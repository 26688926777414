import React, { ChangeEvent, useState, useContext, useEffect } from 'react'
import { X } from 'react-feather'
import AlertPopup from './AlertPopup'
import Button from './Button'
import Dropdown from './Dropdown'
import TextArea from './TextArea'
import {
  takeActionTextAreaText,
  giveWarningToUser,
  reportActionType,
  noteToAdmin,
  selectActionType,
  TakeActionAlertText
} from '../constants'
import { ToastContext } from './Toast/ToastProvider'
import { error, success } from '../constants'
import { createContent } from '../adapters/create'
import {
  findReportedUserEndPoint,
  getReportedUserImage,
  getReportedUserProfilePicture,
  takeActionOnReportedUserEndPoint
} from '../adapters/endpoints'
import { fetchAndDisplayImages, getContent } from '../adapters/get'
import { UserNameAvatar } from './userNameAvatar'
import UserDetailComponent from './UserDetailComponent'
import { ResponsibleUserDetail } from './ResponsibleUserDetail'
import ImageViewer from './ImageViewer'
import Spinner, { DetailSpinner, LoadingDots, SideBarSpinner } from './Spinner'

interface userReportData {
  img: string
  uploadedImgs: Array<string>
  description: string
  responsibleId: ResponsibleUser[]
  reporterId: ReporterUser[]
  report?: {
    type: string
    complaint: string
    reportedByUserId: string
    userId: string
    dateOfReporting: string
  }
  violationType:string,
  createdAt:string
}
interface ReporterUser {
  region: any
  rating: any
  _id: string
  firstName?: string
  lastName?: string
  bio?: string
  age?: string
  gender?: string
  height?: {ft:string, m:string}
  location?: string
  address?: {
    country: string
  }
  jobTitle?: string
}
interface ResponsibleUser {
  _id: string
  firstName?: string
  lastName?: string
  bio?: string
  age?: string
  gender?: string
  height?: {ft:string, m:string}
  location?: string
  address?: {
    country: string
  }
  jobTitle?: string
}

interface Props {
  setShowUser: React.Dispatch<React.SetStateAction<boolean>>
  reportId: number | string
  setReports: any
}

export const InfoCard = ({ label, value }: any) => {
  return (
    <div className='py-2 px-2 bg-white border rounded-md w-full max-w-lg my-2 mx-auto'>
      <div className=' justify-between items-center'>
        <p className='text-black text-sm font-bold'>{label}</p>
        <p className='text-black'>{value}</p>
      </div>
    </div>
  )
}

const ViewReportedUser: React.FC<Props> = ({
  setShowUser,
  reportId,
  setReports
}) => {
  const { handleCreateToast } = useContext(ToastContext)

  const [userReportData, setUserReportData] = useState<userReportData[]>([])
  function onSuccessGetReportedChat (data: any) {
    
    setUserReportData(data.reportedUser)
  }

  function onFail (message: any) {
    handleCreateToast(message, error)
  }

  useEffect(() => {
    if (reportId !== 0) {
      const GET_REPORTED_USER = `${findReportedUserEndPoint}${reportId}`
      getContent(GET_REPORTED_USER, onSuccessGetReportedChat, onFail)
    }
  }, [reportId])

  const TakeAction = () => {
    const [showPopup, setShowPopup] = useState<Boolean>(false)
    const [selectedActionType, setSelectedActionType] = useState('')
    // const [reviewDescription, setReviewDescription] = useState('')
    const [noteToAdminManager, setNoteToAdminManager] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const { handleCreateToast } = useContext(ToastContext)
    const [accountType,setAccountType] = useState<any>({})
    useEffect(() => {
  
      const existingAccount =localStorage.getItem('adminManager');
      if(existingAccount) {
        setAccountType(JSON.parse(existingAccount));
      }
    }, [])
    useEffect(() => {
      if(accountType?.isAdminManager === true){
        setNoteToAdminManager('adminManager')        
      }
    }, [accountType?.isAdminManager])
   
    const onSuccess = (data: any, response: any) => {
      setReports((prevReportedUsers: any) => {
        const nextReportedUsers = [...prevReportedUsers]?.filter(
          (user: any) => user._id !== reportId
        )
        return nextReportedUsers
      })

      setShowUser(false)

      handleCreateToast(response.message, success)
      setIsLoading(false);
    }

    const onFail = (message: string) =>{
      handleCreateToast(message, error);
      setIsLoading(false);
    }

    const submitReview = () => {
      setIsLoading(true); 
      
      const data = {
        actionType: selectedActionType,
        report_id: reportId,
        noteToAdminManager: noteToAdminManager
      }

      createContent(takeActionOnReportedUserEndPoint, data, onSuccess, onFail)
    }

    return (
      <div>
          <div className="flex flex-col justify-between items-center justify-center h-full max-h-[85%] scrollbar-hide  overflow-y-auto ">
            <div className=' items-center justify-center my-5'>
                     <InfoCard
                      value={userReportData[0]?.violationType}
                      label="Violation Type"
                    />
                     <InfoCard
                      value={userReportData[0]?.createdAt.split("T")[0]}
                      label="Date"
                    />
              
            </div>
            <div className=" items-center  pt-6 justify-center">
              <h2 className=" text-md text-start  mb-2">
                Action Type{" "}
              </h2>
              <Dropdown
                name={selectActionType}
                list={reportActionType}
                onClick={(e: ChangeEvent<HTMLInputElement>) => {
                  setSelectedActionType(e.currentTarget.innerText);
                }}
              />
              {accountType?.isAdminManager === false && (
                <TextArea
                  text={noteToAdmin}
                  change={(e: ChangeEvent<HTMLInputElement>) => {
                    setNoteToAdminManager(e.target.value);
                  }}
                />
              )}
            </div>
            <div className=" items-center mx-10 justify-center mt-28">
            <Button
              disabled={!selectedActionType || !noteToAdminManager}
              text={giveWarningToUser}
              onClick={() => setShowPopup(true)}
            />
            </div>
          
          </div>
          {showPopup ? (
            <AlertPopup
              message={TakeActionAlertText}
              Close={() => {
                setShowPopup(false);
              }}
              onSubmit={submitReview}
              isLoading={isLoading}
            ></AlertPopup>
          ) : null}
        </div>
    )
  }
console.log(userReportData[0]);

  const ReportDetails = () =>{
    return (
      <div className="relative flex flex-row h-full">
  <div className="w-[35%] p-4">
    <div className="bg-white shadow-lg rounded-lg p-6">
      <p className="text-center text-blue-500">Reporter</p>
      <UserDetailComponent userReportData={userReportData[0]}  />
    </div>
  </div>

  <div className="w-[35%] p-4">
    <div className="bg-white shadow-lg rounded-lg p-6">
      <p className="text-center text-blue-500">Responsible</p>
      <ResponsibleUserDetail userReportData={userReportData[0]} />
    </div>
  </div>
  <div className="w-[30%] p-4">
    <div className="bg-white h-full shadow-lg rounded-lg p-6">
    <p className="text-center text-blue-500 pt-2">Take Action</p>
      <TakeAction />
    </div>
  </div>
      </div>
    )
  }
  return (
    <div className="fixed overflow-y-auto scrollbar scrollbar-thumb-gray-500 scrollbar-track-gray-200 inset-0 z-40 flex overflow items-end justify-end bg-gray-900 bg-opacity-50 ">
    <div className="p-8  absolute top-0 right-0 h-auto w-full md:w-[85%] bg-gray-50 rounded-l-md z-50">
      <div className="flex justify-end">
        <X onClick={() => setShowUser(false)} className=" cursor-pointer " />
      </div>
      <div className=" h-full space-y-8 ">
        <ReportDetails />
      </div>
    </div>
  </div>
   
  )
}

export default ViewReportedUser
