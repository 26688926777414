import { useState, useEffect, ChangeEvent } from "react";
import { ChevronDown } from "react-feather";
import DropdownList from "./DropdownList";
import {LineGraphFilterType, MatchingCategory, PlanName, MatchingPartnerAccountStatus, reportActionType, subscriptionDurations, userGender, userRate, verifyActionType} from "../constants"

interface DropdownInterface {
    name: string;
    list: typeof reportActionType | typeof verifyActionType | typeof LineGraphFilterType | typeof subscriptionDurations
     | typeof userRate | typeof PlanName | typeof MatchingPartnerAccountStatus | typeof userGender | typeof MatchingCategory | any[];
    onClick: (e: ChangeEvent<HTMLInputElement>) => void;
    width?:string
}



function Dropdown(props: DropdownInterface) {
  const [open, setOpen] = useState<boolean>(false);
  const [name, setname] = useState<String>("");
  useEffect(() => {
    setname(props.name);
  }, []);

  return (
    <div className={`relative ${props.width ? "w-" + props.width : "w-[100%]"}`}>
    <button
      className="border-2 px-2 rounded flex justify-between items-center"
        onClick={(e: any) => {
          setOpen(!open);
        }}
      >
        <h2 className="flex text-sm flex-row p-2 rounded w-full">{name}</h2>

                <div className="place-self-end pb-2">
                    <ChevronDown />
                </div>
            </button>

            {open && (
              <div className={`relative ${props.width ? "w-" + props.width : "w-[100%]"}`}>
                <DropdownList
                    width={props.width}
                    onClick={e => {
                        setOpen(false);
                        props.onClick(e);
                        setname(e.currentTarget.innerText);
                    }}
                >
                    {props.list}
                </DropdownList>
                </div>
            )}
        </div>
  );
}



export default Dropdown;
