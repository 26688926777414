import { useState, ChangeEvent, useContext, useEffect } from "react";
import { X } from "react-feather";
import Dropdown from "./Dropdown";
import AlertPopup from "./AlertPopup";
import TextArea from "./TextArea";
import Button from "./Button";
import {
  takeActionTextAreaText,
  verificationUpdateButton,
  selectActionType,
  verifyActionType,
  noteToAdmin,
  TakeActionAlertText,
} from "../constants";
import { ToastContext } from "../components/Toast/ToastProvider";
import { error, success } from "../constants";
import { createContent } from "../adapters/create";
import {
  findVerifyImgRequestEndPoint,
  getpartnerImage,
  getUserImageNames,
  getUserVerificationRequestImage,
  takeActionOnUserVerificationRequestEndPoint,
} from "../adapters/endpoints";
import { fetchAndDisplayImages, getContent } from "../adapters/get";
import { DetailSpinner, LoadingDots, SideBarSpinner } from "./Spinner";
import { UserNameAvatar } from "./userNameAvatar";
import ImageViewer from "./ImageViewer";
import InfoCard from "./InfoCard";
import PostImageZoomPopup from "./PostImageViewer";
import UserPopup from "./UserPopup";

interface Props {
  setShowUser: React.Dispatch<React.SetStateAction<boolean>>;
  userIds: any;
  setVerificationPendingUsers: any;
}

const ViewUser: React.FC<Props> = ({
  setShowUser,
  userIds,
  setVerificationPendingUsers,
}) => {
  const [userVerifyData, setUserVerifyData] = useState<any>();
  const [isVerifyDataLoading, setisVerifyDataLoading] = useState(false);
  const [images, setImages] = useState<any>();
  const [responsibleImg, setResponsibleImg] = useState<any>(null);
  const [userProfilePic, setUserProfilePic] = useState();
  const [OpenZoomPic, setOpenZoomPic] = useState(false);
  const [openVerificationImage, setOpenVerificationImage] = useState(false);
  const [openReferenceImage, setOpenReferenceImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageNames, setImageNames] = useState<any>();
  const [jsonFormat, setJsonFormat] = useState<string | null>(null);

  function onSucessGettingUsersVerificationImgs(data: any) {
    setImages(data.image);
    setLoading(false);
  }
  const toJsonClick = (user: string) => {
    setJsonFormat(user);
  };
  const closePopup = () => {
    setJsonFormat(null);

  };
  function onSucessGettingUsersUploadedImgs(data: any) {
    setResponsibleImg(data);
  }

  function onFail(message: any) {
    setisVerifyDataLoading(false);
    setLoading(false);
  }

  function onSuccessGetVerifyUserData(data: any) {
    setUserVerifyData(data.verificationRequest[0]);
    setisVerifyDataLoading(false);
  }
  const onSucessGettingUserImageNames = (data: any) => {
    setImageNames(data);
  };
  
  useEffect(() => {
    
    if (userIds) {
      const ENDPOINTSNAME = `${getUserImageNames}${userIds._id}`;
      getContent(ENDPOINTSNAME, onSucessGettingUserImageNames, onFail);
    }
  }, []);

  useEffect(() => {
    const GET_VERIFY_USER_DATA = `${findVerifyImgRequestEndPoint}${userIds.verificationRequestId}`;
    getContent(GET_VERIFY_USER_DATA, onSuccessGetVerifyUserData, onFail);
    setisVerifyDataLoading(true);
  }, []);

  useEffect(() => {

    if (
      imageNames &&
      imageNames?.userImageNames &&
      imageNames?.userImageNames.length > 0
    ) {
      
      const ENDPOINTS = imageNames.userImageNames.map(
        (imageId: any) => `${getpartnerImage}${imageId.name}`
      );
      fetchAndDisplayImages(
        ENDPOINTS,
        onSucessGettingUsersUploadedImgs,
        onFail
      );
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [imageNames]);

  useEffect(() => {
    const VERIFY_USER_IMG = `${getUserVerificationRequestImage}${userIds.verificationRequestId}`;
    getContent(VERIFY_USER_IMG, onSucessGettingUsersVerificationImgs, onFail);
    setLoading(true);
  }, []);
  let user: any = {};

  useEffect(() => {
    if (userVerifyData) {
      user = userVerifyData?.userInfoId[0];
    }
  }, [userVerifyData]);

  const referThisImg =
    images?.referenceImageForVerificationRequest[0]?.image?.data;
  const imgForVerification =
    images?.findUserImageToRespondUserVerificationRequest?.image?.data;


    const TakeAction = () => {
      const [showPopup, setShowPopup] = useState<Boolean>(false);
      const [selectedActionType, setSelectedActionType] = useState("");
      // const [reviewDescription, setReviewDescription] = useState('')
      const [noteToAdminManager, setNoteToAdminManager] = useState("");
      const [isLoading, setIsLoading] = useState(false);
      const { handleCreateToast } = useContext(ToastContext);
      const [accountType, setAccountType] = useState<any>({});
      const [newMessage, setNewMessage] = useState<string>("");
  
      useEffect(() => {
        const existingAccount = localStorage.getItem("adminManager");
        if (existingAccount) {
          setAccountType(JSON.parse(existingAccount));
        }
      }, []);
      useEffect(() => {
        if (accountType?.isAdminManager === true) {
          setNoteToAdminManager("adminManager");
        }
      }, [accountType?.isAdminManager]);
      const onSuccess = (data: any, response: any) => {
        setVerificationPendingUsers((prevUsers: any) => {
          const nextUsers = [...prevUsers]?.filter(
            (user: any) => user._id !== userIds.verificationRequestId
          );
          return nextUsers;
        });
  
        setShowUser(false);
        handleCreateToast(response.message, success);
        setIsLoading(false);
      };
  
      const onFail = (message: string) => {
        handleCreateToast(message, error);
        setIsLoading(false);
      };
  
      const submitReview = () => {
        setIsLoading(true);
        const data = {
          // reviewerReasonForTakingAction: reviewDescription,
          actionType: selectedActionType,
          verif_req_image_id: userIds.verificationRequestId,
          noteToAdminManager: noteToAdminManager,
        };
  
        createContent(
          takeActionOnUserVerificationRequestEndPoint,
          data,
          onSuccess,
          onFail
        );
      };
     
      return (
        <div>
          <div className="flex flex-col justify-between items-center justify-center h-full max-h-[85%] w-full scrollbar-hide  overflow-y-auto ">
            <div className=" items-center  pt-6 justify-center">
              <h2 className=" text-md text-start  mb-2">
                Action Type{" "}
              </h2>
              <Dropdown
                name={selectActionType}
                list={verifyActionType}
                onClick={(e: ChangeEvent<HTMLInputElement>) => {
                  setSelectedActionType(e.currentTarget.innerText);
                }}
              />
              
              {accountType?.isAdminManager === false && (
                <TextArea
                  text={noteToAdmin}
                  change={(e: ChangeEvent<HTMLInputElement>) => {
                    setNoteToAdminManager(e.target.value);
                  }}
                />
              )}
            </div>
            <div className=" items-center mx-10 justify-center mt-28">
            <Button
              disabled={!selectedActionType || !noteToAdminManager}
              text={verificationUpdateButton}
              onClick={() => setShowPopup(true)}
            />
            </div>
          
          </div>
          {showPopup ? (
            <AlertPopup
              message={TakeActionAlertText}
              Close={() => {
                setShowPopup(false);
              }}
              onSubmit={submitReview}
              isLoading={isLoading}
            ></AlertPopup>
          ) : null}
        </div>
      );
    };
console.log(responsibleImg);

  const UserDetail = () => {
    return (
      <div className="relative flex flex-col items-center  h-full ">
         { jsonFormat && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">

        <div className="bg-white p-6 rounded-lg shadow-lg">
       <UserPopup user={jsonFormat} onClose={closePopup} />
    </div>
    </div>

  )}
        {loading ? (
          <DetailSpinner />
        ) : (
          <>
              <div className="bg-white  w-[30%] shadow-lg rounded-lg  absolute flex flex-col h-[95%] mb-10 mx-auto ">
                <div className="flex items-center ">
                  {responsibleImg ? (
                    <img
                      className="object-cover border rounded-full h-16 w-16"
                      src={responsibleImg[0]}
                      alt={user?.firstName}
                    />
                  ) : (
                    <UserNameAvatar
                      userName={userVerifyData?.userInfoId[0]?.firstName}
                      userType={user.USER}
                    />
                  )}
                  <div>
                    <p className="font-bold text-lg">
                      {userVerifyData?.userInfoId[0]?.firstName}{" "}
                      {userVerifyData?.userInfoId[0]?.lastName}
                    </p>
                    <p className="text-gray-600">
                      Phone number: {userVerifyData?.userInfoId[0]?.phoneNumber}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col gap-6 mb-4">
            <div className=" h-auto overflow-auto">
              <div className=" capitalize gap-4 p-4 capitalize my-4 ">
                  {userVerifyData?.userInfoId[0]?.accountStatus && (
                    <InfoCard
                      value={userVerifyData?.userInfoId[0]?.accountStatus}
                      label="Account Status"
                    />
                  )}
                  {userVerifyData?.userInfoId[0]?.rating && (
                    <InfoCard
                      value={userVerifyData?.userInfoId[0]?.rating}
                      label="Rate"
                    />
                  )}
                  {userVerifyData?.userInfoId[0]?.gender && (
                    <InfoCard
                      value={userVerifyData?.userInfoId[0]?.gender}
                      label="Gender"
                    />
                  )}
                  {userVerifyData?.userInfoId[0]?.age && (
                    <InfoCard value={userVerifyData?.userInfoId[0]?.age} label="Age" />
                  )}

                  {userVerifyData?.userInfoId[0]?.address?.country && (
                    <InfoCard
                      value={userVerifyData?.userInfoId[0]?.address?.country}
                      label="Country"
                    />
                  )}
                  {userVerifyData?.userInfoId[0]?.address?.region && (
                    <InfoCard
                      value={userVerifyData?.userInfoId[0]?.address?.region}
                      label="Region"
                    />
                  )}
                
                  {userVerifyData?.userInfoId[0]?.lastSeenAt && (
                    <InfoCard
                      value={userVerifyData?.userInfoId[0]?.lastSeenAt.split("T")[0]}
                      label="Last Seen"
                    />
                  )}
                </div>
            </div>
          </div>
          <div className="flex items-end justify-end mb-4">
            <button 
             onClick={() => {
               toJsonClick(userVerifyData?.userInfoId[0]?._id);
             }}
            className=' z-40 bg-[#2e2e2eed] hover:bg-[#2e2e2e] text-white px-4 rounded py-2'>More</button>
            </div>
              </div>
          
          </>
        )}

        {responsibleImg && (
        <div className=" absolute flex flex-col left-2 w-[30%]  mx-auto">
        <div className="bg-white shadow-lg  rounded-lg">
        <p className="font-semibold text-center">Uploaded Images</p>
         <div className="grid grid-row-2">
          <div className={`grid grid-cols-2 grid-rows-${(responsibleImg.length -1)/2} gap-4 mt-2`}>
              {responsibleImg.map((img:any, index:number) => (
                <div key={index} className="border border-gray-200 rounded-md h-32">
                  <img
                    className="object-cover w-full h-full rounded-md cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setOpenZoomPic(true);
                      setSelectedImage(img);
                    }}
                    src={img}
                    alt={`Imag ${index + 1}`}
                  />
                </div>
              ))}
            
            </div>
            <p className="font-semibold text-center pt-2 mt-4">Reference Images</p>
            <div className="grid grid-cols-2 grid-rows-4 gap-4 mt-2">
            {imgForVerification && (
                <div className="border border-gray-200 rounded-md h-32">
                  <img
                    className="object-cover w-full h-full rounded-md cursor-pointer"
                    onClick={() => setOpenVerificationImage(true)}
                    src={`data:image/jpeg;base64,${imgForVerification}`}
                    alt="Imag for Verification"
                  />
                  {openVerificationImage && (
                    <ImageViewer
                      imageUrl={imgForVerification}
                      onClose={() => setOpenVerificationImage(false)}
                    />
                  )}
                </div>
              )}
              {referThisImg && (
                <div className="border border-gray-200 rounded-md h-32">
                  <img
                    className="object-cover w-full h-full rounded-md cursor-pointer"
                    onClick={() => setOpenReferenceImage(true)}
                    src={`data:image/jpeg;base64,${referThisImg}`}
                    alt="Imag for Reference"
                  />
                  {openReferenceImage && (
                    <ImageViewer
                      imageUrl={referThisImg}
                      onClose={() => setOpenReferenceImage(false)}
                    />
                  )}
                </div>
              )}
            </div>
            {OpenZoomPic && (
              <PostImageZoomPopup
                imageUrl={selectedImage}
                onClose={() => setOpenZoomPic(false)}
              />
            )}
          </div>
          </div>
        </div>
      )}
         <div className=" absolute flex flex-col h-[95%]  right-4 w-[30%]  mx-auto">
        <div  className="bg-white h-screen  shadow-lg rounded-lg">
        <TakeAction />
        </div>
        </div>
      </div>
    );
  };
  return (
    <div className="fixed inset-0 z-40 flex overflow items-end justify-end bg-gray-900 bg-opacity-50 ">
      <div className="p-8 absolute top-0 right-0 h-full w-full md:w-[85%] bg-gray-50 rounded-l-md z-50">
        <div className="flex justify-end">
          <X onClick={() => setShowUser(false)} className=" cursor-pointer " />
        </div>
        <div className=" h-full space-y-8 ">
          <UserDetail />
        </div>
      </div>
    </div>
  );
};

export default ViewUser;
