import { useState, ChangeEvent, useContext, useEffect, useRef } from "react";
import { X } from "react-feather";
import Dropdown from "../Dropdown";
import AlertPopup from "../AlertPopup";
import Button from "../Button";

import {
  selectActionType,
  TakeActionAlertText,
  reportActionType,
  giveWarningToUser,
  subscriptionDurations,
  selectSubscriptionDuration,
  PlanName,
  selectPlanName,
  userGender,
  UpdatePreferenceGender,
  UpdateUserGender,
  UpdateUserRating,
  UserType,
} from "../../constants";
import { ToastContext } from "../Toast/ToastProvider";
import { error, success } from "../../constants";
import { createContent } from "../../adapters/create";
import {
  takeActionOnUserEndpoint,
  saveUserSubscription,
  sendMessageToUser,
  updateUserRate,
  getSubscriptionHistory,
  updateMistakeGender,
  updateMistakePreferenceGender,
  getpartnerImage,
  getUserImageNames,
  getUserChatLists,
  findMessagesOfReportedChat,
  getReportedUserProfilePicture,
  pointBasedUserSubscription,
} from "../../adapters/endpoints";
import { fetchAndDisplayImages, fetchProfileImage, getContent } from "../../adapters/get";
import Spinner, { DetailSpinner } from "../Spinner";
import { UserNameAvatar, UserNameAvatarForChat } from "../userNameAvatar";
import PostImageZoomPopup from "../PostImageViewer";
import JSONPretty from 'react-json-pretty';
import { ReusableTable } from "../ReusableTable";
import React from "react";
import UserPopup from "../UserPopup";
import { InfoCard } from "../ViewReportedUser";
import ImagesPopup from "../ImagesPopup";
var JSONPrettyMon = require('react-json-pretty/dist/monikai');

interface Props {
  setShowUser: React.Dispatch<React.SetStateAction<boolean>>;
  userIds: any;
  userInfo: any;
}
const IconCard = ({ icon: Icon, title, color, children }: any) => {
  return (
    <div className="flex gap-2">
      <div>
        <strong>{children}</strong>
        <p className=" text-sm text-gray-400 ">{title}</p>
      </div>
    </div>
  );
};
const AccountType = () =>{
  const [accountType,setAccountType] = useState<any>({})
  useEffect(() => {
    const existingAccount =localStorage.getItem('adminManager');
    if(existingAccount) {
      setAccountType(JSON.parse(existingAccount));
    }
  }, [])
  return accountType
  
}

const TestUserDetailPage: React.FC<Props> = ({
  setShowUser,
  userIds,
  userInfo,
}) => {
  const [tab, setTab] = useState(1);
  const [userImage, setUserImage] = useState<any>([]);
  const [OpenZoomPic, setOpenZoomPic] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [showPreferenceDetails, setShowPreferenceDetails] = useState(false);
  const [showActivityDetails, setShowActivityDetails] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
const [imageNames,  setImageNames] = useState<any>()
  const onSucessGettingUsersUploadedImgs = (data: any) => {
    setUserImage(data);
  };
  const onSucessGettingUserImageNames = (data:any) =>{
    setImageNames(data)
  }
  const onFail = (message: any) => {
    console.error(message);
  };
 useEffect(() =>{
 if(userIds.userId){
  const ENDPOINTSNAME = `${getUserImageNames}${userIds.userId}`
  getContent(ENDPOINTSNAME, onSucessGettingUserImageNames,onFail)
 }
 },[]) 
  useEffect(() => {
    setUserImage([]);
    setImageLoading(true);
    if (imageNames && imageNames.userImageNames && imageNames.userImageNames.length > 0) {          
      const ENDPOINTS = imageNames.userImageNames.map(   
        (imageId: any) => `${getpartnerImage}${imageId.name}`
      );
      fetchAndDisplayImages(
        ENDPOINTS,
        onSucessGettingUsersUploadedImgs,
        onFail
      );
      setImageLoading(true);
    } else {
      setImageLoading(false);
    }
  }, [imageNames]);

  let user: any = {};

  const userStatus = () => {
    if (userInfo?.userStatus === true) {
      return (
        <div className="inline-flex items-center">
          <span className="h-4 w-4 rounded-full bg-green-500 mr-1"></span>
        </div>
      );
    } else {
      return null;
    }
  };

  const UserDetail = () => {
    const [jsonFormat, setJsonFormat] = useState<string | null>(null);
    const [selectedUser, setSelectedUser] = useState<any | null>(null);
    const handleUserClick = (user: any) => {
      setSelectedUser(user);
    };
    const toJsonClick = (user: string) => {
      setJsonFormat(user);
    };
    const closePopup = () => {
      setSelectedUser(null);
      setJsonFormat(null);
  
    };
    return (
      <div >
         {selectedUser && (
        <div className="fixed inset-0 z-50 flex  items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-1/4 mt-10 h-auto my-12 rounded-lg shadow-lg">
            <ImagesPopup userImages={selectedUser} onClose={closePopup} />
          </div>
        </div>
      )}
      { jsonFormat && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">

        <div className="bg-white p-6 rounded-lg shadow-lg">
       <UserPopup user={jsonFormat} onClose={closePopup} />
    </div>
    </div>

  )}
        <>
          {userInfo ? (
            <div className="scrollbar-hide h-[60%]">
              <div className="flex flex-col gap-6 mb-4">
                <div className="flex gap-6 items-center">
                  {userImage ? (
                    <button
                    className="z-40 "
                    onClick={() => {
                      handleUserClick(userImage);
                    }}
                  >
                    <img
                      className="object-cover border rounded-full h-16 w-16"
                      src={userImage[0]}
                      alt={userInfo?.userInfo[0]?.firstName}
                    />
                        </button>

                  ) : (
                    <UserNameAvatar
                      userName={userInfo?.userInfo[0]?.firstName}
                      userType={user?.USER}
                    />
                  )}
                  <p className="font-bold text-lg">
                    {userInfo?.userInfo && userInfo?.userInfo[0]?.firstName}{" "}
                    {userInfo?.userInfo && userInfo?.userInfo[0]?.lastName}
                  </p>
                  {userStatus()}
                </div>
                <p>
                  Phone number:{" "}
                  {userInfo?.userInfo && userInfo?.userInfo[0]?.phoneNumber}
                </p>
                <div className="h-[60vh] overflow-auto">
                  <div className="gap-2 p-4 divide-y-2 capitalize my-2">
                  {userInfo?.userInfo[0]?.accountStatus && (
                    <InfoCard
                      value={userInfo?.userInfo[0]?.accountStatus}
                      label="Account Status"
                    />
                  )}
                  {userInfo?.userInfo[0]?.rating && (
                    <InfoCard
                      value={userInfo?.userInfo[0]?.rating}
                      label="Rate"
                    />
                  )}
                  {userInfo?.userInfo[0]?.gender && (
                    <InfoCard
                      value={userInfo?.userInfo[0]?.gender}
                      label="Gender"
                    />
                  )}
                  {userInfo?.userInfo[0]?.age && (
                    <InfoCard value={userInfo?.userInfo[0]?.age} label="Age" />
                  )}

                  {userInfo?.userInfo[0]?.address?.country && (
                    <InfoCard
                      value={userInfo?.userInfo[0]?.address?.country}
                      label="Country"
                    />
                  )}
                  {userInfo?.userInfo[0]?.address?.region && (
                    <InfoCard
                      value={userInfo?.userInfo[0]?.address?.region}
                      label="Region"
                    />
                  )}
                  {userInfo?.userInfo[0]?.jobTitle && (
                    <InfoCard
                      value={userInfo?.userInfo[0]?.jobTitle}
                      label="Job"
                    />
                  )}

                  {userInfo?.userInfo[0]?.subscriptionStatus && (
                    <InfoCard
                      value={userInfo?.userInfo[0]?.subscriptionStatus}
                      label="Subscription"
                    />
                  )}
                  {userInfo?.userInfo[0]?.subscriptionEndDate && (
                    <InfoCard
                      value={userInfo?.userInfo[0]?.subscriptionEndDate}
                      label="Subscription End Date"
                    />
                  )}
                  {userInfo?.userInfo[0]?.lastSeenAt && (
                    <InfoCard
                      value={userInfo?.userInfo[0]?.lastSeenAt.split("T")[0]}
                      label="Last Seen"
                    />
                  )}
                  </div>
                  <div className="p-4 my-2">
                    <div className=" border border-burgundy rounded-md">
                      <div
                        className="flex justify-between items-center cursor-pointer p-4"
                        onClick={() =>
                          setShowPreferenceDetails(!showPreferenceDetails)
                        }
                      >
                        <p className="text-black text-lg mb-2  font-bold">
                          More about preference
                        </p>
                        <button
                          className="text-black py-2 px-2 rounded-md flex items-center justify-center"
                          type="button"
                        >
                          <span
                            className={`transform transition-transform ${
                              showPreferenceDetails ? "rotate-180" : ""
                            }`}
                          >
                            ▼
                          </span>
                        </button>
                      </div>

                      {showPreferenceDetails && (
                        <div className="gap-2 divide-y-2 capitalize my-2">
                     {userInfo?.matchpreferences[0]?.gender && (
                    <InfoCard
                      value={userInfo?.matchpreferences[0]?.gender}
                      label="Gender"
                    />
                  )}
                  {userInfo?.matchpreferences && userInfo.matchpreferences.length > 0
                   && userInfo.matchpreferences[0].age && (
                  <InfoCard
                 value={`${userInfo.matchpreferences[0].age.min}-${userInfo.matchpreferences[0].age.max}`}
                 label="Age"
                 />
                 )}
                  {userInfo?.matchpreferences[0]?.hasOwnProperty('onlyVerified') && (
                  <InfoCard
                   value={userInfo?.matchpreferences[0]?.onlyVerified ? "Yes" : "No"}
                  label="Show only verified users"
                    />
                   )}
                    {userInfo?.matchpreferences[0]?.address?.country && (
                    <InfoCard
                      value={userInfo?.matchpreferences[0]?.address?.country}
                      label="Country"
                    />
                  )}
                   {userInfo?.matchpreferences[0]?.address?.region && (
                    <InfoCard
                      value={userInfo?.matchpreferences[0]?.address?.region}
                      label="Region"
                    />
                  )}
                  
                   {userInfo?.matchpreferences[0]?.religion && (
                    <InfoCard
                      value={userInfo?.matchpreferences[0]?.religion}
                      label="Religion"
                    />
                  )}                        </div>
                      )}
                    </div>
                  </div>
                  <div className="p-4 my-2">
                    <div className=" border border-burgundy rounded-md">
                      <div
                        className="flex justify-between items-center cursor-pointer py-4"
                        onClick={() =>
                          setShowActivityDetails(!showActivityDetails)
                        }
                      >
                        <p className="text-black text-lg mb-2 ml-2 font-bold">
                          More about user activity
                        </p>
                        <button className="text-black py-2 px-2 rounded-md flex items-center justify-center">
                          <span
                            className={`transform transition-transform ${
                              showActivityDetails ? "rotate-180" : ""
                            }`}
                          >
                            ▼
                          </span>
                        </button>
                      </div>
                      {showActivityDetails && (
                        <div className="gap-2 divide-y-2 capitalize my-2">
                    {userInfo?.userconnections[0]?.matchedUserIds !== undefined && (
                    <InfoCard
                      value={userInfo?.userconnections[0]?.matchedUserIds}
                      label="Matched Users"
                    />
                  )}
                  {userInfo?.userconnections[0]?.likesReceivedFromIds !== undefined && (
                    <InfoCard
                      value={userInfo?.userconnections[0]?.likesReceivedFromIds}
                      label="Like Received"
                    />
                  )}
                  {userInfo?.userconnections[0]?.likesSentToIds !== undefined && (
                    <InfoCard
                      value={userInfo?.userconnections[0]?.likesSentToIds}
                      label="Like Sent"
                    />
                  )}
                  {userInfo?.userconnections[0]?.chatIds !== undefined && (
                    <InfoCard
                      value={userInfo?.userconnections[0]?.chatIds}
                      label="Chat Users"
                    />
                  )}
                  {userInfo?.userconnections[0]?.blockedUserIds !== undefined && (
                    <InfoCard
                      value={userInfo?.userconnections[0]?.blockedUserIds}
                      label="Blocked Users"
                    />
                  )}                        </div>
                      )}
                    </div>
                  </div>

                </div>
              </div>
              <div className="flex items-end justify-end mb-4">
            <button 
             onClick={() => {
               toJsonClick(userInfo?.userInfo[0]?._id);
             }}
            className=' z-40 bg-[#2e2e2eed] hover:bg-[#2e2e2e] text-white px-4 rounded py-2'>More</button>
            </div>
            </div>
          ) : (
            <div>
              <DetailSpinner />
            </div>
          )}
        </>
      </div>
    );
  };
  interface PopupProps {
    onClose: () => void;
  }
 const TakeAction:React.FC<PopupProps> = ({onClose}) => {
    const [showPopup, setShowPopup] = useState<Boolean>(false);
    const [selectedActionType, setSelectedActionType] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { handleCreateToast } = useContext(ToastContext);
    const [newMessage, setNewMessage] = useState<string>("");

    const onSuccess = (data: any, response: any) => {
      setShowUser(false);
      handleCreateToast(response.message, success);
      setIsLoading(false);
    };

    const onFail = (message: string) => {
      handleCreateToast(message, error);
      setIsLoading(false);
    };

    const submitReview = () => {
      setIsLoading(true);
      const data = {
        actionType: selectedActionType,
        userId: userInfo?.userInfo[0]._id,
      };

      createContent(takeActionOnUserEndpoint, data, onSuccess, onFail);
    };
    const sentMessage = () => {
      const data = {
        message: newMessage,
        userId: userInfo?.userInfo[0]._id,
      };

      createContent(sendMessageToUser, data, onSuccess, onFail);
    };
    return (
      <div >
         <div className="flex justify-end">
          <X onClick={onClose} className=" cursor-pointer " />
        </div>
        <div className="flex flex-col justify-between h-full max-h-[85%] scrollbar-hide  overflow-y-auto ">
          <div>
            <h2 className=" text-lg font-semibold  mb-2 left-2">
              Action Type{" "}
            </h2>
            <Dropdown
              name={selectActionType}
              list={reportActionType}
              onClick={(e: ChangeEvent<HTMLInputElement>) => {
                setSelectedActionType(e.currentTarget.innerText);
              }}
            />
          </div>
          <div className="p-4 border-t border-gray-200">
            <p className=" font-light text-md">
              Option two: Write a message to the user.
            </p>
            <div className="flex justify-between items-center">
              <textarea
                className="w-3/4 p-2 border rounded"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Type your message..."
              />
              <button
                className="bg-blue-500 text-white p-2 rounded cursor-pointer"
                onClick={sentMessage}
                disabled={!newMessage.trim()}
              >
                Send
              </button>
            </div>
          </div>

          <Button
            disabled={!selectedActionType}
            text={giveWarningToUser}
            onClick={() => setShowPopup(true)}
          />
        </div>

        {showPopup ? (
          <AlertPopup
            message={TakeActionAlertText}
            Close={() => {
              setShowPopup(false);
            }}
            onSubmit={submitReview}
            isLoading={isLoading}
          ></AlertPopup>
        ) : null}
      </div>
    );
  };
  const SaveEthioUserSubscription = () => {
    const [showPopup, setShowPopup] = useState<Boolean>(false);
    const [duration, setDuration] = useState("");
    const [planName, setPlanName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { handleCreateToast } = useContext(ToastContext);
    const [paymentInfo, setPaymentInfo] = useState<any>([]);
    const [point, setPoint] = useState<number>(0);
    const [showPointPopup, setShowPointPopup] = useState<Boolean>(false);
    const [price, setPrice] = useState<number>(0);
    const onSuccess = (data: any, response: any) => {
      setShowUser(false);
      handleCreateToast(response.message, success);
      setIsLoading(false);
    };

    const onFail = (message: string) => {
      handleCreateToast(message, error);
      setIsLoading(false);
    };

    const submitReview = () => {
      setIsLoading(true);
      const data = {
        planName: planName,
        duration: duration,
        userId: userInfo?.userInfo[0]._id,
      };

      createContent(saveUserSubscription, data, onSuccess, onFail);
    };

    function onSucessGettingPaymentInfo(data: any) {
      setPaymentInfo(data.paymentInfo);
      console.log(data);
      
      
    }

    useEffect(() => {
      if (userIds && userIds.userId) {
        const GET_PAYMENT_INFO = `${getSubscriptionHistory}${userIds?.userId}`;
        getContent(GET_PAYMENT_INFO, onSucessGettingPaymentInfo, onFail);
      }
    }, []);
    const pointBasedSubscription = () => {
      setIsLoading(true);
      const data = {
        userId: userInfo?.userInfo[0]._id,
        point: point,
      };

      createContent(pointBasedUserSubscription, data, onSuccess, onFail);
    };
    useEffect (()=>{
      if(point){
        let basePrice = point / 10; // Base price calculation

        // Define discounts based on your specific needs
        if (point >= 10000) {
          basePrice = basePrice * 0.75; // Apply a 25% discount
        } else if (point >= 7000) {
          basePrice = basePrice * 0.8; // Apply a 25% discount
        } else if (point >= 5000) {
          basePrice = basePrice * 0.9; // Apply a 10% discount
        }
        setPrice(basePrice)
      } else{
        setPrice(0)
      }
    },[point])
   
    return (
      <div
      > 
      {/* <div className="bg-white h-full shadow-lg rounded-lg p-6">
      <p className="text-center text-blue-500">Subscription</p>
        <div className="grid grid-cols-3 gap-y-4 bg-gray-50 p-4">
          <IconCard icon="" color="gray" title="Silver">
            {paymentInfo[0]?.silver}
          </IconCard>
          <IconCard icon="" color="gray" title="Gold">
            {paymentInfo[0]?.gold}
          </IconCard>
          <IconCard icon="" color="gray" title="Platinum">
            {paymentInfo[0]?.platinum}
          </IconCard>
        </div>
        <div className="flex flex-col justify-between h-full max-h-[85%] scrollbar-hide overflow-y-auto">
          <div className="flex flex-row justify-between items-center ">
            <div>
              <h2 className="text-lg font-semibold mb-2">Subscription Type</h2>
              <Dropdown
                name={selectPlanName}
                list={PlanName}
                onClick={(e: ChangeEvent<HTMLInputElement>) => {
                  setPlanName(e.currentTarget.innerText);
                }}
              />
            </div>
            <div>
              <h2 className="text-lg font-semibold mb-2">Duration Type</h2>
              <Dropdown
                name={selectSubscriptionDuration}
                list={subscriptionDurations}
                onClick={(e: ChangeEvent<HTMLInputElement>) => {
                  setDuration(e.currentTarget.innerText);
                }}
              />
            </div> 
          </div>
          <div className=" mt-28 bottom-5  ml-28 items-center justify-center top-1/2 ">
          <Button
            disabled={!duration || !planName}
            text={giveWarningToUser}
            onClick={() => setShowPopup(true)}
            
          />
          </div>
        
             
        </div>
        </div> */}
        <div className=" mt-14  bg-white h-full shadow-lg rounded-lg p-6">
        <p className="text-blue-500 mb-4 item-center text-center">Point Based Subscription</p>
        {
          paymentInfo?.point ?(
            <p className="mb-4">This user has {paymentInfo?.point} Points</p>
          ):(
            <p className="mb-4">This user has no Points</p>
          )
        }
        
          <div className="flex justify-center item-center">
            <div>
            <input
                className="w-3/4 h-12 border rounded p-2 resize-none text-center flex items-center justify-center"
                value={point}
                type="number"
                onChange={(e) => setPoint(Number(e.target.value))} 
              />
              
            </div>
            <div >
            <Button
                disabled={point < 3000} 
                text={giveWarningToUser}
               onClick={() => setShowPointPopup(true)}
            
          />
            </div>
          </div>
          <p className=" ml-8">Amount: {price} ETB</p>
        </div>

        {showPointPopup ? (
          <AlertPopup
            message={TakeActionAlertText}
            Close={() => {
              setShowPointPopup(false);
            }}
            onSubmit={pointBasedSubscription}
            isLoading={isLoading}
          ></AlertPopup>
        ) : null}
        {showPopup ? (
          <AlertPopup
            message={TakeActionAlertText}
            Close={() => {
              setShowPopup(false);
            }}
            onSubmit={submitReview}
            isLoading={isLoading}
          ></AlertPopup>
        ) : null}
      </div>
    );
  };
  const UpdateUserRate = () => {
    const [ratingShowPopup, setRatingShowPopup] = useState<Boolean>(false);
    const [genderShowPopup, setGenderShowPopup] = useState<Boolean>(false);
    const [preferenceGendershowPopup, setPreferenceGenderShowPopup] =
      useState<Boolean>(false);
    const [rate, setRate] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
    const { handleCreateToast } = useContext(ToastContext);
    const [gender, setGender] = useState("");
    const [preferenceGender, setPreferenceGender] = useState("");
    const onSuccess = (data: any, response: any) => {
      setShowUser(false);
      handleCreateToast(response.message, success);
      setIsLoading(false);
    };

    const onFail = (message: string) => {
      handleCreateToast(message, error);
      setIsLoading(false);
    };

    const submitReview = () => {
      setIsLoading(true);
      const data = {
        rate: rate,
        userId: userInfo?.userInfo[0]._id,
      };

      createContent(updateUserRate, data, onSuccess, onFail);
    };
    const updateGender = () => {
      setIsLoading(true);
      const data = {
        userId: userInfo?.userInfo[0]._id,
        gender: gender,
      };

      createContent(updateMistakeGender, data, onSuccess, onFail);
    };
    const updatePreferenceGender = () => {
      setIsLoading(true);
      const data = {
        userId: userInfo?.userInfo[0]._id,
        gender: preferenceGender,
      };

      createContent(updateMistakePreferenceGender, data, onSuccess, onFail);
    };
    const handleClick = () => {
      if (rate) {
        setRatingShowPopup(true); 
      } else if (gender) {
        setGenderShowPopup(true);  
      } else if (preferenceGender) {
        setPreferenceGenderShowPopup(true);  
      } 
    };
    return (
      <div >
        <h2>Update user rate</h2>
        <div className="flex justify-between items-center scrollbar-hide  overflow-y-auto ">
          <div className="border border-gray-300 rounded-md p-4 w-full max-w-md">
            <input
              type="text"
              name="rate"
              onChange={(e) => setRate(e.target.value)}
              className="border-none focus:outline-none w-full"
              placeholder="Enter rating (1-5)"
            />
          </div>
        
          {ratingShowPopup ? (
            <AlertPopup
              message={UpdateUserRating}
              Close={() => {
                setRatingShowPopup(false);
              }}
              onSubmit={submitReview}
              isLoading={isLoading}
            ></AlertPopup>
          ) : null}
        </div>
        <div className=" pt-6 mt-14">
          <h2>Update user gender</h2>
          <div className="flex justify-between items-center content-center">
            <div className="w-full max-w-md">
              <Dropdown
                name={gender}
                list={userGender}
                onClick={(e: ChangeEvent<HTMLInputElement>) => {
                  setGender(e.currentTarget.innerText);
                }}
              />
            </div>
            <div >
              {genderShowPopup ? (
                <AlertPopup
                  message={UpdateUserGender}
                  Close={() => {
                    setGenderShowPopup(false);
                  }}
                  onSubmit={updateGender}
                  isLoading={isLoading}
                ></AlertPopup>
              ) : null}
            </div>
          </div>
        </div>
        <div className=" pt-6 mt-14">
          <h2>Update Preference gender</h2>
          <div className="flex justify-between items-center content-center">
            <div className="w-full max-w-md">
              <Dropdown
                name={preferenceGender}
                list={userGender}
                onClick={(e: ChangeEvent<HTMLInputElement>) => {
                  setPreferenceGender(e.currentTarget.innerText);
                }}
              />
            </div>
            <div>
              <Button
      disabled={!rate && !gender && !preferenceGender}  
      text={giveWarningToUser} 
      onClick={handleClick} 
    />
              {preferenceGendershowPopup ? (
                <AlertPopup
                  message={UpdatePreferenceGender}
                  Close={() => {
                    setPreferenceGenderShowPopup(false);
                  }}
                  onSubmit={updatePreferenceGender}
                  isLoading={isLoading}
                ></AlertPopup>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  };
  const ChatUI = ({ messages, senderId,receiverId,accountStatus}:any) => {
    const [senderProfilePic, setSenderProfilePic] = useState('')
    const [receiverProfilePic, setReceiverProfilePic] = useState('')

    const [selectedUser, setSelectedUser] = useState<string | null>(null);
 
  const handleUserClick = (user: string) => {
    setSelectedUser(user);
  };

  const closePopup = () => {
    setSelectedUser(null);
  };
    function onSucessGettingSenderImgs(data: any) {
      setSenderProfilePic(data?.userProfilePicture[0]?.image?.data || '');  
    }
    function onSucessGettingReceiverImgs(data: any) {
        setReceiverProfilePic(data?.userProfilePicture[0]?.image?.data || '');
    }
    const onFailGettingUserMessages = (message: any) => {
      console.error(message);
    };
    useEffect(()=>{
      const SENDER_IMAGE = `${getReportedUserProfilePicture}${senderId}`;
      const RECIEVER_IMAGE = `${getReportedUserProfilePicture}${receiverId}`;
      getContent(SENDER_IMAGE, onSucessGettingSenderImgs, onFailGettingUserMessages);
      getContent(RECIEVER_IMAGE, onSucessGettingReceiverImgs, onFailGettingUserMessages);

    },[senderId,receiverId])
    const ImgCircle = ({ userImg }:any) => (
      <div className="h-10 w-10 overflow-hidden rounded-full">
        <img className="object-cover h-full w-full" src={`data:image/jpeg;base64,${userImg}`} alt="profile" />
      </div>
    );      
    const formatDate = (date:any) => {
      const today = new Date();
      const messageDate = new Date(date);
      if (messageDate.toDateString() === today.toDateString()) {
        return 'Today';
      }
      return messageDate.toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
    };

    let lastMessageDate:any = null;
    return (
      <div>
        {selectedUser && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">

        <div className="bg-white p-6 rounded-lg shadow-lg">
       <UserPopup user={selectedUser} onClose={closePopup} />
    </div>
    </div>
  )}
     <div className="flex flex-col h-screen">
        <div className="h-[60vh] overflow-auto">
              <div className="flex-grow h-0">
              {messages?.map((message:any) => {
                const date = new Date(message?.createdAt);
                const time = date.toLocaleTimeString('en-US', {
                 hour: 'numeric',
                 minute: 'numeric',
                 hour12: true
                 });
                const messageDate = date.toDateString();
                const repliedMessage = messages.find((msg:any) => msg._id === message.repliedToMessageId);
                const isSender = message.senderId === senderId;
                const messageClassName = `px-4 py-2 rounded-2xl bg-gray-200 ${isSender ? 'rounded-bl-none' : 'rounded-br-none'}`;
                const showDateHeader = messageDate !== lastMessageDate;
                lastMessageDate = messageDate;
                return (
                  <React.Fragment key={message._id}>
                    {showDateHeader && (
                      <div className="text-center my-2 text-gray-500">
                        {formatDate(date)}
                      </div>
                    )}
                    <div id={message._id} className={`flex gap-2 my-2 ${isSender ? 'flex-row' : 'flex-row-reverse'}`}>
                      <div className={`flex items-start`}>
                        {isSender ? (
                          senderProfilePic ? (
                           <button onClick={() => {
                            if (accountStatus === 'deleted') {
                              <div>Account Deleted</div>
                            } else {
                              handleUserClick(senderId);

                            }
                          }}>
                            <ImgCircle userImg={senderProfilePic} /></button>
                           
                          ) : (
                            <UserNameAvatarForChat userType={UserType.SENDER} userName="Sender" />
                          )
                        ) : (
                          receiverProfilePic ? (
                            <button onClick={() => {
                              if (accountStatus === 'deleted') {
                                <div>Account Deleted</div>
                              } else {
                                handleUserClick(receiverId);

                              }
                            }}>
                            <ImgCircle userImg={receiverProfilePic} /></button>
                          ) : (
                            <UserNameAvatarForChat userType={UserType.RECIEVER} userName="Receiver" />
                          )
                        )}
                      </div>
                      <div className="flex flex-col mt-6">
                        {message.repliedToMessageId && repliedMessage && (
                          <div
                            className="bg-gray-100 rounded-lg p-2 mb-2 cursor-pointer"
                            style={{ borderLeft: '4px solid #e0e0e0' }}
                            onClick={() => {
                              const originalMessageElement = document.getElementById(repliedMessage?._id);
                              if (originalMessageElement) {
                                originalMessageElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                              }
                            }}
                          >
                            <p className="text-sm font-semibold text-gray-600">
                              {repliedMessage.messageBody.slice(0, 50)}...
                            </p>
                          </div>
                        )}
                        <div className={messageClassName}>
                          <p className="font-semibold">{message.messageBody}</p>
                        </div>
                        <div className="flex justify-start">
                          <p className={`text-xs text-gray-400 mt-2 font-small ${isSender ? 'mr-auto' : 'ml-auto'}`}>
                            {time}
                          </p>
                          <div className="flex justify-start">
                            {message.reactionFromSender && (
                              <div
                                className={`h-8 w-8 bg-white border-2 border-gray-300 ${isSender ? 'ml-0' : 'mr-0'} rounded-full flex items-center justify-center`}
                              >
                                <span role="img" aria-label="emoji">
                                  {message.reactionFromSender}
                                </span>
                              </div>
                            )}
                            {message.reactionFromReceiver && (
                              <div
                                className={`h-10 w-10 bg-white-300 border-2 border-gray-300 ${isSender ? 'mr-0' : 'ml-0'} rounded-full flex items-center justify-center`}
                              >
                                <span role="img" aria-label="emoji">
                                  {message.reactionFromReceiver}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>         
        </div>
      </div>
      </div>
     
    );
  };
  
  const Chats = () => {
    const [chats, setChats] = useState<any>([]);
    const [chatId, setChatId] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState<any>([]);
    const [showUser, setShowUser] = useState<boolean>(false);
    const [messages, setMessages] = useState<any>([]);
    const [loadingMessages, setLoadingMessages] = useState(false);
    const [senderId,setSenderId] = useState('')
    const [receiverId, setReceiverId] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [accountStatus, setAccountStatus] = useState('')

    const onSucessGettingUserChatLists = (data: any) => {
      setChats(data);
      setLoading(false);
    };
    const onFail = (message: any) => {
      console.error(message);
      setLoading(false);
    };
    const onSuccessGettingUserMessages = (data: any) => {      
      setMessages(data);
      setLoadingMessages(false);
    };
    const onFailGettingUserMessages = (message: any) => {
      console.error(message);
      setLoadingMessages(false);
    };
    const getUserMessages = (chatId:any, onSuccess:any, onFail:any) => {
      const ENDPOINT = `${findMessagesOfReportedChat}${chatId}`;
      getContent(ENDPOINT, onSuccess, onFail);
    };
  
    useEffect(() => {
      if (userInfo && userInfo?.userInfo[0]?._id) {
        setLoading(true);
        const ENDPOINT = `${getUserChatLists}${userInfo?.userInfo[0]?._id}`;
        getContent(ENDPOINT, onSucessGettingUserChatLists, onFail);
      }
    }, [userInfo]);

 
  const ProfilePicture = ({imageUrl,firstName}:any) =>{
    const [profilePic, setProfilePic] = useState<string>()
    const onSuccessProfilePic = (data: string) => {
      setProfilePic(data);
    };
    const onFail = (message: any) => {
      console.error(message);
    };
    useEffect(()=>{
      if(imageUrl){
        const ENDPOINT = `${getpartnerImage}${imageUrl}`
        fetchProfileImage(
          ENDPOINT,
        onSuccessProfilePic,
        onFail
      ); 
      } 
    },[imageUrl])
   return (
    <>
    {
      profilePic ? (
        <img
        className="object-cover border rounded-full h-16 w-16"
        src={profilePic}
        alt={firstName}
      />
      ):(
        <UserNameAvatar
          userName={firstName}
          userType={user?.USER}
        />
      )
    }
    </>
   )
  }   
    useEffect(() => {
      if (chats && chats?.chatLists) {
        const userData = chats?.chatLists.map((userData: any) => {
          if (!userData?.userInfo || userData?.userInfo.length === 0) {
                     
            return {
              _id: userData?._id,
              firstName: "Deleted",
              lastSeenAt: "Long time ago",
              phoneNumber:"Deleted",
              senderId:userData?.senderId,
              receiverId:userData?.receiverId,
              imageUrls: <ProfilePicture imageUrl = {userData?.imageUrls?.[0]?.imageUrls?.[0]} firstName = {"Deleted"} />,
              accountStatus:"deleted"
            };
          } else{
            const newChat = {
              _id: userData?._id,
              firstName: userData?.userInfo[0]?.firstName,
              phoneNumber:userData?.userInfo[0]?.phoneNumber,
              senderId:userData?.senderId,
              receiverId:userData?.receiverId,
              lastSeenAt: userData?.userInfo[0]?.lastSeenAt.split("T")[0],
              accountStatus:userData?.userInfo[0]?.accountStatus,
              imageUrls:<ProfilePicture imageUrl = {userData?.imageUrls?.[0]?.imageUrls?.[0]} firstName = { userData?.userInfo[0]?.firstName} />,

            };
            return newChat;
          }
        });
        setUsers(userData);
      }
    }, [chats, userInfo]);
    
    const handleClick = (_id: string) => {      
      const user = users.find((item: any) => item?._id === _id);
      const chatId = user._id;
      setSenderId(user.senderId)
      setReceiverId(user.receiverId)
      setPhoneNumber(user.phoneNumber)
      setAccountStatus(user.accountStatus)
      setChatId(chatId);
      setShowUser(true);
        setLoadingMessages(true);
      getUserMessages(chatId, onSuccessGettingUserMessages, onFailGettingUserMessages);
    };
    const handleBackClick = () => {
      setShowUser(false);
      setChatId(undefined);
      setMessages([]);
    };
    
    return (
      <div >
        {showUser && chatId && (
          <>
            {loadingMessages ? (
              <DetailSpinner />
            ) : (
              <div>
                <button onClick={handleBackClick} className="back-button">
                ◄ Back
              </button>
              <div className=" text-center">
               {phoneNumber.length >0?(
              <div>{phoneNumber}</div>
              ):(
                'Deleted'
              )}
              </div>
             
              {messages?.messagesOfReportedChat?.[0]?.messageIds?.length > 0 ? (
                <ChatUI
                  messages={messages.messagesOfReportedChat[0].messageIds}
                  senderId={senderId}
                  receiverId={receiverId}
                  accountStatus={accountStatus}
                />
              ) : (
                <div>No messages</div>
              )}
               
              </div>
            )}
          </>
        )}
        {loading ? (
          <DetailSpinner />
        ) : (
          <div className="h-[80%] overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-400">
            <ReusableTable
            data={users}
            columns={[
              {
                key:'imageUrls',
                label: "Profile"
              },
              {
                key: 'firstName',
                label: 'First Name'
              },
              {
                key: 'lastSeenAt',
                label: 'Last seen'
              },
            ]}
            onRowClick={handleClick}
          />
          </div>
        )}
      </div>
    );
  };
  const ReportDetails = () =>{
    const [toAction, setToAction] = useState<any>(false);
    const handelPopup = ()=>{
     setToAction(true)
    }
    const closePopup = () => {
     setToAction(false);
 
   };
    return (
      <div className="relative flex flex-row h-auto">
         { toAction && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">

        <div className="bg-white p-6 rounded-lg shadow-lg">
       <TakeAction onClose={closePopup} />
    </div>
    </div>
  )}
  <div className="w-2/6 p-4">
    <div className="bg-white shadow-lg rounded-lg p-6">
      <p className="text-center text-blue-500">User Details</p>
      <UserDetail />
    </div>
  </div>
 
  <div className="w-2/6 p-4">
    <div>
      <SaveEthioUserSubscription />
      </div>
  </div>
  <div className="w-2/6 p-4">
    <div className="bg-white h-full shadow-lg rounded-lg p-6">
    <span className="flex justify-between items-center">
      Update
    <button  onClick={() => { handelPopup()}}  className="ml-auto  transform-translate-y-1/2 px-2  bg-gray-800 text-white rounded-full hover:bg-gray-700 transition">To action</button>
  </span>    <UpdateUserRate />
    </div>
  </div>
 
      </div>
    )
  }
  return (
    <div className="fixed inset-0 z-40 flex overflow items-end justify-end bg-gray-900 bg-opacity-50 ">
    <div className="p-8 absolute top-0 right-0 h-full w-full md:w-[85%] bg-gray-50 rounded-l-md z-50">
      <div className="flex justify-end">
        <X onClick={() => setShowUser(false)} className=" cursor-pointer " />
      </div>
      <div className=" h-full space-y-8 ">
        <ReportDetails />
      </div>
    </div>
  </div>
  );
};

export default TestUserDetailPage;
