import React, { useState, useContext, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import { countUsersBasedOnTheirCountry } from "../adapters/endpoints";
import { getContent } from "../adapters/get";
import { ToastContext } from "./Toast/ToastProvider";
import { error, success } from "../constants";

ChartJS.register(...registerables);

export const options = {
  responsive: true,
  layout: {
    padding: {
      right: 2,
    },
  },
  plugins: {
    legend: {
      display: true,
      maintainAspectRatio: false,
      position: "right" as const,
      fullSize: true,
      labels: {
        font: {
          size: 14,
          color: "#25233A",
        },
        pointStyle: 'rect',
        boxWidth: 25,
        boxHeight:25, // adjust width of legend label background
        // borderRadius: 15, // add border radius to legend label background
        // borderWidth: 8,
        generateLabels: function (chart: any) {
          const data = chart.data.datasets[0].data;
          const labels = chart.data.labels;
          const legendLabels = [];
          const total = data?.reduce((acc: any, val: any) => acc + val, 0);
          const percentageData = data?.map((val: number) =>
            ((val / total) * 100).toFixed(2)
          );
          for (let i = 0; i < data.length; i++) {
            legendLabels.push({
              text: `${labels[i]}: ${percentageData[i]}% (${data[i]})`,
              fillStyle: chart.data.datasets[0].backgroundColor[i],
              strokeStyle: "white",
              lineWidth: 2,
              hidden: false,
              index: i,
            });
          }
          return legendLabels;
        },
      },
    },
    colors: {
      forceOverride: true,
    },
  },
};
console.log(options);
const MAX_DATA_POINTS = 13;

interface props {
  userLocation: any;
  usersBasedOnTheirCountry?: {
    count: number;
    _id: {
      country: number;
    };
  };
}
const UserLocationChart: React.FC<{}> = () => {
  const [userLocation, setUserLocation] = useState<any>([]);
  const { handleCreateToast } = useContext(ToastContext);

  function onSuccessAge(data: any) {
    console.log(data);
    
    setUserLocation(data);
  }

  function onFail(message: any) {
    handleCreateToast(message, error);
  }

  useEffect(() => {
    getContent(countUsersBasedOnTheirCountry, onSuccessAge, onFail);
  }, []);

  const locationCount = userLocation?.usersBasedOnTheirCountry?.map(
    (e: { count: any }) => e.count
  );
  const country = userLocation?.usersBasedOnTheirCountry?.map(
    (e: { _id: any }) => e._id
  );
  const lables = country?.map((e: { country: any }) => e.country);

  const total = locationCount?.reduce((acc: any, val: any) => acc + val, 0);
  const percentageData = locationCount?.map((val: number) =>
    ((val / total) * 100).toFixed(2)
  );

  const maxPercentageIndex = percentageData?.reduce(
    (
      maxIndex: string | number,
      val: number,
      index: any,
      arr: { [x: string]: number }
    ) => {
      if (val > arr[maxIndex]) {
        return index;
      } else {
        return maxIndex;
      }
    },
    0
  );
const limitedLocationCount =
  locationCount && locationCount.length > MAX_DATA_POINTS
    ? locationCount.slice(-MAX_DATA_POINTS)
    : locationCount;
   
  const data = {
    labels: lables,
    datasets: [
      {
        label: "",
        data: limitedLocationCount,
        borderWidth: 0,
        hoverOffset: 4,
      },
    ],
  };
  console.log(data);
  
  return (
    <div className="rounded-2xl bg-white p-6 border-2 
    rounded-lg transform 
    hover:scale-105 transition-transform duration-300 p-8 max-w-xl w-full h-full">
  <p className="font-semibold text-xl">Users Location</p>
  {lables ? (
    <p className="font-tiny text-gray-400 text-sm mb-6 ">
      Most Users are from {lables[maxPercentageIndex]}
    </p>
  ) : null}
  
  <div className="w-full ">
    <Doughnut key={data?.labels?.length} data={data} options={options} className="flex w-full h-auto " />
  </div>
</div>
  );
};

export default UserLocationChart;
