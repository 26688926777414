import AgeofUsersChart from "../AgeofUsersChart";
import Dashboardinfo from "../Dashboardinfo";
import TotalSubscribers from "../TotalSubscribers";
import UserGenderChart from "../UserGenderChart";
import UserLocationChart from "../UserLocationChart";
import {
  countUsersBasedOnTheirStatus,
  getSubscribedUsersEndpoint,
} from "../../adapters/endpoints";
import { getContent } from "../../adapters/get";
import { ToastContext } from "../Toast/ToastProvider";
import { error, success } from "../../constants";
import { useState, useContext, useEffect } from "react";

interface props {
  dashboardinfo: {
    countUsersBasedOnTheirStatus: {
      activeUsers: number;
      deactivateUsers: number;
      unVerifiedUsers: number;
      verifiedUsers: number;
    };
    divideUsersByAge?: {
      count: number;
      _id: {
        age: number;
      };
    };
  };
}
export default function Home() {
  const [dashboardData, setDashboardData] = useState<any>([]);
  const [subscribedData, setSubscribedData] = useState<any>([]);
  const { handleCreateToast } = useContext(ToastContext);
  function onSuccess(data: any) {
    setDashboardData(data.countUsersBasedOnTheirStatus);
  }

  function onSuccessSubscribed(data: any) {
    setSubscribedData(data);
  }

  function onFail(message: any) {
    handleCreateToast(message, error);
  }

  useEffect(() => {
    getContent(countUsersBasedOnTheirStatus, onSuccess, onFail);
    getContent(getSubscribedUsersEndpoint, onSuccessSubscribed, onFail);
  }, []);

    return (

<div className="flex justify-between flex-wrap py-8 overflow-y-auto scrollbar max-h-[95%] w-[100%]">
  {/* Left Section: Location Data */}
  <div className="flex  ml-28 item-center justify-center ">
    <Dashboardinfo dashboardinfo={dashboardData} subscribedData={subscribedData} />
  </div>

  {/* Wrapping the charts into a row-based flex container */}
  <div className="flex flex-row w-full h-auto mt-10 gap-4">
    {/* First Column */}
    <div className="w-1/2 flex flex-col">
      <div className="rounded-lg p-4 h-[100%] flex-grow">
        <UserLocationChart />
      </div>
    </div>

    {/* Second Column */}
    <div className="w-1/2 flex flex-col gap-10 h-full">
      <div className="w-full h-full flex-grow">
        <AgeofUsersChart />
      </div>
      <div className="w-full h-full flex-grow">
        <UserGenderChart />
      </div>
    </div>
  </div>
</div>
        );
}
