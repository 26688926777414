import { useEffect, useState } from "react";
import { getpartnerImage } from "../adapters/endpoints";
import { fetchProfileImage } from "../adapters/get";
import { UserNameAvatar } from "./userNameAvatar";

const ProfilePicture = ({imageUrl,firstName}:any) =>{
    const [profilePic, setProfilePic] = useState<string>()
    const onSuccessProfilePic = (data: string) => {
      setProfilePic(data);
    };
    const onFail = (message: any) => {
      console.error(message);
    };
    useEffect(()=>{
      if(imageUrl){
        const ENDPOINT = `${getpartnerImage}${imageUrl}`
        fetchProfileImage(
          ENDPOINT,
        onSuccessProfilePic,
        onFail
      ); 
      } 
    },[imageUrl])
   return (
    <>
    {
      profilePic ? (
        <img
        className="object-cover border rounded-full h-14 w-14"
        src={profilePic}
        alt={firstName}
      />
      ):(
        <UserNameAvatar
          userName={firstName}
          userType={''}
        />
      )
    }
    </>
   )
  }   

  export default ProfilePicture