import { useState, useEffect, useContext, useRef, ChangeEvent } from 'react';
import {
  searchMatchPartnerEndpoint,
} from '../../adapters/endpoints';
import { getContentWithPagination } from '../../adapters/get';
import { AdminRole, MatchingCategory, MatchingPartnerAccountStatus, error } from '../../constants';
import { ToastContext } from '../Toast/ToastProvider';
import Pagination from '../Pagination/Pagination';
import Spinner from '../Spinner';
import { ReusableTable } from '../ReusableTable';
import ViewPendingMatchPartner from '../ViewPendingMatchPartner';
import Dropdown from '../Dropdown';
import DataTable from 'react-data-table-component';

interface User {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  category: string;
  createdAt:string;
  address:[]
}
interface DropdownProps {
  name: string;
  list: string[];
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

const FilterDropDown: React.FC<DropdownProps> = ({ name, list, onChange }) => {
  return (
    <select name={name} onChange={onChange} className='block w-1/3 rounded border-2 bg-gray-50 p-2 focus:border-blue-500 focus:ring-blue-500 dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'>
      {list.map((item, index) => (
        <option key={index} value={item}>
          {item}
        </option>
      ))}
    </select>
  );
};

export default function MatchPartners() {
  const { handleCreateToast } = useContext(ToastContext);

  const [users, setUsers] = useState<User[]>([]);
  const [userId, setUserId] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showList, setShowList] = useState(false);
  const [showUser, setShowUser] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const listRef = useRef<HTMLUListElement | null>(null);
  const [countUsers, setCountUsers] = useState<number>(0);
  const [Reports, setReports] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [filter, setFilter] = useState<string>(''); // State for filter
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        listRef.current &&
        !listRef.current.contains(event.target as Node) &&
        event.target instanceof HTMLElement &&
        event.target.id !== 'search-input'
      ) {
        setShowList(false);
      }
    };

    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setShowList(false);
      }
    };

    if (showList) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEscapeKey);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [showList]);

  function onSuccess(users: any) {
    setUsers(users?.user);
    setMatchPartners(users?.user);
    setUserId(users?._id);
    setCountUsers(users.countUsers);
  }

  function onFail(message: any) {
    handleCreateToast(message, error);
  }

  useEffect(() => {
    let search = `${searchMatchPartnerEndpoint}${searchTerm}`;
    if (filter) {
       search = `${searchMatchPartnerEndpoint}${filter}`;
    }
    getContentWithPagination(search, onSuccess, onFail, currentPage);
  }, [searchTerm, currentPage, filter]);

  const [matchPartners, setMatchPartners] = useState([]);

  const partners = matchPartners.map((partner: any, idx: number) => {
    const newPartner = {
      _id: partner?._id,
      firstName: partner?.firstName,
      lastName: partner?.lastName,
      category: partner?.category,
      phoneNumber: partner?.userId[0]?.phoneNumber,
      createdAt: partner?.createdAt.split("T")[0],
      address:partner?.userId[0]?.address?.country
    };
    return newPartner;
  });

  function handleRowClick(row: any) {
    setSelectedRow(row._id)

    const user:any = matchPartners.find((item: any) => {
      return item?._id === row._id;
    });

    const userIds: any = {
      _id: user._id,
      userId: user.userId,
      imageIds: user.imageIds
    };
    setUserId(userIds);
    setShowUser(true);
  }

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  const matchingCategoryOptions = ['All', ...Object.keys(MatchingCategory)];
  const columns = [
    {
      name:'First Name',
      selector: (row: { firstName: any }) => row.firstName,
      width: '200px',
      cell: (row:any) => <span className="text-sm">{row.firstName}</span>,
    },
    {
      name:'Last Name',
      selector: (row: { lastName: any }) => row.lastName,
      width: '200px',
      cell: (row:any) => <span className="text-sm">{row.lastName}</span>,
    },
    {
      name:'Category',
      selector: (row: { category: any }) => row.category,
      width: '200px',
      cell: (row:any) => <span className="text-sm">{row.category}</span>,
    },
    {
      name:'Phone Number',
      selector: (row: { phoneNumber: any }) => row.phoneNumber,
      width: '200px',
      cell: (row:any) => <span className="text-sm">{row.phoneNumber}</span>,
    },
    {
      name:'Country',
      selector: (row: { address: any }) => row.address,
      width: '200px',
      cell: (row:any) => <span className="text-sm">{row.address}</span>,
    },
    {
      name:'Date',
      selector: (row: { createdAt: any }) => row.createdAt,
      width: '200px',
      cell: (row:any) => <span className="text-sm">{row.createdAt}</span>,
    },
  ];
  const conditionalRowStyles = [
    {
      when: (row: any) => row._id === selectedRow, 
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)', 
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
      },
    },
  ];
  return (
    <div className='m-8 '>
      <div className='flex flex-col relative space-y-2 max-w-sm '>
        <label htmlFor='duration' className='font-semibold'>
         {filter?filter:'All'} Posts
        </label>
        <div className='flex flex-col-2'>
       <input
          type='text'
          placeholder='Search users'
          value={searchTerm}
          onClick={() => setShowList(true)}
          onChange={e => {
            const searchTerm1 = e.target.value;
            const filteredAdmins:any = Array.isArray(users)
              ? users.filter(user =>
                  user.firstName
                    .toLowerCase()
                    .includes(searchTerm1.toLowerCase())
                )
              : [];
            setMatchPartners(filteredAdmins);
            setSearchTerm(searchTerm1);
            setCurrentPage(0);
          }}
          className='block w-full rounded border-2 bg-gray-50 p-2 focus:border-blue-500 focus:ring-blue-500 dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
        />
        <FilterDropDown
          name='Filter'
          list={matchingCategoryOptions}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            const value = e.currentTarget.value;
            setFilter(value === 'All' ? '' : value);
            setCurrentPage(0);
          }}
        />
        </div>
      </div>

      {showUser && (
        <ViewPendingMatchPartner
          setShowUser={setShowUser}
          partnerIds={userId}
          setPendingMatchPartners={setReports}
        />
      )}
 
      {loading ? (
        <Spinner />
      ) : (
        <div className='my-8 caret-transparent px-8 py-4 rounded-lg bg-white shadow-sm'>
          <div className="overflow-y-auto scrollbar max-h-[85%] p-4 m-4 rounded-lg bg-white shadow-sm">

<DataTable
    columns={columns}
    data={partners}
    pagination
    onRowClicked={handleRowClick}
    className="text-center"
    customStyles={{
      headCells: {
        style: {
          fontSize: '16px',
          paddingTop: '4px',
          paddingBottom: '4px',
        },
      },
      rows: {
        style: {
          paddingTop: '4px',
          paddingBottom: '4px',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            cursor: 'pointer',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          },
        },
      },
    }}
    conditionalRowStyles={conditionalRowStyles}
  />
          </div>

        </div>
      )}
    </div>
  );
}
