import React, { useContext, useEffect, useRef, useState } from 'react';
import { getBotMessages, getReportedUserProfilePicture, sendMessageToUser } from '../adapters/endpoints';
import { getContent } from '../adapters/get';
import { UserType, error, success } from '../constants';
import { ToastContext } from './Toast/ToastProvider';
import { createContent } from '../adapters/create';
import { UserNameAvatar } from './userNameAvatar';
import UserPopup from './UserPopup';

interface ChatWindowProps {
  userId: string;
}

const ChatWindow: React.FC<ChatWindowProps> = ({ userId }) => {
  const [messages, setMessages] = useState<any>();
  const [newMessage, setNewMessage] = useState<string>('');
	const { handleCreateToast } = useContext(ToastContext);
  const [jsonFormat, setJsonFormat] = useState<string | null>(null);

function onSuccessSendMessage(data: any, response: any) {
  handleCreateToast(response.message, success);
  const newMessageObj = {
    id: messages.length + 1,
    userId: userId,
    message: data.message,
    createdAt: new Date(),
    reactionFromSender: '', // Add reactions here if needed
    reactionFromReceiver: '', // Add reactions here if needed
  };
  setMessages((prevMessages :any) => ({
    ...prevMessages,
    messageIds: [...prevMessages.messageIds, newMessageObj],
    // Add other properties of the new message as needed
  }));
  
  setNewMessage('');
}
  const handleSendMessage = () => {
    const currentTime = new Date().toLocaleTimeString();
    const newMessageObj = {
      id: messages.length + 1,
      userId: userId,
      message: newMessage,
      createdAt: new Date(),
      reactionFromSender: '', // Add reactions here if needed
      reactionFromReceiver: '', // Add reactions here if needed
    };
    createContent(sendMessageToUser, newMessageObj, onSuccessSendMessage, onFail)
    // setMessages([...messages, newMessageObj]);
    // setNewMessage('');
  };

	function onSuccess(data: any) {
			setMessages(data?.botMessages[0]) 
			// console.log(data.botMessages[0]);		
 	}

  function onFail(message: any) {
    handleCreateToast(message, error);
  }

  useEffect(() => {
    if(userId){
      const messageWithId= `${getBotMessages}${userId}`
      getContent(messageWithId, onSuccess, onFail);
    }
  }, [userId]);

	const [senderProfilePic, setSenderProfilePic] = useState('')
	const [receiverProfilePic, setReceiverProfilePic] = useState('')

	function onSucessGettingSenderImg (data: any) {
		setSenderProfilePic(data?.userProfilePicture[0]?.image?.data)
	}

	function onSucessGettingReceiverImg (data: any) {
		setReceiverProfilePic(data?.userProfilePicture[0]?.image?.data)
	}

	useEffect(() => {
		if (messages && messages?.senderId) {
			const findSenderImg = `${getReportedUserProfilePicture}${messages?.senderId}`
			const findReceiverImg = `${getReportedUserProfilePicture}${messages?.receiverId[0]?._id}`

			getContent(findSenderImg, onSucessGettingSenderImg, onFail)
			getContent(findReceiverImg, onSucessGettingReceiverImg, onFail)
		}
	}, [messages])

  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    // Scroll to the bottom when the component mounts or when new messages are added
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };
  const toJsonClick = (user: string) => {
    setJsonFormat(user);
  };
  const closePopup = () => {
    setJsonFormat(null);

  };
  
  return (
    <div className="flex flex-col h-[90vh]">
       { jsonFormat && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">

        <div className="bg-white p-6 rounded-lg shadow-lg">
       <UserPopup user={jsonFormat} onClose={closePopup} />
    </div>
    </div>

  )}
      {userId === "" ?  
      <div className="flex items-center justify-center h-full border border-gray-200 px-6">
      <p> Select a user to start chatting </p>
      </div> : (
      <>
      <div ref={chatContainerRef} className="flex-grow h-0 overflow-y-auto scrollbar border-r border-gray-200">
        <div className='p-2 bg-[#2e2e2e] flex gap-4 items-center' >
                 <div>
                      <button  onClick={() => {
               toJsonClick(messages?.receiverId[0]?._id);
             }} >
                     
                      <img
                        className=' object-cover		border 
                      rounded-full  h-16 w-16 '
                      src={`data:image/jpeg;base64,${receiverProfilePic}`}
                      alt={messages?.receiverId[0]?.firstName}
                      />
                      </button>
                    </div>
                    <div>
                    <p className='text-white'>{messages?.receiverId[0]?.firstName}</p> 
                    <p className='text-white'>{messages?.receiverId[0]?.phoneNumber}</p>
                    </div>
        </div>
        {messages?.messageIds?.map((message :any) => (
          <div key={message.id} className={`flex gap-2 my-2 ${userId === message?.senderId ? 'flex' : 'flex-row-reverse'}`}>
            <div className={`flex items-start`}>
              {/* You can replace the ImgCircle component with your profile picture component */}
              <div className='h-10 w-10 overflow-hidden rounded-full'>
                <img
                  className='object-cover h-full w-full'
                  src={`data:image/jpeg;base64,${userId === message?.senderId ? receiverProfilePic : senderProfilePic }`}
                  alt={message.senderId}
                />
              </div>
            </div>
            <div className='flex flex-col'>
              <div className={`bg-gray-200 px-4 py-2 rounded-2xl ${userId === message?.receiverId ?  'mr-auto bg-gray-00' : 'ml-auto  bg-red-200'}`}>
                <p className='font-semibold'>{message.messageBody}</p>
              </div>
              <div className='flex justify-start'>
                <p className={`text-xs text-gray-400 mt-2 font-small ${userId === message?.receiverId ?  'ml-auto' : 'mr-auto'}`}>
                  {new Date(message.createdAt).toLocaleTimeString()}
                </p>
                <div className='flex justify-start'>
                  {message.reactionFromSender && (
                    <div
                      className={`h-8 w-8 bg-white border-2 border-gray-300 ${userId === message?.receiverId ? 'mr-0' : 'ml-0'} rounded-full flex items-center justify-center`}
                    >
                      <span role='img' aria-label='emoji'>
                        {message.reactionFromSender}
                      </span>
                    </div>
                  )}
                  {message.reactionFromReceiver && (
                    <div
                      className={`h-10 w-10 bg-white-300 border-2 border-gray-300 ${userId === message?.senderId ? 'ml-0' : 'mr-0'} rounded-full flex items-center justify-center`}
                    >
                      <span role='img' aria-label='emoji'>
                        {message.reactionFromReceiver}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className='flex justify-between items-center p-4 border-t border-gray-200'>
        <textarea
          className='w-3/4 p-2 border rounded'
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder='Type your message...'
        />
        <button
          className='bg-blue-500 text-white p-2 rounded'
          onClick={handleSendMessage}
          disabled={!newMessage.trim()}
        >
          Send
        </button>
      </div>
      </>
      )}
    </div>
  );
};

export default ChatWindow;
