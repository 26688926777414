import { useState, useEffect, useContext, useRef } from 'react'
import {
  GetUserInfoEndpoint,
  SearchUserEndpoint,
} from '../../adapters/endpoints'
import { getContent, getContentWithPagination } from '../../adapters/get'
import { error, } from '../../constants'
import { ToastContext } from '../Toast/ToastProvider'
import Spinner from '../Spinner'
import TestUserDetailPage from './TestUserDetailPage'
import ProfilePicture from '../ProfileImages'
import DataTable from 'react-data-table-component';

interface User {
  _id: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  address: {
    country: string
    region: string
    city: string
  },
  images: string[]; // assuming images is an array of URLs

}

export default function Users () {
  const { handleCreateToast } = useContext(ToastContext)

  const [users, setUsers] = useState<User[]>([])
  const [userId, setUserId] = useState<any>([])
  const [searchTerm, setSearchTerm] = useState<any>("")
  const [showList, setShowList] = useState(false)
  const [showUser, setShowUser] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const listRef = useRef<HTMLUListElement | null>(null)
  const [countUsers, setCountUsers] = useState<any>([])
  const [currentPage, setCurrentPage]=useState(0)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        listRef.current &&
        !listRef.current.contains(event.target as Node) &&
        event.target instanceof HTMLElement &&
        event.target.id !== 'search-input'
      ) {
        setShowList(false)
      }
    }

    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setShowList(false)
      }
    }

    if (showList) {
      document.addEventListener('mousedown', handleClickOutside)
      document.addEventListener('keydown', handleEscapeKey)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleEscapeKey)
    }
  }, [showList])

  // ...
  function onSuccess (users: any) {
    const userIds: any = {
      _id: users?._id,
      userId: users?._id,
      images: users?.images   
    };
    
    setUsers(users?.user)
    setUsersData(users?.user)
    setUserId(userIds)
    setCountUsers(users.countUsers)
  }

  function onFail (message: any) {
    handleCreateToast(message, error)
  }

  useEffect(() => {
    const search = `${SearchUserEndpoint}${searchTerm}`
    getContentWithPagination(search, onSuccess, onFail)
  }, [searchTerm])


  const [usersData, setUsersData] = useState(users)
  const [selectedRow, setSelectedRow] = useState(null);

  const userData = usersData.map((userData: any, idx: number) => {
    const newReviewer = {
      _id: userData?._id,
      firstName: userData?.firstName,
      lastName:userData?.lastName,
      phoneNumber:userData?.phoneNumber,
      accountStatus:userData?.accountStatus,
      country: userData?.address?.country,
      images:userData?.images,
      lastSeenAt: userData?.lastSeenAt.split("T")[0],
      createdAt: userData?.createdAt.split("T")[0],
      imageUrls:<ProfilePicture imageUrl = {userData?.imageUrls?.[0]?.imageUrls?.[0]} firstName = { userData?.firstName} />,
 
    }
    return newReviewer
  })

  const handleClick = (row:any) => {
    setSelectedRow(row._id)
    const user = userData.find((item: any) => {
      return item?._id === row._id;
    });
    
    const userIds: any = {
      _id: row._id,
      userId: user?._id,
      images: user?.images   
    };
    
    setUserId(userIds)
    setShowUser(true)

  }

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])
  const [first, setFirst] = useState(false)

  useEffect(() => {
    if (showUser === true) {
      
      getContent(`${GetUserInfoEndpoint}${userId?._id}`, setFirst, onFail)
    }
  }, [showUser])
  const columns = [
    
    {
      name:'Profile',
      selector: (row: { imageUrls: any }) => row.imageUrls,
      width: '150px',
      cell: (row:any) => <span className="text-sm">{row.imageUrls}</span>,
    },
   
    {
      name: "First Name",
      selector: (row: { firstName: any }) => row.firstName,
      width: '150px',
      cell: (row:any) => <span className="text-sm">{row.firstName}</span>, 
      },
      {
        name: "Last Name",
        selector: (row: { lastName: any }) => row.lastName,
        width: '150px',
        cell: (row:any) => <span className="text-sm">{row.lastName}</span>, 
        },
      {
        name: "Phone Number",
        selector: (row: { phoneNumber: any }) => row.phoneNumber,
        width: '200px',
        cell: (row:any) => <span className="text-sm">{row.phoneNumber}</span>, 
        },
    
    {
      name: "Status",
      selector: (row: { accountStatus: any }) => row.accountStatus,
      width: '150px', 
      cell: (row:any) =>
         <span className={row.accountStatus === 'pending' ? 'text-red' : ''}>{row.accountStatus}
         </span>, 
    },
    {
      name: "Country",
      selector: (row: { country: any }) => row.country,
      width: '150px', 
      cell: (row:any) => <span className="text-sm">{row.country}</span>, 
    },
    {
      name: "Last seen",
      selector: (row: { lastSeenAt: any }) => row.lastSeenAt,
      width: '150px',
      cell: (row:any) => <span className="text-sm">{row.lastSeenAt}</span>, 
    },
    {
      name: "Created",
      selector: (row: { createdAt: any }) => row.createdAt ,
      width: '150px', 
      cell: (row:any) => <span className="text-sm">{row.createdAt}</span>, 
    },
  ];
  const conditionalRowStyles = [
    {
      when: (row: any) => row._id === selectedRow, 
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)', 
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
      },
    },
  ];
  return (
    <div className='m-8 scrollbar scrollbar-hide overflow overflow-hidden h-screen w-[100%]'>
      <div className='flex flex-col relative space-y-2  max-w-sm '>
        <label htmlFor='duration' className='font-semibold'>
          Users
        </label>
        <input
          type='text'
          placeholder='Search users'
          value={searchTerm}
          onClick={() => setShowList(true)}
          onChange={e => {
            const searchTerm1 = e.target.value
            const filteredAdmins = Array.isArray(users)
              ? users.filter(user =>
                  user.firstName
                    .toLowerCase()
                    .includes(searchTerm?.toLowerCase())
                )
              : []
            setUsersData(filteredAdmins)
            setSearchTerm(searchTerm1)
            setCurrentPage(0)
          }}
          className='block  w-full rounded border-2 bg-gray-50 p-2 focus:border-blue-500  focus:ring-blue-500  dark:placeholder-gray-400  dark:focus:border-blue-500 dark:focus:ring-blue-500'
        />
      </div>

      {showUser && (

        <TestUserDetailPage
          userIds={userId}
          userInfo={first}
          setShowUser={setShowUser}
        />
      )}

      {loading ? (
        <Spinner />
      ) : (
        <div className=' overflow-y-auto  max-h-[80%] p-4 m-4 rounded-lg bg-white shadow-sm'>
       <DataTable
      columns={columns}
      data={userData}
      pagination
      onRowClicked={handleClick}
      className="text-center" 
      customStyles={{
        headCells: {
          style: {
            fontSize: '16px',
            paddingTop: '4px',
            paddingBottom: '4px',
            backgroundColor:'#ADD8E6'

          },
        },
        rows: {
          style: {
            paddingTop: '4px',
            paddingBottom: '4px',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.05)', 
              cursor: 'pointer',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
            },
          },
        },
      }}
      conditionalRowStyles={conditionalRowStyles} // Add this line

    />
        </div>
      )}
    </div>
  )
}
