import { Layout, MessageSquare, Settings, Users } from "react-feather";

export const AdminSidebarData = [
	{
		title: "Dashboard",
		path: "Dashboard",
		icon: <Layout className=" w-5 h-5 stroke-2" />,
		cName: "nav-text",
	},
	{
    title: "Verification Requests",
    path: "VerificationRequests",
    icon: <Users className=" w-5 h-5 stroke-2" />,
    cName: "nav-text",
  },
  {
    title: "Review Verified Users",
    path: "ReviewVerifiedUsers",
    icon: <Users className=" w-5 h-5 stroke-2" />,
    cName: "nav-text",
  },
  {
    title: "Reported Users",
    path: "ReportUsers",
    icon: <Users className=" w-5 h-5 stroke-2" />,
    cName: "nav-text",
  },
  {
    title: "Reported Chats Users",
    path: "ReportChats",
    icon: <MessageSquare className=" w-5 h-5 stroke-2" />,
    cName: "nav-text",
  },
  {
    title: "Pending Posts",
    path: "PendingMatchPartner",
    icon: <Users className=" w-5 h-5 stroke-2" />,
    cName: "nav-text",
  },
  {
	title: "Posts",
	path: "MatchPartners",
	icon: <Users className=" w-5 h-5 stroke-2" />,
	cName: "nav-text",
},
  {
		title: "Admins",
		path: "Admins",
		icon: <Users className=" w-5 h-5 stroke-2" />,
		cName: "nav-text",
	},
	{
		title: "Users",
		path: "Users",
		icon: <Users className=" w-5 h-5 stroke-2" />,
		cName: "nav-text",
	},
	
	{
		title: "Chat",
		path: "Chat",
		icon: <MessageSquare className=" w-5 h-5 stroke-2" />,
		cName: "nav-text",
	},
	{
		title: "Settings",
		path: "Settings",
		icon: <Settings className=" w-5 h-5 stroke-2" />,
		cName: "nav-text",
	},

];
