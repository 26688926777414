import { Route, Routes } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import ReportChats from "./ReportChats";
import ReportUsers from "./ReportUsers";
import VerificationRequests from "./VerificationRequests";
import ReviewVerifiedUsers from "./VerifiedUsers";
import Cookies from "universal-cookie";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SidebarData } from "../components/SideBarData";
import { SIGN_IN_ROUTE } from "../constants";
import Settings from "./Settings";
import Chat from './Chat';
import Users from '../components/AdminManager/Users';
import PendingMatchPartnerUser from "./PendingMatchPartnerUser";
import MatchPartners from "../components/AdminManager/MatchPartners";
import ReviewerSidebar from "../components/ReviewerSideBar";

export default function Dashboard() {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const isAdminAuth = cookies.get("isAuth");

  useEffect(() => {
    if (isAdminAuth === "false") {
      navigate(SIGN_IN_ROUTE);
    }
  }, []);

  return (
    <div className="flex h-screen bg-[#F5F5F5]">
      <ReviewerSidebar routes={SidebarData} />
      <main className=" w-full ">
        <Routes>
          <Route
            path="VerificationRequests"
            element={<VerificationRequests />}
          />
           <Route
            path="ReviewVerifiedUsers"
            element={<ReviewVerifiedUsers />}
          />
           <Route
            path="PendingMatchPartner"
            element={<PendingMatchPartnerUser />}
          />
          <Route path="ReportUsers" element={<ReportUsers />} />
          <Route path="ReportChats" element={<ReportChats />} />
          <Route path="Settings" element={<Settings />} />
          <Route path="users" element={<Users />} />
          <Route path="MatchPartners" element={<MatchPartners />} />

          <Route path="Chat" element={<Chat />} />
        </Routes>
      </main>
    </div>
  );
}
