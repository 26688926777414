import { useState, useEffect, useContext, Dispatch, SetStateAction } from 'react'
import {
  ChevronLeft,
  ChevronRight,
  MessageSquare,
  MoreVertical,
  User,
  UserCheck,
  UserX,
  X
} from 'react-feather'
import { createContent, postApi } from '../../adapters/create'
import {
  getAdminEndPoint,
  deActivateAdminAccount,
  removeFromReviewer,
  findAllReviewedRequestsByReviewerEndPoint,
  updateReviewerRole,
  getCountUserRequestsByReviewerId,
  getReviewedUserVerificationRequestsEndpoint,
  getReviewedReportedUserEndpoint,
  getReviewedReportedChatsEndpoint
} from '../../adapters/endpoints'

import { getContent, getContentWithPagination, getContentWithPayload } from '../../adapters/get'
import { updateContent } from '../../adapters/update'
import {
  AdminRole,
  error,
  NotReviewedAnyUserYet,
  ReviewerAccountStatus,
  reviewerActionType,
  success,
  UsersTypeEnum
} from '../../constants'
import { ToastContext } from '../Toast/ToastProvider'
import ViewUser from './ViewUser'
import AlertPopup from '../AlertPopup'

interface UsersProps {
  users: any[]
  usersType: string;
  currentPage:number;
  setCurrentPage:Dispatch<SetStateAction<number>>;
  countVerificationTotal?:number;
  countReportedUserTotal?:number;
  countReportedChatTotal?:number;

}

const Users = ({ users, usersType,currentPage ,setCurrentPage,countVerificationTotal,countReportedUserTotal,countReportedChatTotal}: UsersProps) => {
  const [showUserData, setShowUserData] = useState(false)
  const [report, setReport] = useState({
    _id: '',
    type: ''
  })

  const handlePageClick = (pageNumber:any) => {
    setCurrentPage(pageNumber);
  };
  const tableheadings = ['#', 'Responsible', 'Reporter', 'Report Type', 'Requested Date','Reviewed Date']

  const ReusableTable = ({ children, tableheadings }: any) => {
    return (
      <table className='w-full rounded-lg  bg-white shadow-md'>
        <thead>
          <tr>
            {tableheadings.map((heading: any) => (
              <th
                key={heading}
                className='text-gray-600    font-semibold py-3    first:pr-4 first:pl-4 first:text-center text-left '
              >
                {heading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {children?.map((user: any, index: any) => {
            return (
              <tr
                key={user._id}
                onClick={() => {
                  setReport({
                    _id: user._id,
                    type: usersType
                  })
                  setShowUserData(true)
                }}
                className={` border-t hover:bg-gray-100  hover:cursor-pointer  
                `}
              >
                <td className=' text-center '>{index + 1}</td>
                {Object.values(user)
                  .slice(1)
                  .map((userDetail: any, idx) => {
                    return (
                      <td key={idx} className='py-3 '>
                        {userDetail} 
                      </td>
                    )
                  })}
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  const ReportedUsersTable = () => {
    
    const newUsers = users.map((user: any) => {
      const newUser = {
        _id: user._id,
        responsibleName: user.responsibleId[0]?.firstName,
        reporterName: user.reporterId[0]?.firstName,
        violationType: user.violationType,
        date: new Date(user.createdAt).toLocaleDateString('en-US'),
        reviewed: new Date(user.reviewedAt).toLocaleDateString('en-US')

      }

      return newUser
    })

    return (
      <div>
      <ReusableTable tableheadings={tableheadings}>{newUsers}</ReusableTable>
      <div className="flex items-center justify-center gap-4 overflow-auto mt-4"> 
        {currentPage > 0 && (
          <button
            className="pagination-item"
            onClick={() => handlePageClick(currentPage - 1)}
          >
            <ChevronLeft />
          </button>
        )}
            {[...Array(Math.ceil((countReportedUserTotal ?? 0) / 8))?.keys()].map((page) => (
          <div
            key={page}
            className={`w-10 h-10  flex justify-center items-center rounded-lg cursor-pointer ${
              currentPage === page ? 'bg-slate-800 text-white' : 'bg-white text-gray-800'
            }`}
            onClick={() => setCurrentPage(page)}
          >
            <div>{page + 1}</div>
          </div>
        ))}
        {currentPage < (Math.ceil((countReportedUserTotal ?? 0)/8) -1) && (
          <button
            className="pagination-item"
            onClick={() => handlePageClick(currentPage + 1)}
          >
            <ChevronRight />
          </button>
        )}
        </div>
      </div>
    )
  }
  const ReportedChatsTable = () => {
    
    const newUsers = users.map((user: any) => {
      const newUser = {
        _id: user._id,
        responsibleName: user.responsibleId[0]?.firstName,
        reporterName: user.reporterId[0]?.firstName,
        violationType: user.violationType,
        date: new Date(user.createdAt).toLocaleDateString('en-US'),
        reviewed: new Date(user.reviewedAt).toLocaleDateString('en-US')

      }

      return newUser
    })

    return (
      <div>
      <ReusableTable tableheadings={tableheadings}>{newUsers}</ReusableTable>
      <div className="flex items-center justify-center gap-4 overflow-auto mt-4"> 
        {currentPage > 0 && (
          <button
            className="pagination-item"
            onClick={() => handlePageClick(currentPage - 1)}
          >
            <ChevronLeft />
          </button>
        )}
            {[...Array(Math.ceil((countReportedChatTotal ?? 0) / 8))?.keys()].map((page) => (
          <div
            key={page}
            className={`w-10 h-10  flex justify-center items-center rounded-lg cursor-pointer ${
              currentPage === page ? 'bg-slate-800 text-white' : 'bg-white text-gray-800'
            }`}
            onClick={() => setCurrentPage(page)}
          >
            <div>{page + 1}</div>
          </div>
        ))}
        {currentPage <  (Math.ceil((countReportedChatTotal ?? 0) / 8) -1) && (
          <button
            className="pagination-item"
            onClick={() => handlePageClick(currentPage + 1)}
          >
            <ChevronRight />
          </button>
        )}
        </div>
      </div>
    )
  }
  const VerificationRequestsTable = () => {
    const tableheadings = ['#', 'Name', 'Location', 'Requested Date','Reviewed Date', 'Status']

    const newUsers = users.map((user: any) => {
      const newUser = {
        _id: user._id,
        userName: user?.userInfoId[0]?.firstName,
        city: user.userInfoId[0]?.address?.city,
        date: new Date(user.createdAt).toLocaleDateString('en-US'),
        reviewed: new Date(user.reviewedAt).toLocaleDateString('en-US'),
        reviewerAction: user?.isGenuine ? 'verify': 'declien'

      }

      return newUser
    })
console.log(users);

    return (
      <div>
      <ReusableTable tableheadings={tableheadings}>{newUsers}</ReusableTable>
      <div className="flex items-center justify-center gap-4 overflow-auto mt-4"> 
        {currentPage > 0 && (
          <button
            className="pagination-item"
            onClick={() => handlePageClick(currentPage - 1)}
          >
            <ChevronLeft />
          </button>
        )}
            {[...Array(Math.ceil((countVerificationTotal ?? 0) / 8))?.keys()].map((page) => (
          <div
            key={page}
            className={`w-10 h-10  flex justify-center items-center rounded-lg cursor-pointer ${
              currentPage === page ? 'bg-slate-800 text-white' : 'bg-white text-gray-800'
            }`}
            onClick={() => setCurrentPage(page)}
          >
            <div>{page + 1}</div>
          </div>
        ))}
        {currentPage <  (Math.ceil((countVerificationTotal ?? 0) / 8) -1) && (
          <button
            className="pagination-item"
            onClick={() => handlePageClick(currentPage + 1)}
          >
            <ChevronRight />
          </button>
        )}
        </div>
      </div>
    )
  }

  const hasValidUsers = users && users.length > 0

  let tableComponent = null

  switch (usersType) {
    case UsersTypeEnum.REPORTED_USER:
      tableComponent = hasValidUsers ? <ReportedUsersTable /> : null
      break
    case UsersTypeEnum.USERS_CHAT_GOT_REPORTED:
      tableComponent = hasValidUsers ? <ReportedChatsTable /> : null
      break
    case UsersTypeEnum.USERS_APPLIED_FOR_VERIFICATION:
      tableComponent = hasValidUsers ? <VerificationRequestsTable /> : null
      break
    default:
      break
  }


  return (
    <div className='scrollbar-hide h-[60vh] overflow-auto'>
      {tableComponent ? (
        tableComponent
      ) : (
        <p className='text-center py-4 px-2 bg-gray-100'>
          {NotReviewedAnyUserYet}
        </p>
      )}

      {showUserData && (
        <ViewUser reportInfo={report} setShowUser={setShowUserData} />
      )}
          {/* <button
        className=" my-4  font-semibold  bg-[#2e2e2e] text-[#E7E7E9] px-14 rounded py-3 "
        // onClick={handleSubmit(async (data) => {
        //   const nextEngineer = { ...data };

        //   createContent(AddEngineers, nextEngineer, onSuccess, onFail);
        // })}
      >
        Next
      </button> */}
      
    </div>
  )
}

const IconCard = ({ icon: Icon, title, color, children }: any) => {
  return (
    <div className='flex gap-2'>
      {/* <div className={`bg-${color}-100 rounded-md p-2`}>
        <Icon className=" text-[#2e2e2e] " />
      </div> */}
      <div>
        <strong>{children}</strong>
        <p className=' text-sm text-gray-400 '>{title}</p>
      </div>
    </div>
  )
}

const ReviewerSettings = ({
  reviewer,
  setShowEditOptions,
  setShowPopup,
  role,
  setRole,
  setActionType
}: any) => {
  const roles = Object.keys(AdminRole)

  useEffect(() => {
    function getKeyByValue (value: string) {
      for (const key in AdminRole) {
        if (AdminRole[key as keyof typeof AdminRole] === value) {
          return key
        }
      }
      return null
    }

    const key = getKeyByValue(reviewer?.role)

    setRole(key)
  }, [reviewer])

  return (
    <div className='popup shadow-2xl py-4  '>
      <div className=' px-4 py-2  flex justify-end  '>
        <X
          className='  cursor-pointer'
          onClick={() => setShowEditOptions(false)}
        />
      </div>
      <p
        onClick={() => {
          setActionType(reviewerActionType.remove)
          setShowPopup(true)
        }}
        className=' hover:bg-gray-100 cursor-pointer  py-1 px-6'
      >
        Remove
      </p>
      <p
        onClick={() => {
          setActionType(reviewerActionType.deactivate)
          setShowPopup(true)
        }}
        className='  hover:bg-gray-100 cursor-pointer py-1 px-6'
      >
        Deactivate
      </p>
      <p
        onClick={() => {
          setActionType(reviewerActionType.activate); // Set the action type to activate
          setShowPopup(true);
        }}
        className='hover:bg-gray-100 cursor-pointer  py-1 px-6'
      >
        Activate
      </p>
      <form className='flex flex-col my-2 py-2 px-6 '>
        <label htmlFor='role-select ' className='  font-bold'>
          Current Role
        </label>

        <select
          id='role-select'
          className=' capitalize my-1 flex items-center gap-2 bg-gray-200 p-2 rounded  '
          value={role}
          onChange={event => {
            setRole(event.target.value)
            setActionType(reviewerActionType.updateRole)
            setShowPopup(true)
          }}
        >
          {roles.map(option => {
            const roleName = option?.split('_').join(' ').toLowerCase()
            return (
              <option
                key={option}
                value={option}
                className='capitalize bg-white '
              >
                {roleName}
              </option>
            )
          })}
        </select>
      </form>
    </div>
  )
}

export default function Reviewer ({ _id, setShowUser }: any) {
  const [countReportedUsers, setCountReportedUsers] = useState<any>([])
  const [countReportedChats, setCountReportedChats] = useState<any>([])
  const [countVerificationRequests, setCountVerificationRequests] =
    useState<any>([])

  const [reviewer, setReviewer] = useState<any>()
  const [showEditOptions, setShowEditOptions] = useState(false)
  const { handleCreateToast } = useContext(ToastContext)

  const [reviewRequestOfReviewer, setReviewRequestOfReviewer] = useState<any>([])

  const [reportedUsers, setReportedUsers] = useState<any>()
  const [verificationUsers, setVerificationUsers] = useState<any>()
  const [reportedChatsUsers, setReportedChatsUsers] = useState<any>()
  const [showPopup, setShowPopup] = useState<Boolean>(false)
  const [role, setRole] = useState<any>()
  const [actionType, setActionType] = useState<any>()

  // const [currentPage, setCurrentPage] = useState(0);
const [currentPage, setCurrentPage] = useState(0);
const [countVerificationTotal, setcountVerificationTotal] = useState(0); 
const [countReportedUserTotal, setReportedUserTotal] = useState(0);
const [countReportedChatTotal, setReportedChatTotal] = useState(0);
const [totalPagesTotal, setTotalPagesTotal] = useState(0);

// Function to handle page clicks
const handlePageClick = (currentPage:any) => {
  setCurrentPage(currentPage);
  
  getContentWithPagination(
    findReviewedUserVerificationRequestsWithId,
    onSuccessGettingVerificationUsers,
    onFail,
    currentPage,
  )
  getContentWithPagination(
    findReviewedReportedUserWithId,
    onSuccessGettingReportedUsers,
    onFail,
    currentPage,
  )
  getContentWithPagination(
    findReviewedReportedChatsWithId,
    onSuccessGettingReportedChats,
    onFail,
    currentPage,
  )
};



  function onSuccessReviewRequestOfReviewer (data: any) {
    setReviewRequestOfReviewer(data)
  }

  function onSuccessGettingReviewer (data: any) {
    setReviewer(data)
  }

  function onSuccessGettingReviewerStats (data: any) {
    setCountReportedUsers(data.countReportedUsers[0])
    setCountReportedChats(data.countReportedChats[0])
    setCountVerificationRequests(data.countUserVerificationRequests[0])
  }

  function onFail (message: any) {
    handleCreateToast(message, error)
  }

  const GET_REVIEWER = `${getAdminEndPoint}${_id}`

  const getAll = `${getCountUserRequestsByReviewerId}${_id}`
  // const findAllReviewedRequestsByReviewerWithID = `${findAllReviewedRequestsByReviewerEndPoint}${_id}`
  const findReviewedUserVerificationRequestsWithId = `${getReviewedUserVerificationRequestsEndpoint}${_id}`
  const findReviewedReportedUserWithId = `${getReviewedReportedUserEndpoint}${_id}`
  const findReviewedReportedChatsWithId = `${getReviewedReportedChatsEndpoint}${_id}`

  
  useEffect(() => {
    getContent(GET_REVIEWER, onSuccessGettingReviewer, onFail)  // getContent(getCountReportedUsers, onSuccessGettingReviewerStats, onFail);
    getContent(getAll, onSuccessGettingReviewerStats, onFail)
  }, [GET_REVIEWER, _id])

  function onSuccessGettingVerificationUsers (data: any) {
    setVerificationUsers(data.reviewedVerificationReq)
    setcountVerificationTotal(data.countReviewedVerificationReq)
    
  }
  function onSuccessGettingReportedUsers (data: any) {
    setReportedUsers(data.reviewedReportedUsers)
    setReportedUserTotal(data.countReviewedReports)
  }
  function onSuccessGettingReportedChats (data: any) {
    setReportedChatsUsers(data.reviewedReportedChats)
    setReportedChatTotal(data.countReviewedReportedChat)
  }
  useEffect(() => {
    
    getContentWithPagination(
      findReviewedUserVerificationRequestsWithId,
      onSuccessGettingVerificationUsers,
      onFail,
      currentPage,
    )
    getContentWithPagination(
      findReviewedReportedUserWithId,
      onSuccessGettingReportedUsers,
      onFail,
      currentPage,
    )
    getContentWithPagination(
      findReviewedReportedChatsWithId,
      onSuccessGettingReportedChats,
      onFail,
      currentPage,
    )
  }, [_id,currentPage])

  // useEffect(() => {
  //   setReportedUsers(reviewRequestOfReviewer.reviewedReportedUsersByReviewer)
  //   setVerificationUsers(
  //     reviewRequestOfReviewer.reviewedUserVerificationRequestsByReviewer
  //   )
  //   setReportedChatsUsers(
  //     reviewRequestOfReviewer.reviewedReportedChatByReviewerWithId
  //   )
  // }, [reviewRequestOfReviewer, _id])
// console.log(reviewRequestOfReviewer);

  const [tab, setTab] = useState(1)
// console.log(reportedUsers);

  const tabComponents = {
    1: (<Users
        users={verificationUsers}
        usersType={UsersTypeEnum.USERS_APPLIED_FOR_VERIFICATION}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        countVerificationTotal={countVerificationTotal}
      />),
    2: <Users users={reportedUsers} usersType={UsersTypeEnum.REPORTED_USER} currentPage={currentPage}   setCurrentPage={setCurrentPage} countReportedUserTotal={countReportedUserTotal}/>,
    3: (<Users
        users={reportedChatsUsers}
        usersType={UsersTypeEnum.USERS_CHAT_GOT_REPORTED}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        countReportedChatTotal={countReportedChatTotal}
      />)
  }

  const onSuccess = (response: any) => {
    handleCreateToast(response.message, success)
  }

  const onSuccessUpdateContent = (data: any, response: any) => {
    handleCreateToast(response.message, success)
  }

  const onSubmit = () => {
    const roleType = {
      roleType: AdminRole[role as keyof typeof AdminRole]
    } 
    switch (actionType) {
      case reviewerActionType.remove:
        getContent(`${removeFromReviewer}${_id}`, onSuccess, onFail)
        break
      case reviewerActionType.deactivate:
        updateContent(`${deActivateAdminAccount}${_id}`,{reviewerStatus:ReviewerAccountStatus.DEACTIVATE}, onSuccess, onFail)
        break
        case reviewerActionType.activate:
          updateContent(`${deActivateAdminAccount}${_id}`,{reviewerStatus:ReviewerAccountStatus.ACTIVATE}, onSuccess, onFail)
          break
      case reviewerActionType.updateRole:
        updateContent(
          `${updateReviewerRole}${reviewer?._id}`,
          roleType,
          onSuccessUpdateContent,
          onFail
        )
        break
      default:
        handleCreateToast('Invalid action type',error)
    }

    setShowPopup(false)
  }

  const countUserRequests = (userRequestType: any) => {
    return (
      <div className='grid grid-cols-3 gap-y-4 bg-gray-50 p-4 '>
        <IconCard icon={User} color='blue' title='Total Users'>
          {userRequestType?.total}
        </IconCard>
        <IconCard icon={User} color='red' title='Pending Actions'>
          {userRequestType?.totalPending}
        </IconCard>
        <IconCard icon={User} color='gray' title='Total Actions'>
          {userRequestType?.totalReviewed}
        </IconCard>
      </div>
    )           
  }
  const setResultBasedOnTab = () => {
    if (tab === 1) {
      return <div>{countUserRequests(countVerificationRequests)}</div>
    } else if (tab === 2) {
      return <div>{countUserRequests(countReportedUsers)}</div>
    } else if (tab === 3) {
      return <div>{countUserRequests(countReportedChats)}</div>
    }
  }
  return (
    <>
      <div className=' fixed inset-0 z-40 flex h-full  w-full items-center justify-center bg-gray-900 bg-opacity-50 '>
        <div className='p-8 absolute space-y-4 top-0 right-0 w-[50%] bg-white rounded-l-md z-50 h-full '>
          <div className='flex justify-between  '>
            <div>
              <p>
                {reviewer?.firstName} {reviewer?.lastName ?? ""}
              </p>
            </div>
            <div className='flex gap-4'>
              <MoreVertical
                onClick={() => setShowEditOptions(!showEditOptions)}
                className=' h-5 w-5 cursor-pointer'
              />
              <X
                onClick={() => setShowUser(false)}
                className=' h-5 w-5 cursor-pointer'
              />
            </div>
          </div>
          {showEditOptions && (
            <ReviewerSettings
              reviewer={reviewer}
              setShowEditOptions={setShowEditOptions}
              setShowPopup={setShowPopup}
              role={role}
              setRole={setRole}
              setActionType={setActionType}
            />
          )}
          {setResultBasedOnTab()}

          <div className='flex item-center justify-center gap-6  font-bold  overflow-scroll w-full '>
          <p
              onClick={() => setTab(1)}
              className={`  cursor-pointer whitespace-nowrap p-2 ${
                tab === 1 && 'border-b-4 border-[#2e2e2e]'
              }`}
            >
              Verification Requests
            </p>
            <p
              onClick={() => setTab(2)}
              className={`  cursor-pointer whitespace-nowrap  p-2 ${
                tab === 2 && 'border-b-4 border-[#2e2e2e]'
              }`}
            >
              Reported Users
            </p> 
            <p
              onClick={() => setTab(3)}
              className={`  cursor-pointer whitespace-nowrap p-2 ${
                tab === 3 && 'border-b-4 border-[#2e2e2e]'
              }`}
            >
              Reported Chats Users
            </p>
          </div>
          <div>
          {tab in tabComponents &&
            tabComponents[tab as keyof typeof tabComponents]}
          </div>
          {/* <div className="pagination">
        {currentPage > 0 && (
          <button
            className="pagination-item"
            onClick={() => handlePageClick(currentPage - 1)}
          >
            <ChevronLeft />
          </button>
        )}
            {[...Array(Math.ceil(countVerification / 8))?.keys()].map((page) => (
          <div
            key={page}
            className={`w-10 h-10  flex justify-center items-center rounded-lg cursor-pointer ${
              currentPage === page ? 'bg-slate-800 text-white' : 'bg-white text-gray-800'
            }`}
            onClick={() => setCurrentPage(page)}
          >
            <div>{page + 1}</div>
          </div>
        ))}
        {currentPage < 13 && (
          <button
            className="pagination-item"
            onClick={() => handlePageClick(currentPage + 1)}
          >
            <ChevronRight />
          </button>
        )}
      </div> */}
        </div>
      </div>
      {showPopup ? (
        <AlertPopup
          message='Are you sure you want to take this action?'
          Close={() => {
            setShowPopup(false)
          }}
          onSubmit={onSubmit}
        ></AlertPopup>
      ) : null}
    </>
  )
}
