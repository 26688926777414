import { useState, useEffect, useContext, useRef } from 'react';
import { findAllAdminsEndPoint } from '../../adapters/endpoints';
import { getContent } from '../../adapters/get';
import { AdminRole, error, getEnumKeyFromValue } from '../../constants';
import { ToastContext } from '../Toast/ToastProvider';
import Reviewer from './Reviewer';
import Pagination from '../Pagination/Pagination';
import usePagination from '../Pagination/usePagination';
import Spinner from '../Spinner';
import { ReusableTable } from '../ReusableTable';
import { getCountUsersRequestsBasedOnReviewerId } from '../../adapters/endpoints';
import DataTable from 'react-data-table-component';

interface admin {
  createdAt: string;
  firstName: string;
  isAdminManager: boolean;
  lastName: string;
  role: string;
  __v: 0;
  _id: string;
  total: number;
  totalReviewed: number;
  totalPending: number;
}

export default function Admins() {
  const { handleCreateToast } = useContext(ToastContext);

  const [admins, setAdmins] = useState<admin[]>([]);
  const [reviewerId, setReviewerId] = useState<string>();
  const [searchTerm, setSearchTerm] = useState<any>();
  const [showList, setShowList] = useState(false);
  const [showUser, setShowUser] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const listRef = useRef<HTMLUListElement | null>(null);
  const [countUsers, setCountUsers] = useState<any>([]);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        listRef.current &&
        !listRef.current.contains(event.target as Node) &&
        event.target instanceof HTMLElement &&
        event.target.id !== 'search-input'
      ) {
        setShowList(false);
      }
    };

    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setShowList(false);
      }
    };

    if (showList) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEscapeKey);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [showList]);


  function onSuccess(admins: admin[]) {
    setAdmins(admins);
    setReviewerAdmins(admins);
    setReviewerId(admins[0]?._id);
  }

  function onFail(message: any) {
    handleCreateToast(message, error);
  }

  function onCountUserRequestsSuccess(data: any) {
    setCountUsers(data.countUserRequests);
  }
  useEffect(() => {
    getContent(
      getCountUsersRequestsBasedOnReviewerId,
      onCountUserRequestsSuccess,
      onFail
    );
  }, []);

  useEffect(() => {
    getContent(findAllAdminsEndPoint, onSuccess, onFail);
  }, []);

  const [reviewerAdmins, setReviewerAdmins] = useState<any[]>([]);

  const [currentPage, setCurrentPage, itemsPerPage, currentItems] =
    usePagination(reviewerAdmins);
    const [selectedRow, setSelectedRow] = useState(null);

  const mergeArrays = (admins: admin[], countUsers: any[]) => {
    return admins.map((admin) => ({
      ...countUsers.find(
        (countUsersData: any) => countUsersData._id === admin._id && countUsersData
      ),
      ...admin,
    }));
  };

  const updateReviewerAdmins = (newAdmins: admin[]) => {
    const reviewers = newAdmins.map((reviewer: any, idx: number) => {
      const status = () =>{
        if (reviewer?.isAccountActive === true){
          return (
            <div>
              <div className="w-4 h-4 bg-green-500 rounded-full"></div>
            </div>
          ) 
        } else{
          return (
            <div>
              <div className="w-4 h-4 bg-red-500 rounded-full"></div>
            </div>
          )
        }
      }
      const newReviewer = {
        _id: reviewer?._id,
        reviewer: reviewer?.firstName,
        role: getEnumKeyFromValue(AdminRole, reviewer?.role)
          ?.split('_')
          .join(' ')
          .toLowerCase(),
          status:status(),
        totalUsers: reviewer?.total,
        totalActions: reviewer?.totalReviewed,
        pendingActions: reviewer?.totalPending,
      };

      if (
        !newReviewer.totalActions &&
        !newReviewer.totalUsers &&
        !newReviewer.pendingActions
      ) {
        newReviewer.totalActions = 0;
        newReviewer.totalUsers = 0;
        newReviewer.pendingActions = 0;
      }
      return newReviewer;
    });
    setReviewerAdmins(reviewers);
  };

  useEffect(() => {
    const mergedArray = mergeArrays(admins, countUsers);
    updateReviewerAdmins(mergedArray);
  }, [admins, countUsers]);

  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    const filteredAdmins = admins.filter((admin: any) =>
      admin?.firstName?.toLowerCase().includes(searchTerm?.toLowerCase())
    );
    const mergedArray = mergeArrays(filteredAdmins, countUsers);
    updateReviewerAdmins(mergedArray);
  };


  const handleClick = (row: any) => {
    setSelectedRow(row._id)

    setShowUser(true);
    setReviewerId(row._id);
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  const columns:any = [
    {
      name:'Reviewer',
      selector: (row: { reviewer: any }) => row.reviewer,
      width: '150px',
      cell: (row:any) => <span className="text-sm">{row.reviewer}</span>,
    },
    {
      name: "Role",
      selector: (row: { role: any }) => row.role,
      width: '250px',
      cell: (row:any) => <span className="text-sm">{row.role}</span>, 
      },
      {
        name: "Total Users",
        selector: (row: { totalUsers: any }) => row.totalUsers,
        width: '200px',
        cell: (row:any) => <span className="text-sm">{row.totalUsers}</span>, 
        },
      {
        name: "Total Actions",
        selector: (row: { totalActions: any }) => row.totalActions,
        width: '200px',
        cell: (row:any) => <span className="text-sm">{row.totalActions}</span>, 
        },
        {
          name: "Pending Actions",
          selector: (row: { pendingActions: any }) => row.pendingActions,
          width: '200px',
          cell: (row:any) => <span className="text-sm">{row.pendingActions}</span>, 
          },
          {
            name: "Status",
            selector: (row: { status: any }) => row.status,
            width: '200px',
            cell: (row:any) => <span className="text-sm">{row.status}</span>, 
            },
    
  ]
  const conditionalRowStyles = [
    {
      when: (row: any) => row._id === selectedRow, 
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)', 
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
      },
    },
  ];
  return (
    <div className="m-8 ">
      <div className="flex flex-col relative space-y-2  max-w-sm ">
        <label htmlFor="duration" className="font-semibold">
          Reviewers
        </label>
        <input
          type="text"
          placeholder="Search admins"
          value={searchTerm}
          onClick={() => setShowList(true)}
          onChange={(e) => {
            handleSearch(e.target.value)
          }}
          className="block w-full     rounded border-2 bg-gray-50 p-2 focus:border-blue-500  focus:ring-blue-500  dark:placeholder-gray-400  dark:focus:border-blue-500 dark:focus:ring-blue-500"
        />
      </div>

      {showUser && <Reviewer _id={reviewerId} setShowUser={setShowUser} />}

      {loading ? (
        <Spinner />
      ) : (
        <div className=" my-8  caret-transparent px-8 py-4  rounded-lg  bg-white shadow-sm">
          <DataTable
      columns={columns}
      data={reviewerAdmins}
      pagination
      onRowClicked={handleClick}
      className="text-center" 
      customStyles={{
        headCells: {
          style: {
            fontSize: '16px',
            paddingTop: '4px',
            paddingBottom: '4px',
            backgroundColor:'#ADD8E6'

          },
        },
        rows: {
          style: {
            paddingTop: '4px',
            paddingBottom: '4px',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.05)', 
              cursor: 'pointer',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
            },
          },
        },
      }}
      conditionalRowStyles={conditionalRowStyles} 

    />
        </div>
      )}
    </div>
  );
}
