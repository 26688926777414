import { useState, useEffect, useContext } from "react";
import ViewUser from "../components/ViewUser";
import { fetchProfileImage, getContent, getContentWithPagination } from "../adapters/get";
import { ToastContext } from "../components/Toast/ToastProvider";
import { error } from "../constants";
import { findAllVerifyImgRequestEndPoint, getpartnerImage } from "../adapters/endpoints";
import usePagination from "../components/Pagination/usePagination";
import Spinner from "../components/Spinner";
import { Users } from "react-feather";
import ProfilePicture from "../components/ProfileImages";
import DataTable from "react-data-table-component";


const VerificationRequests = () => {
  const [showUser, setShowUser] = useState<boolean>(false);
  const [userIds, setUserIds] = useState(0);
  const [loading, setLoading] = useState(false);
  const { handleCreateToast } = useContext(ToastContext);

  const [verificationPendingUsers, setVerificationPendingUsers] = useState([]);
  const [verificationCount, setVerificationCount] = useState<any>(0);
  const [selectedRow, setSelectedRow] = useState(null);

    usePagination(verificationPendingUsers);

  function onSuccess(data: any) {
    setVerificationPendingUsers(data.userVerificationRequests);
    setVerificationCount(data.countUserVerificationReq)
    setLoading(false);
  }
  function onFail(message: any) {
    handleCreateToast(message, error);
  }
  
  useEffect(() => {
    getContentWithPagination(findAllVerifyImgRequestEndPoint, onSuccess, onFail,);
    setLoading(true);
  }, []);

  

  const users = verificationPendingUsers.map((item: any, idx: number) => {
    const user = {
      // no: idx + 1,
      _id: item?.userInfo?._id,
      firstName: item?.userInfo?.firstName,
      lastName:item?.userInfo?.lastName,
      phoneNumber:item?.userInfo?.phoneNumber,
      accountStatus:item?.userInfo?.accountStatus,
      lastSeenAt:item?.userInfo?.lastSeenAt.split("T")[0],
      country: item?.userInfo?.address?.country,
      date: item?.createdAt?.split("T")[0],
      imageUrls:<ProfilePicture imageUrl = {item?.imageUrls?.[0]?.imageUrls?.[0]} firstName = { item?.userInfo?.firstName} />,

    };
    return user;
  });
  function handleRowClick(row: any) {
    setSelectedRow(row._id)

    const user:any = verificationPendingUsers.find((item: any) => {
      return item?.userInfo?._id === row._id;
    });

    const userIds: any = {
      _id: row._id,
      verificationRequestId: user._id,
    };
    setUserIds(userIds);
    setShowUser(true);
  }
  const columns = [
    {
      name:'Profile',
      selector: (row: { imageUrls: any }) => row.imageUrls,
      width: '150px',
      cell: (row:any) => <span className="text-sm">{row.imageUrls}</span>,
    },
   
    {
      name: "First Name",
      selector: (row: { firstName: any }) => row.firstName,
      width: '150px',
      cell: (row:any) => <span className="text-sm">{row.firstName}</span>, 
      },
      {
        name: "Last Name",
        selector: (row: { lastName: any }) => row.lastName,
        width: '150px',
        cell: (row:any) => <span className="text-sm">{row.lastName}</span>, 
        },
      {
        name: "Phone Number",
        selector: (row: { phoneNumber: any }) => row.phoneNumber,
        width: '200px',
        cell: (row:any) => <span className="text-sm">{row.phoneNumber}</span>, 
        },
    
    {
      name: "Status",
      selector: (row: { accountStatus: any }) => row.accountStatus,
      width: '150px', 
      cell: (row:any) =>
         <span className={row.accountStatus === 'pending' ? 'text-red' : ''}>{row.accountStatus}
         </span>, 
    },
    {
      name: "Country",
      selector: (row: { country: any }) => row.country,
      width: '150px', 
      cell: (row:any) => <span className="text-sm">{row.country}</span>, 
    },
    {
      name: "Last seen",
      selector: (row: { lastSeenAt: any }) => row.lastSeenAt,
      width: '150px',
      cell: (row:any) => <span className="text-sm">{row.lastSeenAt}</span>, 
    },
    {
      name: "Date",
      selector: (row: { date: any }) => row.date ,
      width: '150px', 
      cell: (row:any) => <span className="text-sm">{row.date}</span>, 
    },
  ];
  const conditionalRowStyles = [
    {
      when: (row: any) => row._id === selectedRow, 
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)', 
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
      },
    },
  ];

  return (
    <div className=" p-8  ">
      {loading ? (
        <Spinner />
      ) : (
        <div className=" w-[100%] bg-white h-screen  caret-transparent px-8 py-4 rounded-lg shadow-sm">
          <div className="flex text-gray-600  shadow my-2 max-w-max px-4 py-2  rounded-md gap-2 items-center font-semibold ">
            <div className="flex gap-2  items-center">
              <Users />
              <p>Pending Users</p>
            </div>
            <span>&nbsp;•&nbsp;</span>
            <p className="text-slate-800 text-2xl">
              {verificationCount}
            </p>
          </div>
          <div className=' overflow-y-auto max-h-[85%] p-4 m-4 rounded-lg bg-white shadow-sm'>
       <DataTable
      columns={columns}
      data={users}
      pagination
      onRowClicked={handleRowClick}
      className="text-center" 
      customStyles={{
        headCells: {
          style: {
            fontSize: '16px',
            paddingTop: '4px',
            paddingBottom: '4px',
            backgroundColor:'#ADD8E6'
          },
        },
        rows: {
          style: {
            paddingTop: '4px',
            paddingBottom: '4px',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.05)', 
              cursor: 'pointer',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
            },
          },
        },
      }}
      conditionalRowStyles={conditionalRowStyles} // Add this line

    />
        </div>
        </div>
      )}

      {showUser && (
        <ViewUser
          setShowUser={setShowUser}
          userIds={userIds}
          setVerificationPendingUsers={setVerificationPendingUsers}
        />
      )}
    </div>
  );
};

export default VerificationRequests;
