import { useState } from "react";
import { X } from "react-feather";
import { LoadingDots } from "./Spinner";

interface ReporterDetailsProps {
    userImages: any;
    onClose: () => void;
  }
  
  const ImagesPopup = ({ userImages, onClose }: ReporterDetailsProps) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const nextImage = () => {
      setCurrentIndex((prevIndex) => {
        if (prevIndex < userImages.length - 1) {
          return prevIndex + 1;
        }
        return prevIndex;
      });
    };
    const previousImage = () => {
      setCurrentIndex((prevIndex) => {
        if (prevIndex > 0) {
          return prevIndex - 1;
        }
        return prevIndex;
      });
    };
    return (
      <div>
        <div className="flex justify-end">
          <X onClick={onClose} className=" cursor-pointer " />
        </div>
        <div className="grid grid-cols-1">
          {userImages?.length > 0 ? (
            <>
              <div className="relative flex flex-col">
                <img
                  src={userImages[currentIndex]}
                  alt={`Slide ${currentIndex}`}
                  className="w-full h-auto max-h-[70vh] rounded-lg shadow-lg"
                />
                <button
                  onClick={nextImage}
                  className="absolute right-4  top-1/2 transform -translate-y-1/2 px-4 py-2 bg-gray-800 text-white rounded-full hover:bg-gray-700 transition"
                  disabled={currentIndex === userImages.length - 1}
                >
                  ⟩
                </button>
                <button
                  onClick={previousImage}
                  className="absolute left-4  top-1/2 transform -translate-y-1/2 px-4 py-2 bg-gray-800 text-white rounded-full hover:bg-gray-700 transition"
                  disabled={currentIndex === 0}
                >
                  ⟨
                </button>
              </div>
            </>
          ) : (
            <LoadingDots />
          )}
        </div>
      </div>
    );
  };

  export default ImagesPopup