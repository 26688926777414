import React, { useContext, useState, useRef, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { signOut } from "../adapters/general";
import { ToastContext } from "./Toast/ToastProvider";
import { LogOut } from "react-feather";
import AlertPopup from "./AlertPopup";
import { logOutAlertText } from "../constants";

interface Route {
  title: string;
  path: string;
  icon: any;
  cName: string;
}

interface Props {
  routes: Route[];
}

const ReviewerSidebar: React.FC<Props> = ({ routes }) => {
  const [showPopup, setShowPopup] = useState<Boolean>(false);
  const { handleCreateToast } = useContext(ToastContext);
  const [isLoading, setIsLoading] = useState(false); // New loading state
  const navigate = useNavigate();
  const [visibleSection, setVisibleSection] = useState<string | null>(null); // State for visible section
  const [selectedRoute, setSelectedRoute] = useState<string | null>(null); // State for selected route

  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleToggle = (section: string) => {
    setVisibleSection(visibleSection === section ? null : section);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      setVisibleSection(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="z-50 min-w-[17%] bg-white overflow-y-auto scrollbar scrollbar-thumb-gray-500 scrollbar-track-gray-200" ref={wrapperRef}>
      <div className="h-[10%] flex items-center gap-2 justify-center">
        <Link to="/" className="">
          Gojo Dating
        </Link>
      </div>
      <div className="flex flex-col justify-between h-[90%]">
        <ul className="px-4 py-4">
          {routes.slice(0).map((route, index) => {
            return (
              <li key={index}>
                <NavLink
                  to={route.path}
                  className={({ isActive }) =>
                    `py-3 px-4 my-4 flex items-center gap-2 text-sm transform transition-transform hover:scale-105 hover:shadow-lg ${
                      isActive
                        ? "bg-lightBlack text-black font-semibold rounded-full"
                        : "text-black"
                    }`
                  }
                  onClick={() => setSelectedRoute(route.path)}
                >
                  {route.icon}
                  <span>{route.title}</span>
                </NavLink>
              </li>
            );
          })}
        </ul>
        <div
          className="py-4 hover:cursor-pointer px-8 my-4 flex items-center gap-2 rounded-md font-medium"
          onClick={() => setShowPopup(true)}
        >
          <LogOut />
          logout
        </div>
        {showPopup && (
          <AlertPopup
            message={logOutAlertText}
            Close={() => setShowPopup(false)}
            onSubmit={() => {
              setIsLoading(true);
              signOut(navigate, handleCreateToast, setIsLoading);
            }}
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  );
};

export default ReviewerSidebar;
