import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { useState } from "react";
import { Bar } from "react-chartjs-2";
import { countUsersBasedOnTheirAge } from "../adapters/endpoints";
import { getContent } from "../adapters/get";
import { ToastContext } from "./Toast/ToastProvider";
import { error, success } from "../constants";
import { useContext,useEffect } from "react";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
        },
    },
    scales: {
        x: {
            grid: {
                display: false,
            },
        },
        y: {
            display: false,
            grid: {
                display: false,
            },
        },
    },
};
interface props{
    ageData:any;
    divideUsersByAge?:{
        count:number;
        _id:{
          age:number;
        }
      }
}
const AgeofUsersChart: React.FC<{}> = () => {
    const [ageData, setAgeData] = useState<any>([])
    const { handleCreateToast } = useContext(ToastContext);
    
    function onSuccessAge(data: any) {
        setAgeData(data);
      }

      function onFail(message: any) {
        handleCreateToast(message, error);
      }

      useEffect(() => {
        getContent(countUsersBasedOnTheirAge, onSuccessAge, onFail);
      }, []);

    const ageCount = ageData?.divideUsersByAge?.map((e: { count: any; }) => e.count);
    const labels= ageData?.divideUsersByAge?.map((e: { _id: any; }) => e._id);
    const ageLabels=labels?.map((e: { age: any; }) => e.age)

    const data={
        labels: ageLabels,
        datasets: [
            {
                label: "",
                data: ageCount,
                backgroundColor: ["#A3CCFF"],
                borderColor: ["#A3CCFF"],
                borderWidth: 1,
                borderRadius: 6,
            },
        ],
    };

    return (
         ageData? (
        <div className="max-w-xl rounded-2xl bg-white p-6 border-2 rounded-lg transform hover:scale-105 transition-transform duration-300 w-[100%] p-8">
        <p className="font-semibold text-lg"> Age of users</p>
        <p className="font-tiny text-gray-500 text-sm mb-6">
            Most age group of users
        </p>
        <Bar data={data} options={options} />   
    </div>
    ):null);
};

export default AgeofUsersChart;
