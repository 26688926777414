import { LineGraphFilterType, MatchingCategory, PlanName, MatchingPartnerAccountStatus, reportActionType, subscriptionDurations, userGender, userRate, verifyActionType } from "../constants";

interface props {
  children: typeof reportActionType | typeof verifyActionType | typeof LineGraphFilterType |
   typeof subscriptionDurations | typeof userRate | typeof PlanName | typeof MatchingPartnerAccountStatus | typeof userGender | typeof MatchingCategory | any[];
  onClick: (e: any) => void;
  width?:string
}

function DropdownList(props: props) {
  return (
    <div className={` absolute   overflow-auto ${props.width ==="auto"? "my-2  w-full": "my-4  w-full"} absolute bg-white border  text-center rounded `}>
      {Object.values(props.children).map((value, index) => {
        return (
          <p
            onMouseDown={e => props.onClick(e)}
            className={`${props.width === "auto"? `w-${props.width}`: "w-full"} text-left px-4 py-2 hover:bg-gray-300 `}
            key={index}
          >
            {value}
          </p>
        );
      })}
    </div>
  );
}

export default DropdownList;
