import { useState, ChangeEvent, useContext, useEffect } from "react";
import { X } from "react-feather";
import Dropdown from "./Dropdown";
import AlertPopup from "./AlertPopup";
import TextArea from "./TextArea";
import Button from "./Button";
import {
  selectActionType,
  noteToAdmin,
  TakeActionAlertText,
  reviewMatchPartnerUsers,
  MatchingPartnerAccountStatus,
} from "../constants";
import { ToastContext } from "../components/Toast/ToastProvider";
import { error, success } from "../constants";
import { createContent } from "../adapters/create";
import {
  findMatchingPartner,
  getReportedUserProfilePicture,
  getUserImageNames,
  getpartnerImage,
  sendMessageToUser,
  takeActionOnMatchingPartner,
} from "../adapters/endpoints";
import { fetchAndDisplayImages, getContent } from "../adapters/get";
import { DetailSpinner, LoadingDots, SideBarSpinner } from "./Spinner";
import { UserNameAvatar } from "./userNameAvatar";
import PostImageZoomPopup from "./PostImageViewer";
import JSONPretty from 'react-json-pretty'
import ImageViewer from "./ImageViewer";
import { InfoCard } from "./ViewReportedUser";
import UserPopup from "./UserPopup";
import ImagesPopup from "./ImagesPopup";
var JSONPrettyMon = require('react-json-pretty/dist/monikai');
interface Props {
  setShowUser: React.Dispatch<React.SetStateAction<boolean>>;
  partnerIds: any;
  setPendingMatchPartners: any;
}
interface UserPopupProps {
  jsonFormat: any;
  onClose: () => void;
}
const ViewPendingMatchPartner: React.FC<Props> = ({
  setShowUser,
  partnerIds,
  setPendingMatchPartners,
}) => {
  const [tab, setTab] = useState(1);
  const [matchPartnerData, setMatchPartnerData] = useState<any>();
  const [isVerifyDataLoading, setisVerifyDataLoading] = useState(false);
  const [userProfilePic, setUserProfilePic] = useState();
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [verificatioStatus, setVerificationStatus] = useState()
  const [partnerImage, setPartnerImage] = useState([]);
  const [OpenZoomPic, setOpenZoomPic] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [jsonFormat, setJsonFormat] = useState<any | null>(null);
  const [userImages, setUserImages] = useState<any>(null);
  const [imageNames, setImageNames] = useState<any>();
  const [selectedUser, setSelectedUser] = useState<any | null>(null);

  function onSucessGettingUsersProfileImgs(data: any) {
    setUserProfilePic(data.userProfilePicture[0]?.image?.data);
    setLoading(false);
  }
  function onSucessGettingPartnerImgs(data: any) {
    setPartnerImage(data);
    setLoading(false);
  }
  function onFail(message: any) {
    setisVerifyDataLoading(false);
    setLoading(false);
  }
  const handleUserClick = (user: any) => {
    setSelectedUser(user);
  };
  const toJsonClick = (user: string) => {
    setJsonFormat(user);
  };
  const closePopup = () => {
    setSelectedUser(null)
    setJsonFormat(null);

  };
  function onSuccessGetVerifyUserData(data: any) {
    setMatchPartnerData(data.findMatchingPartner.matchingPartner[0]);
    setVerificationStatus(data.findMatchingPartner.isAccountVerified)

    setisVerifyDataLoading(false);
  }
  const onSucessGettingUserImageNames = (data: any) => {
    setImageNames(data);
  }
  function onSucessGettingUsersUploadedImgs(data: any) {
    setUserImages(data);
  }
  function userStatus() {
    if (verificatioStatus === true) {

      return (
        <div className="inline-flex items-center">
          <span className="h-4 w-4 rounded-full bg-green-500 mr-1"></span>
        </div>
      );
      
    } else {      
      return null;
    }
  }
  
  useEffect(() => {
    
    if (partnerIds) {      
      const ENDPOINTSNAME = `${getUserImageNames}${partnerIds.userId[0]?._id}`;
      getContent(ENDPOINTSNAME, onSucessGettingUserImageNames, onFail);
    }
  }, []);
  
  useEffect(() => {

    if (
      imageNames &&
      imageNames?.userImageNames &&
      imageNames?.userImageNames.length > 0
    ) {
      
      const ENDPOINTS = imageNames.userImageNames.map(
        (imageId: any) => `${getpartnerImage}${imageId.name}`
      );
      fetchAndDisplayImages(
        ENDPOINTS,
        onSucessGettingUsersUploadedImgs,
        onFail
      );
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [imageNames]);
  useEffect(() => {
    if (partnerIds !== 0) {
      const GET_VERIFY_USER_DATA = `${findMatchingPartner}${partnerIds._id}`;
      getContent(GET_VERIFY_USER_DATA, onSuccessGetVerifyUserData, onFail);

      setLoading(true);
    }
  }, []);
  useEffect(() => {
    if (partnerIds.imageIds && partnerIds.imageIds.length > 0 ) {
      const ENDPOINTS = partnerIds.imageIds.map(
        (imageId: any) => `${getpartnerImage}${imageId}`
      );
      fetchAndDisplayImages(ENDPOINTS, onSucessGettingPartnerImgs, onFail);

      setImageLoading(true);
    }
  }, [partnerIds]);

  useEffect(() => {
    const USER_IMG = `${getReportedUserProfilePicture}${partnerIds.userId}`;
    getContent(USER_IMG, onSucessGettingUsersProfileImgs, onFail);
    setLoading(true);
  }, []);

  let user: any = {};

  useEffect(() => {
    if (matchPartnerData) {
      user = matchPartnerData;
    }
  }, [matchPartnerData]);

  const TakeAction = () => {
    const [showPopup, setShowPopup] = useState<Boolean>(false);
    const [selectedActionType, setSelectedActionType] = useState("");
    // const [reviewDescription, setReviewDescription] = useState('')
    const [noteToAdminManager, setNoteToAdminManager] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { handleCreateToast } = useContext(ToastContext);
    const [accountType, setAccountType] = useState<any>({});
    const [newMessage, setNewMessage] = useState<string>('');

    useEffect(() => {
      const existingAccount = localStorage.getItem("adminManager");
      if (existingAccount) {
        setAccountType(JSON.parse(existingAccount));
      }
    }, []);
    useEffect(() => {
      if (accountType?.isAdminManager === true) {
        setNoteToAdminManager("adminManager");
      }
    }, [accountType?.isAdminManager]);
    const onSuccess = (data: any, response: any) => {
      setPendingMatchPartners((prevUsers: any) => {
        const nextUsers = [...prevUsers]?.filter(
          (user: any) => user._id !== partnerIds._id
        );
        return nextUsers;
      });

      setShowUser(false);
      handleCreateToast(response.message, success);
      setIsLoading(false);
    };
    const onSuccessMessage = (data: any, response: any) => {
      
      setShowUser(false);
      handleCreateToast(response.message, success);
      setIsLoading(false);
    };
    const onFail = (message: string) => {
      handleCreateToast(message, error);
      setIsLoading(false);
    };

    const submitReview = () => {
      setIsLoading(true);
      const data = {
        actionType: selectedActionType,
        matchingPartnerId: partnerIds._id,
      };

      createContent(takeActionOnMatchingPartner, data, onSuccess, onFail);
    };
    const sentMessage = () => {
      const data = {
        message: newMessage,
        userId: partnerIds.userId,
      };

      createContent(sendMessageToUser, data, onSuccessMessage, onFail);
    };
    const statusKeys = Object.keys(MatchingPartnerAccountStatus);

    return (
      <div>
        <div className="flex flex-col justify-between items-center justify-center h-full max-h-[85%] scrollbar-hide  overflow-y-auto ">
          <div className=" items-center  pt-6 justify-center">
            <h2 className=" text-md text-start  mb-2">
              Action Type{" "}
            </h2>
            <Dropdown
              name={selectActionType}
              list={statusKeys}
              onClick={(e: ChangeEvent<HTMLInputElement>) => {
                setSelectedActionType(e.currentTarget.innerText);
              }}
            />
            
            {accountType?.isAdminManager === false && (
              <TextArea
                text={noteToAdmin}
                change={(e: ChangeEvent<HTMLInputElement>) => {
                  setNoteToAdminManager(e.target.value);
                }}
              />
            )}
          </div>
          <div className=" items-center mx-10 justify-center mt-28">
          <Button
            disabled={!selectedActionType || !noteToAdminManager}
            text={reviewMatchPartnerUsers}
            onClick={() => setShowPopup(true)}
          />
          </div>
        
        </div>
        {showPopup ? (
          <AlertPopup
            message={TakeActionAlertText}
            Close={() => {
              setShowPopup(false);
            }}
            onSubmit={submitReview}
            isLoading={isLoading}
          ></AlertPopup>
        ) : null}
      </div>
    );
  };
const PostJsonFormat:React.FC<UserPopupProps> = ({ jsonFormat, onClose }) =>{
  
  return (
    <>
    <div>
    <div className="flex justify-end">
      <X onClick={onClose} className=" cursor-pointer " />
    </div>
     {jsonFormat ? (
        <div className="scrollbar-hide h-[60%]">
          <div className="flex flex-col gap-6 mb-4">
           
            <div className="h-[60vh] overflow-auto">
              <div className="gap-2 p-4 divide-y-2 capitalize my-2">
                {delete jsonFormat.bio}
                <JSONPretty  data={jsonFormat} theme={JSONPrettyMon}></JSONPretty>

              </div>
             
            </div>
          </div>
        </div>
      ) : (
        <div>
          <DetailSpinner />
        </div>
      )}
</div>
  </>
  )
}
  const UserDetail = () => {
    return (
      <div className="relative flex flex-col items-center px-6 h-full">
          {selectedUser && (
        <div className="fixed inset-0 z-50 flex  items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-1/4 mt-10 h-auto my-12 rounded-lg shadow-lg">
            <ImagesPopup userImages={selectedUser} onClose={closePopup} />
          </div>
        </div>
      )}
       { jsonFormat && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">

        <div className="bg-white p-6 rounded-lg shadow-lg">
       <PostJsonFormat jsonFormat={jsonFormat} onClose={closePopup} />
    </div>
    </div>

  )}
          <>
            {matchPartnerData ? (
              <div className="bg-white shadow-lg rounded-lg px-6 absolute flex flex-col h-[95%] mb-10 w-2/5 mx-auto">
                <div className="flex flex-col gap-6 mb-4 ">
                  <div className="flex  gap-6 items-center ">
                  {userImages ? (
                     <button
                     className="z-40 "
                     onClick={() => {
                       handleUserClick(userImages);
                     }}
                   >
                    <img
                      className="object-cover border rounded-full h-16 w-16"
                      src={userImages[0]}
                      alt={user?.firstName}
                    />
                      </button>

                  ) : (
                    <UserNameAvatar
                      userName={matchPartnerData?.firstName}
                      userType={user.USER}
                    />
                  )}
                    <p className="font-bold text-lg">
                      {matchPartnerData?.firstName} {matchPartnerData?.lastName}
                    </p>
                    {userStatus()}
                  </div>
                  {matchPartnerData?.userId[0]?.phoneNumber && (
                    <p
                    >{matchPartnerData?.userId[0]?.phoneNumber}
                    </p>
                  )}
                  
                  <div className=" h-[60vh] overflow-auto">
                  <div>
                    <p className="text-gray-400 text-sm">Bio</p>
                    <p className=" text-gray-600 text-sm">
                      {" "}
                      {matchPartnerData?.bio}
                    </p>
                  </div>
                  <div className="flex flex-col gap-6 mb-4">
            <div className=" h-auto overflow-auto">
              <div className=" capitalize gap-4 p-4 capitalize my-4 ">
                  {matchPartnerData?.category && (
                    <InfoCard
                      value={matchPartnerData?.category}
                      label="Category"
                    />
                  )}
                  {matchPartnerData?.status && (
                    <InfoCard
                      value={matchPartnerData?.status}
                      label="Status"
                    />
                  )}
                  {matchPartnerData?.postType && (
                    <InfoCard
                      value={matchPartnerData?.postType}
                      label="Post Type"
                    />
                  )}
                  {matchPartnerData?.isChattingEnabled && (
                    <InfoCard value={matchPartnerData?.isChattingEnabled ?'True': 'False'} label="Chat Enabled" />
                  )}

                  {matchPartnerData?.firstName && (
                    <InfoCard
                      value={matchPartnerData?.firstName}
                      label="Name"
                    />
                  )}
                  {matchPartnerData?.profession && (
                    <InfoCard
                      value={matchPartnerData?.profession}
                      label="Profession"
                    />
                  )}
                 {matchPartnerData?.religion && (
                    <InfoCard
                      value={matchPartnerData?.religion}
                      label="Region"
                    />
                  )}
                 {matchPartnerData?.maritalStatus && (
                    <InfoCard
                      value={matchPartnerData?.maritalStatus}
                      label="Marital Status"
                    />
                  )}
                
                  {matchPartnerData?.createdAt && (
                    <InfoCard
                      value={matchPartnerData?.createdAt.split("T")[0]}
                      label="Created Date"
                    />
                  )}
                   {matchPartnerData?.updatedAt && (
                    <InfoCard
                      value={matchPartnerData?.updatedAt.split("T")[0]}
                      label="Updated Date"
                    />
                  )}
                </div>
            </div>
          </div>
                   
                  </div>
                </div>
                <div className="flex items-end justify-end mb-4">
            <button 
             onClick={() => {
               toJsonClick(matchPartnerData);
             }}
            className=' z-40 bg-[#2e2e2eed] hover:bg-[#2e2e2e] text-white px-4 rounded py-2'>More</button>
            </div>
              </div>
            ) : (
              <div>
                <DetailSpinner />
              </div>
              
            )}
             
          </>
          {partnerImage && (
        <div className=" absolute flex flex-col px-6 left-2 w-1/4  mx-auto">
        <div className="bg-white shadow-lg  rounded-lg">
        <p className="font-semibold text-center">Posted Images</p>
         <div className="grid grid-row-2">
          <div className={`grid grid-cols-2 grid-rows-${(partnerImage.length -1)/2} gap-4 mt-2`}>
              {partnerImage.map((img:any, index:number) => (
                <div key={index} className="border border-gray-200 rounded-md h-32">
                  <img
                    className="object-cover w-full h-full rounded-md cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setOpenZoomPic(true);
                      setSelectedImage(img);
                    }}
                    src={img}
                    alt={`Imag ${index + 1}`}
                  />
                </div>
              ))}
            
            </div>
          
            {OpenZoomPic && (
              <PostImageZoomPopup
                imageUrl={selectedImage}
                onClose={() => setOpenZoomPic(false)}
              />
            )}
          </div>
          </div>
        </div>
      )}
       <div className=" absolute flex flex-col h-[95%] px-6 right-4 w-1/5  mx-auto">
        <div  className="bg-white h-screen  shadow-lg rounded-lg">
        <TakeAction />
        </div>
        </div>
      </div>
    );
  };
  
  

  return (
    <div className="fixed inset-0 z-40 flex overflow items-end justify-end bg-gray-900 bg-opacity-50 ">
      <div className="p-8 absolute top-0 right-0 h-full w-full md:w-[85%] bg-gray-50 rounded-l-md z-50">
        <div className="flex justify-end">
          <X onClick={() => setShowUser(false)} className=" cursor-pointer " />
        </div>
        <div className=" h-full space-y-8 ">
          <UserDetail />
        </div>
      </div>
    </div>
  );
};

export default ViewPendingMatchPartner;
