import { useState, useEffect, useContext } from "react";
import ViewUser from "../components/ViewUser";
import { getContent, getContentWithPagination } from "../adapters/get";
import { ToastContext } from "../components/Toast/ToastProvider";
import { error } from "../constants";
import { findAllPendingMatchPartners, listReviewVerifiedUsers } from "../adapters/endpoints";
import Pagination from "../components/Pagination/Pagination";
import usePagination from "../components/Pagination/usePagination";
import Spinner from "../components/Spinner";
import { Users } from "react-feather";
import { ReusableTable } from "../components/ReusableTable";
import ViewPendingMatchPartner from "../components/ViewPendingMatchPartner";
import DataTable from "react-data-table-component";

const ReviewPendingMatchPartnerUsers = () => {
  const [showUser, setShowUser] = useState<boolean>(false);
  const [userIds, setUserIds] = useState(0);
  const [loading, setLoading] = useState(false);
  const { handleCreateToast } = useContext(ToastContext);

  const [matchPartnerUsers, setMatchPartnerUsers] = useState([]);
  const [marriageCount, setMarriageCount] = useState<any>(0);
  const [selectedRow, setSelectedRow] = useState(null);

  const [currentPage, setCurrentPage, itemsPerPage] =
    usePagination(matchPartnerUsers);

  function onSuccess(data: any) {
    setMatchPartnerUsers(data.findAllPendingMatchPartner);
    setMarriageCount(data.countAllPendingMatchPartners)
    setLoading(false);
  }
  function onFail(message: any) {
    handleCreateToast(message, error);
  }

  useEffect(() => {
    getContentWithPagination(findAllPendingMatchPartners, onSuccess, onFail,currentPage);
    setLoading(true);
  }, [currentPage]);

  function handleRowClick(row: any) {
    setSelectedRow(row._id)
    const user:any = matchPartnerUsers.find((item: any) => {
      return item?._id === row._id;
    });
    const userIds: any = {
      _id: user._id,
      userId: user.userId,
      imageIds: user.imageIds
    };
    setUserIds(userIds);    
    setShowUser(true);
  }

  const users = matchPartnerUsers.map((item: any, idx: number) => {
    const user = {
      // no: idx + 1,
      _id: item?._id,
      firstName: item?.firstName,
      lastName: item?.lastName,
      createdAt: item?.createdAt.split("T")[0],
      category: item?.category,
      phoneNumber: item?.userId[0]?.phoneNumber,
      address:item?.userId[0]?.address?.country
    };
    return user;
  });

  const columns = [
    {
      name:'First Name',
      selector: (row: { firstName: any }) => row.firstName,
      width: '200px',
      cell: (row:any) => <span className="text-sm">{row.firstName}</span>,
    },
    {
      name:'Last Name',
      selector: (row: { lastName: any }) => row.lastName,
      width: '200px',
      cell: (row:any) => <span className="text-sm">{row.lastName}</span>,
    },
    {
      name:'Category',
      selector: (row: { category: any }) => row.category,
      width: '200px',
      cell: (row:any) => <span className="text-sm">{row.category}</span>,
    },
    {
      name:'Phone Number',
      selector: (row: { phoneNumber: any }) => row.phoneNumber,
      width: '200px',
      cell: (row:any) => <span className="text-sm">{row.phoneNumber}</span>,
    },
    {
      name:'Country',
      selector: (row: { address: any }) => row.address,
      width: '200px',
      cell: (row:any) => <span className="text-sm">{row.address}</span>,
    },
    {
      name:'Date',
      selector: (row: { createdAt: any }) => row.createdAt,
      width: '200px',
      cell: (row:any) => <span className="text-sm">{row.createdAt}</span>,
    },
  ];
  const conditionalRowStyles = [
    {
      when: (row: any) => row._id === selectedRow, 
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)', 
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
      },
    },
  ];
  return (
    <div className=" p-8  ">
      {loading ? (
        <Spinner />
      ) : (
        <div className=" max-w-full caret-transparent px-8 py-4    rounded-lg  bg-white shadow-sm">
          <div className="flex text-gray-600  shadow my-2 max-w-max px-4 py-2  rounded-md gap-2 items-center font-semibold ">
            <div className="flex gap-2  items-center">
              <Users />
              <p>Pending Posts</p>
            </div>
            <span>&nbsp;•&nbsp;</span>
            <p className="text-slate-800 text-2xl">
              {marriageCount}
            </p>
          </div>
          <div className="overflow-y-auto scrollbar max-h-[85%] p-4 m-4 rounded-lg bg-white shadow-sm">

          <DataTable
              columns={columns}
              data={users}
              pagination
              onRowClicked={handleRowClick}
              className="text-center"
              customStyles={{
                headCells: {
                  style: {
                    fontSize: '16px',
                    paddingTop: '4px',
                    paddingBottom: '4px',
                  },
                },
                rows: {
                  style: {
                    paddingTop: '4px',
                    paddingBottom: '4px',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.05)',
                      cursor: 'pointer',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    },
                  },
                },
              }}
              conditionalRowStyles={conditionalRowStyles}
            />
                  </div>

        </div>
      )}
  {showUser && (
        <ViewPendingMatchPartner
          setShowUser={setShowUser}
          partnerIds={userIds}
          setPendingMatchPartners={setMatchPartnerUsers}
        />
      )}
    </div>
  );
};

export default ReviewPendingMatchPartnerUsers;
