import React, { useState, useContext, useEffect } from 'react'
import { X } from 'react-feather'
import AlertPopup from './AlertPopup'
import Button from './Button'
import Dropdown from './Dropdown'
import TextArea from './TextArea'
import {
  selectActionType,
  reportActionType,
  noteToAdmin,
  giveWarningToUser,
  UserType,
  monthNames,
  TakeActionAlertText
} from '../constants'
import { ChangeEvent } from 'react'
import { ToastContext } from '../components/Toast/ToastProvider'
import { error, success } from '../constants'
import { createContent } from '../adapters/create'
import {
  findReportedChatEndPoint,
  getReportedUserProfilePicture,
  takeActionOnReportedChatEndPoint,
  findMessagesOfReportedChat,
  getpartnerImage
} from '../adapters/endpoints'
import { fetchAndDisplayImages, getContent } from '../adapters/get'
import { InfoCard } from './ViewReportedUser'
import { UserNameAvatar, UserNameAvatarForChat } from './userNameAvatar'
import { DetailSpinner, LoadingDots, SideBarSpinner } from './Spinner'
import PostImageZoomPopup from './PostImageViewer'
import JSONPretty from 'react-json-pretty'
import ImagesPopup from './ImagesPopup'
import UserPopup from './UserPopup'
var JSONPrettyMon = require('react-json-pretty/dist/monikai');

interface data {
  img: string
  uploadedImgs: Array<string>
  description: string
  responsibleId: {
    lastSeenAt: any
    rating: any
    phoneNumber: any
    accountStatus:string
    firstName: string
    lastName: string
    bio: string
    age: string
    gender: string
    height: {ft:string, m:string}
    location: string
    address: {
      region: any
      country: string
    }
    jobTitle: string
  }
  reporterId: {
    accountStatus:string
    firstName: string
    lastName: string
    bio: string
    age: string
    gender: string
    height: {ft:string, m:string}
    location: string
    address: {
      country: string
    }
    jobTitle: string
  }
  report?: {
    type: string
    complaint: string
    reportedByUserId: string
    userId: string
    dateOfReporting: string
  }
}
interface Props {
  setShowUser: React.Dispatch<React.SetStateAction<boolean>>
  reportId: number
  setReports: any
}

const ViewReportedChatsUser: React.FC<Props> = ({
  setShowUser,
  reportId,
  setReports
}) => {
  const [tab, setTab] = useState(1)
  const [chatReportData, setChatReportData] = useState<any>([])
  const { handleCreateToast } = useContext(ToastContext)
  const [toAction, setToAction] = useState<any>(false);
  const handelPopup = ()=>{
   setToAction(true)
  }
  const closePopup = () => {
   setToAction(false);

 };
  function onSuccessGetReportedChat (data: any) {
    setChatReportData(data.reportedChat)
  }

  function onFail (message: any) {
    handleCreateToast(message, error)
  }

  useEffect(() => {
    if (reportId !== 0) {
      const GET_REPORTED_CHAT = `${findReportedChatEndPoint}${reportId}`
      getContent(GET_REPORTED_CHAT, onSuccessGetReportedChat, onFail)
    }
  }, [reportId])

  const UserDetail = () => {
    const [reporterImg, setReporterImg] = useState<any>(null)
    const [OpenZoomPic, setOpenZoomPic] = useState(false)
    const [selectedImage, setSelectedImage] = useState('')
    const [loading, setLoading] = useState(false)
    const [jsonFormat, setJsonFormat] = useState<string | null>(null);
    const [selectedUser, setSelectedUser] = useState<any | null>(null);
  
    const handleUserClick = (user: any) => {
      setSelectedUser(user);
    };
    const toJsonClick = (user: string) => {
      setJsonFormat(user);
    };
    const closePopup = () => {
      setSelectedUser(null);
      setJsonFormat(null);
  
    };
    function onSucessGettingUsersUploadedImgs (data: any) {
      setReporterImg(data)
      setLoading(false)
    }
    
    useEffect(() => {
      if (Array.isArray(chatReportData) && chatReportData?.length > 0) {
        const REPORTER_IMAGE =  chatReportData[0]?.reporterId[0]?.images.map(
          (imageId: any) => `${getpartnerImage}${imageId.name}`
        );
        fetchAndDisplayImages(REPORTER_IMAGE, onSucessGettingUsersUploadedImgs, onFail)
      }
      setLoading(true)
    }, [chatReportData])

    return (
      <div
      >
         {selectedUser && (
        <div className="fixed inset-0 z-50 flex  items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-1/4 mt-10 h-auto my-12 rounded-lg shadow-lg">
            <ImagesPopup userImages={selectedUser} onClose={closePopup} />
          </div>
        </div>
      )}
      { jsonFormat && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">

        <div className="bg-white p-6 rounded-lg shadow-lg">
       <UserPopup user={jsonFormat} onClose={closePopup} />
    </div>
    </div>

  )}
          <>
          {chatReportData ? (
          <div>
                <div  className="flex w-30 space-x-4 items-center">
                    {reporterImg ? (
                       <button
                       className="z-40 "
                       onClick={() => {
                         handleUserClick(reporterImg);
                       }}
                     >
                       <img
                         className="object-cover border rounded-full h-16 w-16"
                         src={reporterImg[0]}
                         alt={chatReportData[0]?.reporterId[0]?.firstName}
                         />
                     </button>
                    ) : (
                      <UserNameAvatar
                      userName={chatReportData[0]?.reporterId[0]?.firstName}
                      userType={UserType.SENDER}
                    />
                    )}
                  <div className="space-y-2">
                        <div>
                          <div className='space-y-2'>
                            <p className='font-bold text-gray-800 text-2xl'>
                              {chatReportData[0]?.reporterId[0]?.firstName} {chatReportData[0]?.reporterId[0]?.firstName ?? ""}
                            </p>
                            {chatReportData[0]?.reporterId[0]?.phoneNumber ? (
                              <p>{chatReportData[0]?.reporterId[0]?.phoneNumber}
                              </p>
                            ) : null}
                          </div>
                        </div>
                  </div>
                </div>
             {/* return ( */}
             <div className="flex flex-col gap-6 mb-4">
              <div className=" h-auto overflow-auto">
                <div className=" gap-2 p-4 divide-y-2 capitalize my-2">
                  {chatReportData[0]?.reporterId[0]?.accountStatus && (
                    <InfoCard
                      value={chatReportData[0]?.reporterId[0]?.accountStatus}
                      label="Account Status"
                    />
                  )}
                  {chatReportData[0]?.reporterId[0]?.rating && (
                    <InfoCard
                      value={chatReportData[0]?.reporterId[0]?.rating}
                      label="Rate"
                    />
                  )}
                  {chatReportData[0]?.reporterId[0]?.gender && (
                    <InfoCard
                      value={chatReportData[0]?.reporterId[0]?.gender}
                      label="Gender"
                    />
                  )}
                  {chatReportData[0]?.reporterId[0]?.age && (
                    <InfoCard value={chatReportData[0]?.reporterId[0]?.age} label="Age" />
                  )}

                  {chatReportData[0]?.reporterId[0]?.address?.country && (
                    <InfoCard
                      value={chatReportData[0]?.reporterId[0]?.address?.country}
                      label="Country"
                    />
                  )}
                  {chatReportData[0]?.reporterId[0]?.address?.region && (
                    <InfoCard
                      value={chatReportData[0]?.reporterId[0]?.address?.region}
                      label="Region"
                    />
                  )}
                  {chatReportData[0]?.reporterId[0]?.lastSeenAt && (
                    <InfoCard
                      value={chatReportData[0]?.reporterId[0]?.lastSeenAt.split("T")[0]}
                      label="Last Seen"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="flex items-end justify-end mb-4">
            <button 
             onClick={() => {
               toJsonClick(chatReportData[0]?.reporterId[0]?._id);
             }}
            className=' z-40 bg-[#2e2e2eed] hover:bg-[#2e2e2e] text-white px-4 rounded py-2'>More</button>
            </div>
          </div>
        ) : (
          <div className='flex flex-col gap-6 top-1/2 sm:top-[44%] '>
            <DetailSpinner />
            </div>
        )}
          </>        
      </div>
    )
  }
  const ChatUI = ({
    report,
    messages,
    senderId,
    senderProfilePic,
    receiverProfilePic
  }: any) => {
    const ImgCircle = ({ userImg, userName }: any) => {
      return (
        <div className='h-10 w-10 overflow-hidden rounded-full'>
          <img
            className='object-cover h-full w-full'
            src={`data:image/jpeg;base64,${userImg}`}
            alt={userName}
          />
        </div>
      )
    }
    
    return (
      <div className='flex flex-col h-screen'>
        <div className='h-[60vh] overflow-auto '>
        <div className='flex-grow h-0'>
          {messages?.map((message: any) => {
            const dateString = message?.createdAt
            const date = new Date(dateString)
            const time = date.toLocaleTimeString()

            const repliedMessage = messages.find(
              (msg: any) => msg._id === message.repliedToMessageId
            )

            const isSender = message.senderId === senderId
            const messageClassName = `px-4 py-2 rounded-2xl bg-gray-200 ${
              isSender ? "rounded-br-none bg-blue-200" : "rounded-bl-none bg-gray-200"
            }`

            return (
              <div
                key={message._id}
                id={message._id}
                className={`flex gap-2 my-2 ${
                  isSender ? 'flex' : 'flex-row-reverse'
                }`}
              >
                <div className={`flex items-start `}>
                  {isSender ? (
                    <>
                      {senderProfilePic ? (
                        <ImgCircle
                          userImg={senderProfilePic}
                          userName={report?.reporterId[0]?.firstName}
                        />
                      ) : (
                        <UserNameAvatarForChat
                          userType={UserType.SENDER}
                          userName={report?.reporterId[0]?.firstName}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {receiverProfilePic ? (
                        <ImgCircle
                          userImg={receiverProfilePic}
                          userName={report?.responsibleId[0]?.firstName}
                        />
                      ) : (
                        <UserNameAvatarForChat
                          userType={UserType.RECIEVER}
                          userName={report?.responsibleId[0]?.firstName}
                        />
                      )}
                    </>
                  )}
                </div>
                <div className='flex flex-col mt-6'>
                  {message.repliedToMessageId && repliedMessage ? (
                    <div
                      className='bg-gray-100 rounded-lg p-2 mb-2 cursor-pointer'
                      style={{ borderLeft: '4px solid #e0e0e0' }}
                      onClick={() => {
                        const originalMessageElement = document.getElementById(
                          repliedMessage?._id
                        )
                        if (originalMessageElement) {
                          originalMessageElement.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start'
                          })
                        }
                      }}
                    >
                      <p className='text-sm font-semibold text-gray-600 '>
                        {repliedMessage.messageBody.slice(0, 50)}...
                      </p>
                    </div>
                  ) : null}
                  <div className={messageClassName}>
                    <p className='font-semibold'>{message.messageBody}</p>
                  </div>
                  <div className='flex justify-start'>
                    <p
                      className={`text-xs text-gray-400 mt-2 font-small ${
                        isSender ? 'mr-auto' : 'ml-auto'
                      }`}
                    >
                      {time}
                    </p>
                    <div className='flex justify-start'>
                      {message.reactionFromSender && (
                        <div
                          className={`h-8 w-8 bg-white border-2 border-gray-300 ${
                            isSender ? 'ml-0' : 'mr-0'
                          } rounded-full flex items-center justify-center`}
                        >
                          <span role='img' aria-label='emoji'>
                            {message.reactionFromSender}
                          </span>
                        </div>
                      )}
                      {message.reactionFromReceiver && (
                        <div
                          className={`h-10 w-10 bg-white-300 border-2 border-gray-300 ${
                            isSender ? 'mr-0' : 'ml-0'
                          } rounded-full flex items-center justify-center`}
                        >
                          <span role='img' aria-label='emoji'>
                            {message.reactionFromReceiver}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        </div>
      </div>
    )
  }

  const ReportedChat = ({ chatReportData }: any) => {
    const [message, setMesssage] = useState([])
    const [senderId, setSenderId] = useState()
    const [chats, setChats] = useState<any>()
    const [receiverId, setReceiverId] = useState()

    let chatId: any

    const monthIndex = new Date(chats?.updatedAt)?.getMonth()
    const monthName = monthNames[monthIndex]
    const day = new Date(chats?.updatedAt).getDate()

    const onSucess = (data: any) => {
      setChats(data.messagesOfReportedChat[0])
      setSenderId(data.messagesOfReportedChat[0]?.senderId)
      setReceiverId(data.messagesOfReportedChat[0]?.receiverId)
      setMesssage(data.messagesOfReportedChat[0]?.messageIds)
    }

    const onFail = (message: any) => {
      handleCreateToast(message, error)
    }

    useEffect(() => {
      if (chatReportData) {
        chatId = chatReportData[0]?.responsibleChatId

        if (chatId?.length > 0) {
          const FIND_MESSAGES_OF_REPORTED_CHAT = `${findMessagesOfReportedChat}${chatId}`
          getContent(FIND_MESSAGES_OF_REPORTED_CHAT, onSucess, onFail)
        }
      }
    }, [chatId, chatReportData])

    const [senderProfilePic, setSenderProfilePic] = useState('')
    const [receiverProfilePic, setReceiverProfilePic] = useState('')

    function onSucessGettingSenderImg (data: any) {
      setSenderProfilePic(data?.userProfilePicture[0]?.image?.data)
    }

    function onSucessGettingReceiverImg (data: any) {
      setReceiverProfilePic(data?.userProfilePicture[0]?.image?.data)
    }

    useEffect(() => {
      if (chats) {
        const findSenderImg = `${getReportedUserProfilePicture}${senderId}`
        const findReceiverImg = `${getReportedUserProfilePicture}${receiverId}`

        getContent(findSenderImg, onSucessGettingSenderImg, onFail)
        getContent(findReceiverImg, onSucessGettingReceiverImg, onFail)
      }
    }, [chats, receiverId, senderId])

    return (
      <div
      >
        <div className='flex'>
          <div className=' absolute rounded-full bg-gray-200 text-gray-400  ml-12 max-w-max'>
            {monthName && monthName} {day && day}
          </div>
        </div>
        <ChatUI
          messages={message}
          senderId={senderId}
          senderProfilePic={senderProfilePic}
          receiverProfilePic={receiverProfilePic}
        />
      </div>
    )
  }

  const ReportedUserDetail = ({ chatReportData }: any) => {
    const [responsibleImg, setResponsibleImg] = useState<any>(null)
    const [OpenZoomPic, setOpenZoomPic] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectedImage, setSelectedImage] = useState('')
    const [jsonFormat, setJsonFormat] = useState<string | null>(null);
    const [selectedUser, setSelectedUser] = useState<any | null>(null);
  
    const handleUserClick = (user: any) => {
      setSelectedUser(user);
    };
    const toJsonClick = (user: string) => {
      setJsonFormat(user);
    };
    const closePopup = () => {
      setSelectedUser(null);
      setJsonFormat(null);
  
    };
    function onSucessGettingUsersUploadedImgs (data: any) {
      setResponsibleImg(data)
      setLoading(false)
    }

    useEffect(() => {
      if (Array.isArray(chatReportData) && chatReportData?.length > 0) {
       
        const RESPONSIBE_IMAGE =  chatReportData[0]?.responsibleId[0]?.images.map(
          (imageId: any) => `${getpartnerImage}${imageId.name}`
        );
        fetchAndDisplayImages(RESPONSIBE_IMAGE, onSucessGettingUsersUploadedImgs, onFail)
      }
      setLoading(true)
    }, [chatReportData])
    return (
      <div
      >
         {selectedUser && (
        <div className="fixed inset-0 z-50 flex  items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-1/4 mt-10 h-auto my-12 rounded-lg shadow-lg">
            <ImagesPopup userImages={selectedUser} onClose={closePopup} />
          </div>
        </div>
      )}
      { jsonFormat && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">

        <div className="bg-white p-6 rounded-lg shadow-lg">
       <UserPopup user={jsonFormat} onClose={closePopup} />
    </div>
    </div>
  )}
          <>
          {chatReportData ? (
          <div>
                <div  className="flex w-30 space-x-4 items-center">
                    {responsibleImg ? (
                       <button
                       className="z-40 "
                       onClick={() => {
                         handleUserClick(responsibleImg);
                       }}
                     >
                       <img
                         className="object-cover border rounded-full h-16 w-16"
                         src={responsibleImg[0]}
                         alt={chatReportData[0]?.responsibleId[0]?.firstName}
                         />
                     </button>
                    ) : (
                      <UserNameAvatar
                      userName={chatReportData[0]?.responsibleId[0]?.firstName}
                      userType={UserType.SENDER}
                    />
                    )}
                  <div className="space-y-2">
                        <div>
                          <div className='space-y-2'>
                            <p className='font-bold text-gray-800 text-2xl'>
                              {chatReportData[0]?.responsibleId[0]?.firstName} {chatReportData[0]?.reporterId[0]?.firstName ?? ""}
                            </p>
                            {chatReportData[0]?.responsibleId[0]?.phoneNumber ? (
                              <p>{chatReportData[0]?.responsibleId[0]?.phoneNumber}
                              </p>
                            ) : null}
                          </div>
                        </div>
                  </div>
                </div>
             {/* return ( */}
             <div className="flex flex-col gap-6 mb-4">
              <div className=" h-auto overflow-auto">
                <div className=" gap-2 p-4 divide-y-2 capitalize my-2">
                  {chatReportData[0]?.responsibleId[0]?.accountStatus && (
                    <InfoCard
                      value={chatReportData[0]?.responsibleId[0]?.accountStatus}
                      label="Account Status"
                    />
                  )}
                  {chatReportData[0]?.responsibleId[0]?.rating && (
                    <InfoCard
                      value={chatReportData[0]?.responsibleId[0]?.rating}
                      label="Rate"
                    />
                  )}
                  {chatReportData[0]?.responsibleId[0]?.gender && (
                    <InfoCard
                      value={chatReportData[0]?.responsibleId[0]?.gender}
                      label="Gender"
                    />
                  )}
                  {chatReportData[0]?.responsibleId[0]?.age && (
                    <InfoCard value={chatReportData[0]?.responsibleId[0]?.age} label="Age" />
                  )}

                  {chatReportData[0]?.responsibleId[0]?.address?.country && (
                    <InfoCard
                      value={chatReportData[0]?.responsibleId[0]?.address?.country}
                      label="Country"
                    />
                  )}
                  {chatReportData[0]?.responsibleId[0]?.address?.region && (
                    <InfoCard
                      value={chatReportData[0]?.responsibleId[0]?.address?.region}
                      label="Region"
                    />
                  )}
                  {chatReportData[0]?.responsibleId[0]?.lastSeenAt && (
                    <InfoCard
                      value={chatReportData[0]?.responsibleId[0]?.lastSeenAt.split("T")[0]}
                      label="Last Seen"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="flex items-end justify-end mb-4">
            <button 
             onClick={() => {
               toJsonClick(chatReportData[0]?.responsibleId[0]?._id);
             }}
            className=' z-40 bg-[#2e2e2eed] hover:bg-[#2e2e2e] text-white px-4 rounded py-2'>More</button>
            </div>
          </div>
        ) : (
          <div className='flex flex-col gap-6 top-1/2 sm:top-[44%] '>
            <DetailSpinner />
            </div>
        )}
          </>        
      </div>
    )
  }
  interface PopupProps {
    onClose: () => void;
  }
  const TakeAction:React.FC<PopupProps> = ({onClose}) => {
    const [showPopup, setShowPopup] = useState<Boolean>(false)
    const [selectedActionType, setSelectedActionType] = useState('')
    // const [reviewDescription, setReviewDescription] = useState('')
    const [noteToAdminManager, setNoteToAdminManager] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const { handleCreateToast } = useContext(ToastContext)
    const [accountType,setAccountType] = useState<any>({})

    useEffect(() => {
  
      const existingAccount =localStorage.getItem('adminManager');
      if(existingAccount) {
        setAccountType(JSON.parse(existingAccount));
      }
    }, [])
    useEffect(() => {
      if(accountType?.isAdminManager === true){
        setNoteToAdminManager('adminManager')        
      }
    }, [accountType?.isAdminManager])
    const onSuccess = (data: any, response: any) => {
      setReports((prevReports: any) => {
        const nextChats = [...prevReports]?.filter(
          (chat: any) => chat._id !== reportId
        )
        return nextChats
      })
      setShowUser(false)

      handleCreateToast(response.message, success)
      setIsLoading(false)
    }

    const onFail = (message: string) => {
      handleCreateToast(message, error)
      setIsLoading(false)
    }

    const submitReview = () => {
      setIsLoading(true)
      const data = {
        // reviewerReasonForTakingAction: reviewDescription,
        actionType: selectedActionType,
        report_id: reportId,
        noteToAdminManager: noteToAdminManager
      }

      createContent(takeActionOnReportedChatEndPoint, data, onSuccess, onFail)
    }

    return (
      <div className=' w-full'>
         <div className="flex justify-end">
          <X onClick={onClose} className=" cursor-pointer " />
        </div>
          <div className="flex flex-col justify-between items-center justify-center h-full max-h-[85%] scrollbar-hide  overflow-y-auto ">
            <div className=' items-center justify-center my-5'>
                     <InfoCard
                      value={chatReportData[0]?.violationType}
                      label="Violation Type"
                    />
                     <InfoCard
                      value={chatReportData[0]?.createdAt.split("T")[0]}
                      label="Date"
                    />
              
            </div>
            <div className=" items-center w-40  pt-6 justify-center">
              <h2 className=" text-sm text-start mb-2">
                Action Type{" "}
              </h2>
              <Dropdown
                name={selectActionType}
                list={reportActionType}
                onClick={(e: ChangeEvent<HTMLInputElement>) => {
                  setSelectedActionType(e.currentTarget.innerText);
                }}
              />
              {accountType?.isAdminManager === false && (
                <TextArea
                  text={noteToAdmin}
                  change={(e: ChangeEvent<HTMLInputElement>) => {
                    setNoteToAdminManager(e.target.value);
                  }}
                />
              )}
            </div>
            <div className=" items-center mx-10 justify-center mt-28">
            <Button
              disabled={!selectedActionType || !noteToAdminManager}
              text={giveWarningToUser}
              onClick={() => setShowPopup(true)}
            />
            </div>
          
          </div>
          {showPopup ? (
            <AlertPopup
              message={TakeActionAlertText}
              Close={() => {
                setShowPopup(false);
              }}
              onSubmit={submitReview}
              isLoading={isLoading}
            ></AlertPopup>
          ) : null}
        </div>
    )
  }
  const ReportDetails = () =>{
    
    return (
      <div className="relative flex flex-row h-auto">
         { toAction && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">

        <div className="bg-white p-6 w-[30%] rounded-lg shadow-lg">
       <TakeAction onClose={closePopup} />
    </div>
    </div>
  )}
  <div className="w-2/6 p-4">
    <div className="bg-white shadow-lg rounded-lg p-6">
      <p className="text-center text-blue-500">Reporter</p>
      <UserDetail />
      </div>
  </div>

  <div className="w-2/6 p-4">
    <div className="bg-white shadow-lg rounded-lg p-6">
      <p className="text-center text-blue-500">Responsible</p>
      <ReportedUserDetail chatReportData={chatReportData} />
      </div>
  </div>
  <div className="w-2/6 p-4">
    <div className="bg-white h-full shadow-lg rounded-lg p-6">
    <p className="text-center text-blue-500 pt-2">
    Chats
</p>   
 <ReportedChat chatReportData={chatReportData} />

    </div>
  </div>
      </div>
    )
  }
  return (
    <div className="fixed inset-0 z-40 flex overflow items-end justify-end bg-gray-900 bg-opacity-50 ">
    <div className="p-8 absolute top-0 right-0 h-full w-full md:w-[85%] bg-gray-50 rounded-l-md z-50">
    <div className="flex justify-end items-center space-x-4">
  <button 
    onClick={() => { handelPopup() }} 
    className="px-2 bg-gray-800 text-white rounded-full hover:bg-gray-700 transition">
    Take Action
  </button>
  <X onClick={() => setShowUser(false)} className="cursor-pointer" />
</div>
      <div className=" h-full space-y-8 ">
        <ReportDetails />
      </div>
    </div>
  </div>
  )
}

export default ViewReportedChatsUser
