import React, { useContext, useEffect, useState } from "react";
import { CheckCircle, Edit, PlusSquare, Trash2 } from "react-feather";
import ReusableForm from "./ReusableForm";
import { planProps } from "./Settings";
import ConfirmDeleteDialog from "../ConfirmDeleteDialog";
import { DeactivateAlertText, error, NoPlansCurrentAdded, success } from "../../constants";
import { NoContentFound } from "../../util";
import {
  createSubscriptionPlansEndPoint,
  deactivateSubscriptionPlanEndPoint,
  findAllSubscriptionPlansEndPoint,
  updateSubscriptionPlanEndPoint,
  getTotalSubscribedAmount
} from "../../adapters/endpoints";
import { ToastContext } from "../Toast/ToastProvider";
import { getContent } from "../../adapters/get";
import { createContent } from "../../adapters/create";
import { updateContent } from "../../adapters/update";

interface subsCardProps {
  plan: planProps;
  setPlans: React.Dispatch<React.SetStateAction<planProps[]>>;
}

const SubscriptionCard = ({ plan, setPlans }: subsCardProps) => {
  const { handleCreateToast } = useContext(ToastContext);

  const [showEditPlanComponent, setShowEditPlanComponent] = useState(false);
  const [deletePlan, setDeletePlan] = useState(false);

  const onSuccess = (response: any) => {
    setPlans((prevPlans) => {
      const newPlans = prevPlans.filter((p) => p._id !== plan._id);
      return newPlans;
    });
    setDeletePlan(false);
    handleCreateToast(response.message, success);
  };

  const onFail = (message: string) => handleCreateToast(message, error);

  const editPlanAndUpdatePlans = (currPlan: planProps, response: any) => {
    setPlans((prevPlans) => {
      const planIndex = prevPlans.findIndex(
        (plan, _) => plan._id === currPlan._id
      );
      prevPlans[planIndex] = currPlan;
      return [...prevPlans];
    });
    setShowEditPlanComponent(false);

    handleCreateToast(response.message, success);
  };

  function apiCallingFucntion(data: planProps) {
    updateContent(
      `${updateSubscriptionPlanEndPoint}/${plan._id}`,
      data,
      editPlanAndUpdatePlans,
      onFail
    );
  }

  return (
    <>
      <div className="flex flex-col justify-between  max-w-sm rounded-md  customshadow p-8 bg-white ">
        <div className=" space-y-4 ">
          <h2 className="font-bold text-2xl break-words">{plan.planName}</h2>
          <p className="text-gray-600 font-medium break-words ">
            {plan.description}
          </p>
          <div>
            {plan.subscriptionOptions.map(
              ({ price, duration }, key: number) => {
                return (
                  <div key={key} className="flex text-[#2e2e2e] gap-4 my-2">
                    <p className="font-black text-xl ">{price}$</p>
                    <p className="font-bold text-lg text-gray-800  ">
                      {duration}
                    </p>
                  </div>
                );
              }
            )}
          </div>
          <div>
            {plan?.benefits?.map((benefit, idx) => {
              return (
                <div key={idx} className="flex gap-2 my-2 items-top ">
                  <CheckCircle className="text-[#27C98F] mt-1 min-w-[1.5rem] min-h-[1.5rem]" />
                  <p className="text-gray-600 font-medium break-all">
                    {benefit}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex justify-between gap-4 pt-6 ">
          <div
            onClick={() => setShowEditPlanComponent(true)}
            className="hover:cursor-pointer hover:bg-gray-100    p-2 border-2 rounded"
          >
            <Edit className="w-5 h-5" />
          </div>
          <div
            onClick={() => {
              setDeletePlan(true);
            }}
            className="hover:cursor-pointer hover:bg-gray-100   p-2 border-2 rounded"
          >
            <Trash2 className="w-5 h-5" />
          </div>
        </div>
      </div>
      {deletePlan && (
        <ConfirmDeleteDialog
          ENDPOINT={`${deactivateSubscriptionPlanEndPoint}/${plan._id}`}
          onSuccess={onSuccess}
          onFail={onFail}
          setContentsAfterDeletion={setDeletePlan}
          message={DeactivateAlertText}
        />
      )}
      {showEditPlanComponent ? (
        <ReusableForm
          formTitle="Edit Plan"
          plan={plan}
          setShowForm={setShowEditPlanComponent}
          apiCallingFucntion={apiCallingFucntion}
        />
      ) : null}
    </>
  );
};

export default function SubscriptionOptions() {
  const { handleCreateToast } = useContext(ToastContext);

  const [showAddNewPlanComponent, setShowAddNewPlanComponent] = useState(false);
  const [plans, setPlans] = useState<planProps[] | any>([]);
  const [amount, setAmount] = useState <any>([])
  function onSuccessPlans(plans: planProps[]) {
    setPlans([...plans]);
    setShowAddNewPlanComponent(false);
  }

  useEffect(() => {
    getContent(findAllSubscriptionPlansEndPoint, onSuccessPlans);
  }, []);

  const addNewPlan = (plan: planProps, response: any) => {
    getContent(findAllSubscriptionPlansEndPoint, onSuccessPlans, onFail);

    handleCreateToast(response.message, success);
  };

  function onFail(message: string) {
    handleCreateToast(message, error);
  }

  function apiCallingFucntion(data: planProps) {
    const { _id, ...rest } = data;
    createContent(createSubscriptionPlansEndPoint, rest, addNewPlan, onFail);
  }
  function onSuccessAmount(data:any){
    setAmount(data.totalSubscribedAmount)
  }
  useEffect(() => {
    getContent(getTotalSubscribedAmount, onSuccessAmount);
  }, []);
  
  const Plans = () => {

    return (
      <div>
        {plans.length <= 0 ? (
          <NoContentFound text={NoPlansCurrentAdded} />
        ) : (
          <div className="grid  grid-cols-3 gap-4 h-[100%] ">
            {plans !== undefined &&
              plans?.map((plan: planProps) => {
                return (
                  <SubscriptionCard
                    key={plan._id}
                    plan={plan}
                    setPlans={setPlans}
                  />
                );
              })}
              <>
              <div  className="flex flex-col justify-between  max-w-sm rounded-md h-[100%] customshadow p-8 bg-white">
              <div>
                <p className=" text-blue-500">Financial Flow</p>
              </div>
              <p className="font-black text-lg mt-6 ">Today: {amount[0]?.day?? 0}$</p>
              <p className="font-black text-lg mt-6 ">This Week: {amount[0]?.week?? 0}$</p>
              <p className="font-black  text-lg mt-6 ">This Month: {amount[0]?.month?? 0}$</p>
              <p className="font-black text-lg mt-8 ">This year: {amount[0]?.year?? 0}$</p>
                </div>
              </>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="flex flex-col gap-4  my-8 ">
        <button
          onClick={() => setShowAddNewPlanComponent(true)}
          className=" w-max h-min flex justify-between items-center gap-2 font-medium  bg-[#2e2e2e]  px-5 py-2 rounded text-lg text-white  border"
        >
          <PlusSquare />
          <p>Add Plan</p>
        </button>
        <Plans />
      </div>
      {showAddNewPlanComponent ? (
        <ReusableForm
          formTitle="Add New Plan"
          plan={{
            _id: null,
            planName: "",
            description: "",
            benefits: [],
            subscriptionOptions: [],
          }}
          setShowForm={setShowAddNewPlanComponent}
          apiCallingFucntion={apiCallingFucntion}
        />
      ) : null}
    </div>
  );
}
