import { ChangeEvent, useCallback, useContext, useEffect, useState } from "react";
import { X } from "react-feather";
import {
  findMessagesOfReportedChat,
  findReportedChatEndPoint,
  findReportedUserEndPoint,
  findVerifyImgRequestEndPoint,
  getpartnerImage,
  getReportedUserImage,
  getReportedUserProfilePicture,
  getUserImageNames,
  getUserVerificationRequestImage,
  modifyReviewerActionTypeOnReportedChat,
  modifyReviewerActionTypeOnReportedUser,
  modifyReviewerActionTypeOnUserVerificationRequest,
} from "../../adapters/endpoints";
import { fetchAndDisplayImages, getContent } from "../../adapters/get";
import {
  error,
  giveWarningToUser,
  IdTypeEnum,
  monthNames,
  reportActionType,
  selectActionType,
  success,
  takeActionTextAreaText,
  UpdateActionAlertText,
  UsersTypeEnum,
  UserType,
  verifyActionType,
} from "../../constants";
import { ToastContext } from "../Toast/ToastProvider";
import { createContent } from "../../adapters/create";
import AlertPopup from "../AlertPopup";
import Button from "../Button";
import Dropdown from "../Dropdown";
import TextArea from "../TextArea";
import { UserNameAvatar, UserNameAvatarForChat } from "../userNameAvatar";
import { InfoCard } from "../ViewReportedUser";
import ImageViewer from "../ImageViewer";
import Spinner, {DetailSpinner, LoadingDots} from "../Spinner";
import PostImageZoomPopup from "../PostImageViewer";
import JSONPretty from "react-json-pretty";
import UserPopup from "../UserPopup";
import UserDetailComponent from "../UserDetailComponent";
import { ResponsibleUserDetail } from "../ResponsibleUserDetail";
var JSONPrettyMon = require('react-json-pretty/dist/monikai');

const Box = ({ item }: any) => {
  const { title, value } = item;
  return (
    <div className="border-2 rounded-md py-2 text-center space-y-2 ">
      <p className="text-lg font-medium">{value}</p>
      <p className="text-gray-400 ">{title}</p>
    </div>
  );
};

const ReportDetail = ({
  reportId,
  reportType,
  reporterName,
  description,
  reviewerAction,
  noticeFromReviewer,
  noteToAdmin,
  isReviewed,
  isGenuine,
  violationType
}: any) => {
  const [openVerificationImage, setOpenVerificationImage] = useState(false);
  const [openReferenceImage, setOpenReferenceImage] = useState(false);
  const [images, setImages] = useState<any>();

  const [loadingVerificationImage, setLoadingVerificationImage] =
    useState(false);

  function onSucessGettingUsersVerificationImgs(data: any) {
    setLoadingVerificationImage(false);
    setImages(data.image);
  }
  function onFail(message: any) {
    setLoadingVerificationImage(false);
  }
  
  useEffect(() => {
    if (reportType === UsersTypeEnum.USERS_APPLIED_FOR_VERIFICATION) {
      const VERIFY_USER_IMG = `${getUserVerificationRequestImage}${reportId}`;
      setLoadingVerificationImage(true);

      getContent(VERIFY_USER_IMG, onSucessGettingUsersVerificationImgs, onFail);
    }
  }, []);

  const referThisImg =
    images?.referenceImageForVerificationRequest[0]?.image?.data;
  const imgForVerification =
    images?.findUserImageToRespondUserVerificationRequest?.image?.data;

  return (
    <div>
    
<div className="space-y-6 py-6">     
      <p className=" pt-2 font-medium text-gray-500">
        Reviewer Action :
        <span
  className={`m-2 rounded-md p-2 text-black ${
    isGenuine || reviewerAction === 'activate' ? 'bg-blue-300' : 'bg-red-300'
  }`}
>
  {reviewerAction
    ? reviewerAction
    : isGenuine
    ? verifyActionType.verify
    : verifyActionType.decline}
</span>
      </p>
      <div className=" overflow-auto">
      
      <p className=" font-medium text-gray-500">
      Violation Type : <br />
        <div className=" my-1 p-2 rounded bg-gray-100 text-gray-900">
          {violationType}
        </div>{" "}
      </p>
      <p className=" font-medium text-gray-500">
        Description : <br />{" "}
        <div className=" my-1 p-2 rounded bg-gray-100 text-gray-900">
          {description}
        </div>{" "}
      </p>
      <p className=" font-medium text-gray-500">
        Notice from Reviewer : <br />
        <div className=" my-1 p-2 rounded break-words  bg-gray-100 text-gray-900">
          {noticeFromReviewer}
        </div>{" "}
      </p>
      <p className=" font-medium text-gray-500">
        Notice from Reviewer to manager : <br />
        <div className=" my-1 p-2 rounded break-words  bg-gray-100 text-gray-900">
          {noteToAdmin}
        </div>{" "}
      </p>
      <div>
        {" "}
        {reportType === UsersTypeEnum.USERS_APPLIED_FOR_VERIFICATION ? (
              <div>
                <div className="grid grid-cols-2 gap-4">
                  <p className=" text-sm  text-[#999999]">
                    Image took for Verification
                  </p>
                  <p className=" text-sm  text-[#999999]">
                    Image for Reference
                  </p>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <img
                    className=" object-cover w-full h-64 rounded-md"
                    onClick={() => setOpenVerificationImage(true)}
                    src={`data:image/jpeg;base64,${imgForVerification}`}
                    alt=" Imgage took for Verification"
                  />
                  {openVerificationImage && (
                    <ImageViewer
                      imageUrl={imgForVerification}
                      onClose={() => setOpenVerificationImage(false)}
                    />
                  )}
                  <img
                    className=" object-cover w-full h-64 rounded-md"
                    onClick={() => setOpenReferenceImage(true)}
                    src={`data:image/jpeg;base64,${referThisImg}`}
                    alt=" Imgage took for Verification"
                  />
                  {openReferenceImage && (
                    <ImageViewer
                      imageUrl={referThisImg}
                      onClose={() => setOpenReferenceImage(false)}
                    />
                  )}
                </div>
              </div>
           
        ) : (
          " "
        )}
      </div>
      </div>
    </div>
    <div>
      
    </div>
    </div>
  );
};

const UserDetail = ({ user,refreshTrigger  }: any) => {
  const {

  } = user || {};

  const { handleCreateToast } = useContext(ToastContext);
  const [responsibleImg, setResponsibleImg] = useState<any>();
  const [OpenZoomPic, setOpenZoomPic] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [loadingUserImage, setLoadingUserImage] = useState(false);
  const [imageNames, setImageNames] = useState<any>()
  const [jsonFormat, setJsonFormat] = useState<string | null>(null);

  const  onSucessGettingUsersUploadedImgs =useCallback ((data: any) => {
    setResponsibleImg(data);
    setLoadingUserImage(false);

  },
  [setLoadingUserImage, setResponsibleImg] // Include dependencies here

)
  const onFail = useCallback(
    (message: any) => {
      setLoadingUserImage(false);
      handleCreateToast(message, error);
    },
    [setLoadingUserImage, handleCreateToast] // Include dependencies here
  );
  const onSucessGettingUserImageNames = (data:any) =>{
    setImageNames(data)
  }
  const toJsonClick = (user: string) => {
    setJsonFormat(user);
  };
  const closePopup = () => {
    setJsonFormat(null);

  };
  useEffect(()=>{
    if(user?._id){ 
      const ENDPOINTSNAME = `${getUserImageNames}${user?._id}`
      getContent(ENDPOINTSNAME, onSucessGettingUserImageNames,onFail)
     }
  },[user, onFail,onSucessGettingUsersUploadedImgs])

useEffect(() => {
    if (imageNames && imageNames.userImageNames && imageNames.userImageNames.length > 0) {    
      const ENDPOINTS = imageNames.userImageNames.map(   
        (imageId: any) => `${getpartnerImage}${imageId.name}`
      );
      fetchAndDisplayImages(
        ENDPOINTS,
        onSucessGettingUsersUploadedImgs,
        onFail
      );
      setLoadingUserImage(true);
    } else {
      setLoadingUserImage(false);
    }
  }, [imageNames,onSucessGettingUsersUploadedImgs,onFail]);
  
  return (
    <div className="relative flex flex-col items-center w-full  h-full ">
         { jsonFormat && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">

        <div className="bg-white p-6 rounded-lg shadow-lg">
       <UserPopup user={jsonFormat} onClose={closePopup} />
    </div>
    </div>

  )}
        {loadingUserImage ? (
          <DetailSpinner />
        ) : (
          <>
              <div className="bg-white shadow-lg rounded-lg px-6 ml-28 absolute flex justify-end flex-col h-[100%] mb-10 w-3/5 mx-auto ">
              <p className="text-center text-blue-500">User</p>

                <div className="flex space-x-4">
                  {responsibleImg ? (
                    <img
                      className="object-cover border rounded-full h-16 w-16"
                      src={responsibleImg[0]}
                      alt={user?.firstName}
                    />
                  ) : (
                    <UserNameAvatar
                      userName={user?.firstName}
                      userType={'user'}
                    />
                  )}
                  <div>
                    <p className="font-bold text-lg">
                      {user?.firstName}{" "}
                      {user?.lastName}
                    </p>
                    <p className="text-gray-600">
                      Phone number: {user?.phoneNumber}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col gap-6 mb-4">
            <div className=" h-auto overflow-auto">
              <div className=" capitalize gap-4 p-4 capitalize my-4 ">
                  {user?.accountStatus && (
                    <InfoCard
                      value={user?.accountStatus}
                      label="Account Status"
                    />
                  )}
                  {user?.rating && (
                    <InfoCard
                      value={user?.rating}
                      label="Rate"
                    />
                  )}
                  {user?.gender && (
                    <InfoCard
                      value={user?.gender}
                      label="Gender"
                    />
                  )}
                  {user?.age && (
                    <InfoCard value={user?.age} label="Age" />
                  )}

                  {user?.address?.country && (
                    <InfoCard
                      value={user?.address?.country}
                      label="Country"
                    />
                  )}
                  {user?.address?.region && (
                    <InfoCard
                      value={user?.address?.region}
                      label="Region"
                    />
                  )}
                
                  {user?.lastSeenAt && (
                    <InfoCard
                      value={user?.lastSeenAt.split("T")[0]}
                      label="Last Seen"
                    />
                  )}
                </div>
                <div className="flex items-end justify-end">
            <button 
             onClick={() => {
               toJsonClick(user?._id);
             }}
            className=' z-40 bg-[#2e2e2eed] hover:bg-[#2e2e2e] text-white px-4 rounded py-2'>More</button>
            </div>
            </div>
           
          </div>
         
              </div>
          
          </>
        )}

        {responsibleImg && (
        <div className=" absolute flex flex-col px-6 left-2 w-1/4 mr-16 mx-auto">
        <div className="bg-white shadow-lg  rounded-lg">
        <p className="font-semibold text-center">Uploaded Images</p>
         <div className="grid grid-row-2">
          <div className={`grid grid-cols-2 grid-rows-${(responsibleImg.length -1)/2} gap-4 mt-2`}>
              {responsibleImg.map((img:any, index:number) => (
                <div key={index} className="border border-gray-200 rounded-md h-32">
                  <img
                    className="object-cover w-full h-full rounded-md cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setOpenZoomPic(true);
                      setSelectedImage(img);
                    }}
                    src={img}
                    alt={`Imag ${index + 1}`}
                  />
                </div>
              ))}
            
            </div>
           
            {OpenZoomPic && (
              <PostImageZoomPopup
                imageUrl={selectedImage}
                onClose={() => setOpenZoomPic(false)}
              />
            )}
          </div>
          </div>
        </div>
      )}
         {/* <div className=" absolute flex flex-col h-[95%] px-6 right-4 w-1/4  mx-auto">
        <div  className="bg-white h-screen  shadow-lg rounded-lg">
        <TakeAction />
        </div>
        </div> */}
      </div>
  );
};

const TakeAction = ({ reportType, reportId, setShowUser }: any) => {
  const [showPopup, setShowPopup] = useState<Boolean>(false);
  const [selectedActionType, setSelectedActionType] = useState("");
  // const [reviewDescription, setReviewDescription] = useState('')
  const [uniqueid, setuniqueid] = useState("intial");
  const { handleCreateToast } = useContext(ToastContext);

  const onSuccess = (data: any, response: any) => {
    setShowPopup(false);
    setuniqueid(crypto.randomUUID());
    handleCreateToast(response.message, success);
    setShowUser(false);
  };

  const onFail = (message: string) => {
    handleCreateToast(message, error);
    setShowPopup(false);
  };

  const submitReview = () => {
    const data = {
      // reasonForModifyingTheAction: reviewDescription,
      actionType: selectedActionType,
      [reportType === UsersTypeEnum.USERS_APPLIED_FOR_VERIFICATION
        ? IdTypeEnum.VERIFICATION_REQUEST_ID
        : reportType === UsersTypeEnum.REPORTED_USER
        ? IdTypeEnum.REPORTED_USER_ID
        : IdTypeEnum.REPORTED_CHAT_ID]: reportId,
    };
    let endpoint;
    switch (reportType) {
      case UsersTypeEnum.REPORTED_USER:
        endpoint = modifyReviewerActionTypeOnReportedUser;
        break;
      case UsersTypeEnum.USERS_CHAT_GOT_REPORTED:
        endpoint = modifyReviewerActionTypeOnReportedChat;
        break;
      case UsersTypeEnum.USERS_APPLIED_FOR_VERIFICATION:
        endpoint = modifyReviewerActionTypeOnUserVerificationRequest;
        break;
    }
    createContent(endpoint, data, onSuccess, onFail);
  };

  return (
    
    <div>
          <div className="flex flex-col justify-between items-center justify-center h-full max-h-[85%] scrollbar-hide  overflow-y-auto ">
            <div className=" items-center  pt-6 justify-center">
              <h2 className=" text-md text-start  mb-2">
                Action Type{" "}
              </h2>
                <Dropdown
          name={selectActionType}
          list={
            reportType === UsersTypeEnum.USERS_APPLIED_FOR_VERIFICATION
              ? verifyActionType
              : reportActionType
          }
          onClick={(e: ChangeEvent<HTMLInputElement>) => {
            setSelectedActionType(e.currentTarget.innerText);
          }}
        />
              
            
            </div>
            <div className=" items-center mx-10 justify-center mt-28">
            <Button text={giveWarningToUser} onClick={() => setShowPopup(true)} />
            </div>
          
          </div>
          {showPopup ? (
          <AlertPopup
            message={UpdateActionAlertText}
            Close={() => {
              setShowPopup(false);
            }}
            onSubmit={submitReview}
          ></AlertPopup>
        ) : null}
        </div>
  );
};
interface PopupProps {
  report:any
  onClose: () => void;

}
const ReportedChat:React.FC<PopupProps> = ({  report,onClose }) => {
  const [message, setMesssage] = useState([]);
  const [senderId, setSenderId] = useState();
  const [chats, setChats] = useState<any>();
  const [sender, setSender] = useState();
  const [receiver, setReceiver] = useState();
  const [receiverId, setReceiverId] = useState();
  const chatId = report?.responsibleChatId;
  const [loadingUserChats, setLoadingUserChats] = useState(false);

  const { handleCreateToast } = useContext(ToastContext);

  const endpoint = `${findMessagesOfReportedChat}${chatId}`;

  const monthIndex = new Date(chats?.updatedAt)?.getMonth();
  const monthName = monthNames[monthIndex];

  const day = new Date(chats?.updatedAt).getDate();

  const onSucess = (data: any) => {
    setLoadingUserChats(false)
    setChats(data.messagesOfReportedChat[0]);
    setSenderId(data.messagesOfReportedChat[0]?.senderId);
    setReceiverId(data.messagesOfReportedChat[0]?.receiverId);
    setMesssage(data.messagesOfReportedChat[0]?.messageIds);
  };

  const onFail = (message: any) => {
    setLoadingUserChats(false)
    handleCreateToast(message, error);
  };

  useEffect(() => {
    if (chatId?.length > 0) {
      getContent(endpoint, onSucess, onFail);
      setLoadingUserChats(true)
    }
  }, [chatId]);

  const [senderProfilePic, setSenderProfilePic] = useState("");
  const [receiverProfilePic, setReceiverProfilePic] = useState("");

  function onSucessGettingSenderImg(data: any) {
    setSenderProfilePic(data?.userProfilePicture[0]?.image?.data);
  }

  function onSucessGettingReceiverImg(data: any) {
    setReceiverProfilePic(data?.userProfilePicture[0]?.image?.data);
  }

  useEffect(() => {
    if (chats) {
      const findSenderImg = `${getReportedUserProfilePicture}${senderId}`;
      const findReceiverImg = `${getReportedUserProfilePicture}${receiverId}`;

      getContent(findSenderImg, onSucessGettingSenderImg, onFail);
      getContent(findReceiverImg, onSucessGettingReceiverImg, onFail);
    }
  }, [chats, senderId, receiverId]);

  return (
    <div className="">
       <div className="flex justify-end ">
          <X onClick={onClose} className=" cursor-pointer " />
        </div>
      <div
        className={` max-h-[70vh] w-full min-w-full space-y-6`}
      >
        <div className="flex">
          <div className=" absolute rounded-full bg-gray-200 text-gray-400 px-4 max-w-max right-2/4 ">
            {monthName && monthName} {day && day}
          </div>
        </div>
        <ChatUI
          report={report}
          messages={message}
          senderId={senderId}
          senderProfilePic={senderProfilePic}
          receiverProfilePic={receiverProfilePic}
        />
      </div>
   
    </div>
  );
};

const ChatUI = ({
  report,
  messages,
  senderId,
  senderProfilePic,
  receiverProfilePic,
}: any) => {
  const ImgCircle = ({ userImg, userName }: any) => {
    return (
      <div className="h-10 w-10 overflow-hidden rounded-full">
        <img
          className="object-cover h-full w-full"
          src={`data:image/jpeg;base64,${userImg}`}
          alt={userName}
        />
      </div>
    );
  };

  return (
    <div className="flex flex-col  h-screen">
      <div className="flex-grow h-0  max-h-[70vh]   overflow-y-auto ">
        {messages?.map((message: any) => {
          const dateString = message?.createdAt;
          const date = new Date(dateString);
          const time = date.toLocaleTimeString();

          const repliedMessage = messages.find(
            (msg: any) => msg._id === message.repliedToMessageId
          );

          const isSender = message.senderId === senderId;
          const messageClassName = `px-4 py-2 rounded-2xl ${
            isSender ? "rounded-br-none bg-blue-200" : "rounded-bl-none bg-gray-200"
          }`;

          return (
            <div
              key={message._id}
              id={message._id}
              className={`flex gap-2 my-2 ${
                isSender ? "flex-row-reverse" : "flex"
              }`}
            >
              <div className={`flex items-end `}>
                {isSender ? (
                  <>
                    {senderProfilePic ? (
                      <ImgCircle
                        userImg={senderProfilePic}
                        userName={report?.reporterId[0]?.firstName}
                      />
                    ) : (
                      <UserNameAvatarForChat
                        userType={UserType.SENDER}
                        userName={report?.reporterId[0]?.firstName}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {receiverProfilePic ? (
                      <ImgCircle
                        userImg={receiverProfilePic}
                        userName={report?.responsibleId[0]?.firstName}
                      />
                    ) : (
                      <UserNameAvatarForChat
                        userType={UserType.RECIEVER}
                        userName={report?.responsibleId[0]?.firstName}
                      />
                    )}
                  </>
                )}
              </div>
              <div className="flex flex-col mt-6">
                {message.repliedToMessageId && repliedMessage ? (
                  <div
                    className={` rounded-lg p-2 mb-2 cursor-pointer${isSender ? "bg-gray-100" : "bg-gray-100"}`}
                    style={{ borderLeft: "4px solid #e0e0e0" }}
                    onClick={() => {
                      const originalMessageElement = document.getElementById(
                        repliedMessage?._id
                      );
                      if (originalMessageElement) {
                        originalMessageElement.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                      }
                    }}
                  >
                    <p className="text-sm font-semibold text-gray-600 ">
                      {repliedMessage.messageBody.slice(0, 50)}...
                    </p>
                  </div>
                ) : null}
                <div className={messageClassName}>
                  <p className="font-semibold">{message.messageBody}</p>
                </div>
                <div className="flex justify-end">
                  <p
                    className={`text-xs text-gray-400 mt-2 font-small ${
                      isSender ? "ml-auto" : "mr-auto"
                    }`}
                  >
                    {time}
                  </p>
                  <div className="flex justify-end">
                    {message.reactionFromSender && (
                      <div
                        className={`h-8 w-8 bg-white border-2 border-gray-300 ${
                          isSender ? "mr-0" : "ml-0"
                        } rounded-full flex items-center justify-center`}
                      >
                        <span role="img" aria-label="emoji">
                          {message.reactionFromSender}
                        </span>
                      </div>
                    )}
                    {message.reactionFromReceiver && (
                      <div
                        className={`h-10 w-10 bg-white-300 border-2 border-gray-300 ${
                          isSender ? "mr-0" : "ml-0"
                        } rounded-full flex items-center justify-center`}
                      >
                        <span role="img" aria-label="emoji">
                          {message.reactionFromReceiver}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default function ViewUser({ reportInfo, setShowUser }: any) {
  const [tab, setTab] = useState(1);
  const { handleCreateToast } = useContext(ToastContext);
  const [report, setReport] = useState<any>();
  const [refreshTrigger, setRefreshTrigger] = useState(false); // Add refreshTrigger state

  function updateEndpointOnTheBasisOfUsersType(type: string): string {
    const endpoints = {
      REPORTEDUSERS: findReportedUserEndPoint,
      REPORTEDCHATS: findReportedChatEndPoint,
      VERIFICATIONUSERS: findVerifyImgRequestEndPoint,
    };

    return endpoints[type as keyof typeof endpoints];
  }

  function onSuccess(data: any) {
    switch (reportInfo.type) {
      case UsersTypeEnum.REPORTED_USER:
        setReport(data?.reportedUser[0]);
        break;
      case UsersTypeEnum.USERS_CHAT_GOT_REPORTED:
        setReport(data?.reportedChat[0]);
        break;
      case UsersTypeEnum.USERS_APPLIED_FOR_VERIFICATION:
        setReport(data?.verificationRequest[0]);
        break;
    }
  }

  function onFail(message: any) {
    handleCreateToast(message, error);
  }

  const GET_REPORT_DETAIL = `${updateEndpointOnTheBasisOfUsersType(
    reportInfo?.type
  )}${reportInfo?._id}`;

  useEffect(() => {
    getContent(GET_REPORT_DETAIL, onSuccess, onFail);
  }, [GET_REPORT_DETAIL]);

  const tabComponents = {
    1: (
      <UserDetail
        user={
          reportInfo.type === UsersTypeEnum.USERS_APPLIED_FOR_VERIFICATION
            ? report?.userInfoId[0]
            : report?.responsibleId[0]
        }
        refreshTrigger={refreshTrigger} 
      />
    ),
    2: (
      <ReportDetail
        reporterName={
          reportInfo.type === UsersTypeEnum.USERS_APPLIED_FOR_VERIFICATION
            ? ""
            : `${report?.reporterId[0]?.firstName} ${
                report?.reporterId[0]?.lastName ?? ""
              }`
        }
        reportId={report?._id}
        reportType={reportInfo.type}
        description={report?.description}
        isReviewed={report?.isReviewed}
        isGenuine={report?.isGenuine}
        reviewerAction={report?.actionType}
        noticeFromReviewer={report?.reviewerReasonForTakingAction}
        noteToAdmin={report?.noteToAdminManager}
      />
    ),
    3: (
      <UserDetail
        user={
          reportInfo.type === UsersTypeEnum.USERS_APPLIED_FOR_VERIFICATION
            ? "no user"
            : report?.reporterId[0]
        }
        refreshTrigger={refreshTrigger} 
      />
    ),
    // 4: <ReportedChat report={report} reportInfo={reportInfo} />,
    5: (
      <TakeAction
        reportType={reportInfo?.type}
        reportId={reportInfo?._id}
        setShowUser={setShowUser}
      />
    ),
  };
  
  const ReportDetails = () =>{
    return (
      <div className="relative flex flex-row h-auto">
  <div className="w-[70%] p-4">
      <UserDetail
        user={
          reportInfo.type === UsersTypeEnum.USERS_APPLIED_FOR_VERIFICATION
            ? report?.userInfoId[0]
            : report?.responsibleId[0]
        }
        refreshTrigger={refreshTrigger} 
      />   

  </div>

  <div className="w-[35%] p-4">
    <div className="bg-white shadow-lg rounded-lg p-6">
      <p className="text-center text-blue-500">Reviewer Action</p>
      <ReportDetail
        reporterName={
          reportInfo.type === UsersTypeEnum.USERS_APPLIED_FOR_VERIFICATION
            ? ""
            : `${report?.reporterId[0]?.firstName} ${
                report?.reporterId[0]?.lastName ?? ""
              }`
        }
        reportId={report?._id}
        reportType={reportInfo.type}
        description={report?.description}
        isReviewed={report?.isReviewed}
        isGenuine={report?.isGenuine}
        reviewerAction={report?.actionType}
        noticeFromReviewer={report?.reviewerReasonForTakingAction}
        noteToAdmin={report?.noteToAdminManager}
      />   

       </div>

  </div>
  <div>
    <div className="p-4">
    <div className="bg-white shadow-lg rounded-lg p-6">
    <p className="text-center text-blue-500">Modify Action</p>
        <TakeAction 
             reportType={reportInfo?.type}
             reportId={reportInfo?._id}
             setShowUser={setShowUser}
            />
    </div>
    </div>
  </div>
      </div>
    )
  }

  const ReportedUserDetails = () =>{
    return (
      <div className="relative flex flex-row h-auto">
      <div className="w-2/6 p-4">
        <div className="bg-white shadow-lg rounded-lg p-6">
          <p className="text-center text-blue-500">Reporter</p>
          <UserDetailComponent userReportData={report} />
        </div>
      </div>
    
      <div className="w-2/6 p-4">
        <div className="bg-white shadow-lg rounded-lg p-6">
          <p className="text-center text-blue-500">Responsible</p>
          <ResponsibleUserDetail userReportData={report} />
        </div>
      </div>
    
      <div className="w-2/6 p-4">
        <div className="bg-white shadow-lg rounded-lg h-full flex flex-col">
          <div className="flex-1 p-6">
            <p className="text-center text-blue-500 pt-2">Reviewer</p>
            <ReportDetail
              reporterName={`${
                report?.reporterId[0]?.firstName ?? ""
              } ${report?.reporterId[0]?.lastName ?? ""}`}
              reportId={report?._id}
              reportType={reportInfo.type}
              description={report?.description}
              isReviewed={report?.isReviewed}
              isGenuine={report?.isGenuine}
              reviewerAction={report?.actionType}
              noticeFromReviewer={report?.reviewerReasonForTakingAction}
              noteToAdmin={report?.noteToAdminManager}
              violationType= {report?.violationType}
            />
        <p className="text-center text-blue-500">Modify Action</p>
        <TakeAction 
             reportType={reportInfo?.type}
             reportId={reportInfo?._id}
             setShowUser={setShowUser}
            />
          </div>
        </div>
      </div>
    </div>
    
    )
  }

  const ReportedChatDetails = () =>{
    const [toAction, setToAction] = useState<any>(false);
    const handelPopup = ()=>{
      setToAction(true)
     }
     const closePopup = () => {
      setToAction(false);
  
    };
    return (
      <div className="relative flex flex-row h-auto">
         { toAction && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">

        <div className="bg-white w-2/5 p-6 rounded-lg shadow-lg">
       <ReportedChat report={report} onClose={closePopup} />
    </div>
    </div>
  )}
      <div className="w-2/6 p-4">
        <div className="bg-white shadow-lg rounded-lg p-6">
          <p className="text-center text-blue-500">Reporter</p>
          <UserDetailComponent userReportData={report} />
        </div>
      </div>
    
      <div className="w-2/6 p-4">
        <div className="bg-white shadow-lg rounded-lg p-6">
          <p className="text-center text-blue-500">Responsible</p>
          <ResponsibleUserDetail userReportData={report} />
        </div>
      </div>
    
      <div className="w-2/6 p-4">
        <div className="bg-white shadow-lg rounded-lg h-full flex flex-col">
          <div className="flex-1 p-6">
          <p className="text-center text-blue-500 pt-2">
         <span className="flex justify-between  items-center">
        Reviewer
          <button  onClick={() => { handelPopup()}}  className="ml-auto  transform-translate-y-1/2 px-2  bg-gray-800 text-white rounded-full hover:bg-gray-700 transition">To Chats</button>
            </span>
          </p>            
           <ReportDetail
              reporterName={`${
                report?.reporterId[0]?.firstName ?? ""
              } ${report?.reporterId[0]?.lastName ?? ""}`}
              reportId={report?._id}
              reportType={reportInfo.type}
              description={report?.description}
              isReviewed={report?.isReviewed}
              isGenuine={report?.isGenuine}
              reviewerAction={report?.actionType}
              noticeFromReviewer={report?.reviewerReasonForTakingAction}
              noteToAdmin={report?.noteToAdminManager}
              violationType= {report?.violationType}
            />
        <p className="text-center text-blue-500">Modify Action</p>
        <TakeAction 
             reportType={reportInfo?.type}
             reportId={reportInfo?._id}
             setShowUser={setShowUser}
            />
          </div>
        </div>
      </div>
    </div>
    
    )
  }

  const handleTabClick = (selectedTab: number) => {
    setTab(selectedTab);
    setRefreshTrigger((prev) => !prev); // Toggle the refreshTrigger to trigger useEffect
  };
  return (
    <div className="fixed inset-0 z-40 flex overflow items-end justify-end bg-gray-900 bg-opacity-50 ">
    <div className="p-8 absolute top-0 right-0 h-full w-full md:w-[85%] bg-gray-50 rounded-l-md z-50">
      <div className="flex justify-end">
        <X onClick={() => setShowUser(false)} className=" cursor-pointer " />
      </div>
      <div className=" h-full space-y-8 ">
       
          {
          reportInfo.type === UsersTypeEnum.USERS_APPLIED_FOR_VERIFICATION && (
            <ReportDetails /> ) 
            }
             {
          reportInfo.type === UsersTypeEnum.REPORTED_USER && (
            <ReportedUserDetails /> ) 
            }
             {
          reportInfo.type === UsersTypeEnum.USERS_CHAT_GOT_REPORTED && (
            <ReportedChatDetails /> ) 
            }
      </div>
    </div>
  </div>
  );
}
