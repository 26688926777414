import { useState, useEffect, useContext, useRef } from "react";
import {
  getpartnerImage,
} from "../adapters/endpoints";
import { fetchAndDisplayImages, getContent } from "../adapters/get";
import { UserNameAvatar } from "./userNameAvatar";
import { ToastContext } from "./Toast/ToastProvider";
import { DetailSpinner, LoadingDots, SideBarSpinner } from "./Spinner";
import JSONPretty from "react-json-pretty";
import { X } from "react-feather";
import ImagesPopup from "./ImagesPopup";
import { InfoCard } from "./ViewReportedUser";
import UserPopup from "./UserPopup";
var JSONPrettyMon = require("react-json-pretty/dist/monikai");

interface userReportProps {
  userReportData: any;
}

const UserDetailComponent = ({ userReportData }: userReportProps) => {
  const [reporterImg, setReporterImg] = useState<any>(null);
  const { handleCreateToast } = useContext(ToastContext);
  const [selectedUser, setSelectedUser] = useState<any | null>(null);
  const [jsonFormat, setJsonFormat] = useState<string | null>(null);


  const handleUserClick = (user: any) => {
    setSelectedUser(user);
  };
  const toJsonClick = (user: string) => {
    setJsonFormat(user);
  };
  const closePopup = () => {
    setSelectedUser(null);
    setJsonFormat(null);

  };
  const onSucessGettingUsersUploadedImgs = (data: any) => {
    setReporterImg(data);
  };
  useEffect(() => {
    if (userReportData) {
      const REPORTER_IMAGE = userReportData?.reporterId[0]?.images.map(
        (imageId: any) => `${getpartnerImage}${imageId.name}`
      );
      fetchAndDisplayImages(
        REPORTER_IMAGE,
        onSucessGettingUsersUploadedImgs,
        handleCreateToast
      );
    }
  }, [userReportData]);
  
  return (
    <div>
      {selectedUser && (
        <div className="fixed inset-0 z-50 flex  items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-1/4 mt-10 h-auto my-12 rounded-lg shadow-lg">
            <ImagesPopup userImages={selectedUser} onClose={closePopup} />
          </div>
        </div>
      )}
      { jsonFormat && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">

        <div className="bg-white p-6 rounded-lg shadow-lg">
       <UserPopup user={jsonFormat} onClose={closePopup} />
    </div>
    </div>

  )}
      <div>
        {userReportData ? (
          <div>
            <div className="flex w-30 space-x-4 items-center">
              {reporterImg ? (
                <button
                  className="z-40 "
                  onClick={() => {
                    handleUserClick(reporterImg);
                  }}
                >
                  <img
                    className="object-cover border rounded-full h-16 w-16"
                    src={reporterImg[0]}
                    alt={userReportData?.reporterId[0]?.firstName}
                  />
                </button>
              ) : (
                <UserNameAvatar
                  userName={userReportData?.reporterId[0]?.firstName}
                  userType="sender"
                />
              )}

              <div className="space-y-2">
                <p className="font-bold text-lg">
                  {userReportData?.reporterId[0]?.firstName}
                  {userReportData?.reporterId[0]?.lastName ?? ""}
                </p>
                {userReportData?.reporterId[0]?.phoneNumber && (
                  <p className="text-gray-600">
                    {userReportData?.reporterId[0]?.phoneNumber}
                  </p>
                )}
              </div>
            </div>
            {/* return ( */}
            <div className="flex flex-col gap-6 mb-4">
              <div className=" h-auto overflow-auto">
                <div className=" gap-2 p-4 divide-y-2 capitalize my-2">
                  {userReportData?.reporterId[0]?.accountStatus && (
                    <InfoCard
                      value={userReportData?.reporterId[0]?.accountStatus}
                      label="Account Status"
                    />
                  )}
                  {userReportData?.reporterId[0]?.rating && (
                    <InfoCard
                      value={userReportData?.reporterId[0]?.rating}
                      label="Rate"
                    />
                  )}
                  {userReportData?.reporterId[0]?.gender && (
                    <InfoCard
                      value={userReportData?.reporterId[0]?.gender}
                      label="Gender"
                    />
                  )}
                  {userReportData?.reporterId[0]?.age && (
                    <InfoCard value={userReportData?.reporterId[0]?.age} label="Age" />
                  )}

                  {userReportData?.reporterId[0]?.address?.country && (
                    <InfoCard
                      value={userReportData?.reporterId[0]?.address?.country}
                      label="Country"
                    />
                  )}
                  {userReportData?.reporterId[0]?.address?.region && (
                    <InfoCard
                      value={userReportData?.reporterId[0]?.address?.region}
                      label="Region"
                    />
                  )}
                  {userReportData?.reporterId[0]?.lastSeenAt && (
                    <InfoCard
                      value={userReportData?.reporterId[0]?.lastSeenAt.split("T")[0]}
                      label="Last Seen"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="flex items-end justify-end mb-4">
            <button 
             onClick={() => {
               toJsonClick(userReportData?.reporterId[0]?._id);
             }}
            className=' z-40 bg-[#2e2e2eed] hover:bg-[#2e2e2e] text-white px-4 rounded py-2'>More</button>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-6 top-1/2 ">
            <DetailSpinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default UserDetailComponent;
