  
  type Props = {
    label:any,
    value:any
  }
  
  const InfoCard = (props: Props) => {  
    
    return (
      <div className='py-2 px-2 bg-white border rounded-md w-full max-w-lg my-2 mx-auto'>
      <div className=' justify-between items-center'>
        <p className='text-black text-sm font-bold'>{props.label}</p>
        <p className='text-black'>{props.value}</p>
      </div>
    </div>
    )
  }
  
  export default InfoCard