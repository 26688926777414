import React, { useEffect, useState } from 'react';
import { fetchAndDisplayImages, getContent } from '../adapters/get';
import { getpartnerImage, getUserImageNames, GetUserInfoEndpoint } from '../adapters/endpoints';
import { UserNameAvatar } from './userNameAvatar';
import JSONPretty from 'react-json-pretty';
import PostImageZoomPopup from './PostImageViewer';
import { DetailSpinner } from './Spinner';
import { X } from 'react-feather';
var JSONPrettyMon = require('react-json-pretty/dist/monikai');

interface UserPopupProps {
  user: string;
  onClose: () => void;
}

const  UserPopup: React.FC<UserPopupProps> = ({ user, onClose }) => {
    
    const [userInfo,setUserInfo] = useState<any>()
    const [loading, setLoading] = useState(false)
    const [tab, setTab] = useState(1);
    const [userImage, setUserImage] = useState<any>([]);
    const [OpenZoomPic, setOpenZoomPic] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");
    const [showPreferenceDetails, setShowPreferenceDetails] = useState(false);
    const [showActivityDetails, setShowActivityDetails] = useState(false);
    const [imageLoading, setImageLoading] = useState(false);
  const [imageNames,  setImageNames] = useState<any>()
    const onSucessGettingUsersUploadedImgs = (data: any) => {
      setUserImage(data);
    };
    const onSucessGettingUserImageNames = (data:any) =>{
      setImageNames(data)
    }
   
   useEffect(() =>{
   if(user){
    const ENDPOINTSNAME = `${getUserImageNames}${user}`
    getContent(ENDPOINTSNAME, onSucessGettingUserImageNames,onFail)
   }
   },[]) 
    useEffect(() => {
      setUserImage([]);
      setImageLoading(true);
      if (imageNames && imageNames.userImageNames && imageNames.userImageNames.length > 0) {          
        const ENDPOINTS = imageNames.userImageNames.map(   
          (imageId: any) => `${getpartnerImage}${imageId.name}`
        );
        fetchAndDisplayImages(
          ENDPOINTS,
          onSucessGettingUsersUploadedImgs,
          onFail
        );
        setImageLoading(true);
      } else {
        setImageLoading(false);
      }
    }, [imageNames]);
  
  
    const userStatus = () => {
      if (userInfo?.userStatus === true) {
        return (
          <div className="inline-flex items-center">
            <span className="h-4 w-4 rounded-full bg-green-500 mr-1"></span>
          </div>
        );
      } else {
        return null;
      }
    };
    function successUserInfo (data:any){
        setUserInfo(data)
        }
        function onFail (message:any){
            console.error(message)
            setLoading(false)
        }
    useEffect(() => {
          getContent(`${GetUserInfoEndpoint}${user}`, successUserInfo, onFail)
      }, [user])
    return (
        <>
        <div>
        <div className="flex justify-end">
          <X onClick={onClose} className=" cursor-pointer " />
        </div>
         {userInfo ? (
            <div className="scrollbar-hide h-[60%]">
              <div className="flex flex-col gap-6 mb-4">
                <div className="flex gap-6 items-center">
                  {userImage ? (
                    <img
                      className="object-cover border rounded-full h-16 w-16"
                      src={userImage[0]}
                      alt={userInfo?.userInfo[0]?.firstName}
                    />
                  ) : (
                    <UserNameAvatar
                      userName={userInfo?.userInfo[0]?.firstName}
                      userType={'user'}
                    />
                  )}
                  <p className="font-bold text-lg">
                    {userInfo?.userInfo && userInfo?.userInfo[0]?.firstName}{" "}
                    {userInfo?.userInfo && userInfo?.userInfo[0]?.lastName}
                  </p>
                  {userStatus()}
                </div>
                <p>
                  Phone number:{" "}
                  {userInfo?.userInfo && userInfo?.userInfo[0]?.phoneNumber}
                </p>
                <div className="h-[60vh] overflow-auto">
                  <div className="gap-2 p-4 divide-y-2 capitalize my-2">
                    <JSONPretty  data={userInfo?.userInfo?.[0]} theme={JSONPrettyMon}></JSONPretty>

                  </div>
                  <div className="p-4 my-2">
                    <div className="bg-custom-black border border-burgundy rounded-md">
                      <div
                        className="flex justify-between items-center cursor-pointer p-4"
                        onClick={() =>
                          setShowPreferenceDetails(!showPreferenceDetails)
                        }
                      >
                        <p className="text-white text-lg mb-2  font-bold">
                          More about preference
                        </p>
                        <button
                          className="text-white py-2 px-2 rounded-md flex items-center justify-center"
                          type="button"
                        >
                          <span
                            className={`transform transition-transform ${
                              showPreferenceDetails ? "rotate-180" : ""
                            }`}
                          >
                            ▼
                          </span>
                        </button>
                      </div>

                      {showPreferenceDetails && (
                        <div className="gap-2 divide-y-2 capitalize my-2">
                            <JSONPretty  data={userInfo?.matchpreferences[0]} theme={JSONPrettyMon}></JSONPretty>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="p-4 my-2">
                    <div className="bg-custom-black border border-burgundy rounded-md">
                      <div
                        className="flex justify-between items-center cursor-pointer py-4"
                        onClick={() =>
                          setShowActivityDetails(!showActivityDetails)
                        }
                      >
                        <p className="text-white text-lg mb-2 ml-2 font-bold">
                          More about user activity
                        </p>
                        <button className="text-white py-2 px-2 rounded-md flex items-center justify-center">
                          <span
                            className={`transform transition-transform ${
                              showActivityDetails ? "rotate-180" : ""
                            }`}
                          >
                            ▼
                          </span>
                        </button>
                      </div>
                      {showActivityDetails && (
                        <div className="gap-2 divide-y-2 capitalize my-2">
                        <JSONPretty  data={userInfo?.userconnections[0]} theme={JSONPrettyMon}></JSONPretty>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="mt-2 h-[100%]">
                    <p className="text-sm text-[#999999]">Uploaded Images</p>
                    <div className="grid max-w-sm grid-cols-2 gap-4 my-4">
                      {imageLoading ? (
                        userImage.length > 0 ? (
                          <>
                            {userImage.map((imageUrl: any, index: number) => {
                              const handleClick = (
                                e: React.MouseEvent<HTMLImageElement>
                              ) => {
                                e.preventDefault(); 
                                e.stopPropagation(); 
                                setOpenZoomPic(true);
                                setSelectedImage(e.currentTarget.src);
                              };
                              return (
                                <div key={index}>
                                  <img
                                    className="object-cover w-full h-full rounded-md"
                                    onClick={handleClick}
                                    src={imageUrl}
                                    alt={`Partner ${index + 1}`}
                                  />
                                  {OpenZoomPic && (
                                    <PostImageZoomPopup
                                      imageUrl={selectedImage}
                                      onClose={() => setOpenZoomPic(false)}
                                    />
                                  )}
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <div>Loading...</div>
                        )
                      ) : (
                        <div>This post does not have any images.</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <DetailSpinner />
            </div>
          )}
    </div>
      </>
      );
};
export default UserPopup;
