const passwordLength = 8;
const accFirstName = 20;
const accLastName = 40;

enum PlansKeysMaxLengthsEnum {
  NAME = 100,
  DESCRIPTIOIN = 280,
  BENEFIT = 200,
}

// MAX_BENEFIT_LENGTH

const ERROR_MESSAGE_FOR_INVALID_PRICE_FOR_ONE_MONTH =
  "Please add a valid price for one month";

const ERROR_MESSAGE_FOR_INVALID_PRICE_FOR_SIX_MONTHS =
  " Please add a valid price for six months";

const ERROR_MESSAGE_FOR_INVALID_PRICE_FOR_ONE_YEAR =
  " Please add a valid price for one year";

const ERROR_MESSAGE_FOR_INVALID_SUBS_NAME = " Name is required";

const ERROR_MESSAGE_FOR_INVALID_SUBS_DESCRIPTION = "Description is required";

const ERROR_MESSAGE_FOR_INVALID_SUBS_BENEFITS =
  "Benefits are required please add them!";

const ERROR_MESSAGE_FOR_INVALID_SUBS_OPTIONS =
  "Subscription Options are required please add them!";

const DEFAULT_PRICE_FOR_SUBS_PLANS_INPUTS = 0;

const accountType = window.location.pathname.includes("admin")
  ? "admin"
  : "user";

const flexCenter = "flex items-center justify-center";

const TakeActionAlertText =
  "Are you sure you want to take action?";
  const UpdateUserRating =  
 "Are you sure you want to update user rating?";
const UpdateUserGender =  
 "Are you sure you want to update user gender?";
 const UpdatePreferenceGender =  
 "Are you sure you want to update preference gender?";
  const UpdateActionAlertText =
  "Are you sure you want to send this Action update?";


const takeActionTextAreaText = "Note to user/ reason to take action";

const noteToAdmin = "Note to Admin Manager";

const selectActionType = "Action type";
const selectSubscriptionDuration = "Select duration"
const selectPlanName = "subscription type"

const verificationUpdateButton = "Submit";
const reviewMatchPartnerUsers = "Review"
const logOutAlertText =
  "Are you sure you want to Logout?";

const DeleteAlertText =
  "Are you sure you want to Delete?";

const DeactivateAlertText =
  "Are you sure you want to Deactivate?";

const giveWarningToUser = "Submit";
const somethingIsWrong = "Something is Wrong";

enum reportActionType1 {
  block = "block",
  restrict = "restrict",
  deactivate = "deactivate",
  ban = "ban",
  pause = "pause",
}

enum reportActionType  {  
  ACTIVATE="activate", 
  DELETE = "delete", 
  SUSPEND="suspend", 
};
enum userGender {
  FEMALE = "female",
  MALE = "male",
}
enum subscriptionDurations {
  ONE_WEEK = "weekly",
  ONE_MONTH = "monthly",
  SIX_MONTHS = "Six months",
  ONE_YEAR = "One year",
}
enum PlanName {
  SILVER = "silver",
  GOLD = "gold",
  PLATINUM = "platinum",
}
enum userRate {
  ONE = "1",
  TWO = "2",
  THREE = "3",
  FOUR = "4",
  FIVE = "5",
}
enum reviewerActionType {
  remove = "remove",
  deactivate = "deactivate",
  activate = "activate",
  updateRole= "updateRole",
}
enum verifyActionType {
  verify = "verify",
  decline = "decline",
}
const MatchingPartnerAccountStatus = {
	activate: "active",
	reject: "rejected",
  };

const MatchingCategory = {
  'Marriage': "marriage",
  'Gym partner': "gym_partner",
  'Event': "event",
  'Party': "party",
  'Other': "other",
  'Book club': "book_club",
  'Hiking': "hiking",
  'Travel': "travel",
  'Sport': "sport",
  'Dating': "dating",
  'Volunteering': "volunteering",
}
enum LineGraphFilterType {
  ALL="All",
  THISYEAR = "This Year",
  THISMONTH = "This Month",
  THISWEEK = "This Week",
}

enum SubscriptionType {
  ALL="All",
  SILVER = "silver",
  GOLDEN = "golden",
  PLATINUM = "platinum",
}

enum UserType{
  USER="User",
  SENDER="Sender",
  RECIEVER='Receiver'
}
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]
enum Months {
  January = 'January',
  February = 'February',
  March = 'March',
  April = 'April',
  May = 'May',
  June = 'June',
  July = 'July',
  August = 'August',
  September = 'September',
  October = 'October',
  November = 'November',
  December = 'December',
}

const adminManagersDashboard = "/adminManager/Dashboard";
const reviewersDashboard = "/reviewer/VerificationRequests";
const NoPlansCurrentAdded = "No plans are currently added.";
const NotReviewedAnyUserYet = "Not reviewed any user yet";

const success = "success";
const warning = "warning";
const error = "error";
const notice = "notice";

const variantSuccess = "bg-green-300";
const variantWarning = "bg-orange-300";
const variantError = "bg-red-300";
const variantNotice = "bg-gray-300";
const reviewReportedChat = "/api/v1/reportedChats/reviewReportedChat";
const reviewReportedUser = "/api/v1/userReport/reviewReport";
const findAllUserReportApi = "/api/v1/userReport/findAllReports";
const findReportedUserApi = "/api/v1/userReport/findReport/";
const findAllReportedChatApi = "/api/v1/reportedChats/findAllReportedChats";
const findReportedChatApi = "/api/v1/reportedChats/findReportedChat/";
const reviewVerificationImgApi = "/api/v1/verifImgReq/reviewVerificationImg";
const findVerifyImgRequestApi = "/api/v1/verifImgReq/findVerifyImgReq/";
const findAllVerifyImgRequestApi = "/api/v1/verifImgReq/findAllVerifyImgReq";

const SUCCESS_STATUS_CODE = 200;
const FAILED_STATUS_CODE = 201;

enum UsersTypeEnum {
  USERS_APPLIED_FOR_VERIFICATION = "VERIFICATIONUSERS",
  REPORTED_USER = "REPORTEDUSERS",
  USERS_CHAT_GOT_REPORTED = "REPORTEDCHATS",
}
enum IdTypeEnum{
 VERIFICATION_REQUEST_ID= "verificationRequestId" ,
 REPORTED_USER_ID= "reportedUserId",
  REPORTED_CHAT_ID= "reportedChatId" 
}

enum AdminRole {
  NOT_AUTHORIZED_TO_REVIEW = "not_authorized_to_review",
  REVIEW_REPORTED_USERS = "review_reported_users",
  REVIEW_REPORTED_CHATS = "review_reported_chats",
  REVIEW_USER_VERIFICATION_REQUESTS = "review_user_verification_requests",
  REVIEW_REPORTED_CHATS_AND_USERS = "review_reported_chats_and_users",
  REVIEW_REPORTED_USERS_AND_VERIFICATION_REQUESTS = "review_reported_users_and_verification_requests",
  REVIEW_REPORTED_CHATS_AND_VERIFICATION_REQUESTS = "review_reported_chats_and_verification_requests",
  AUTHORIZED_TO_REVIEW_ALL_REQUESTS = "authorized_to_review_all_requests",
}
enum ReviewerAccountStatus{
  ACTIVATE = 'activate',
  DEACTIVATE = 'deactivate',
}
const getEnumKeyFromValue = (enumObject: any, targetValue: any) => {
  for (let key in enumObject) {
    if (enumObject[key] === targetValue) {
      return key;
    }
  }
  return null;
};

const REVIEWER_DASHBOARD_ROUTE = "reviewer/*";
const ADMIN_MANAGER_DASHBOARD_ROUTE = "adminManager/*";
const SIGN_IN_ROUTE = "/signIn";
const SIGN_UP_ROUTE = "/signUp";
const VERIFY_SIGN_UP_ROUTE = `/api/v1/admin/verifyAndSignUp/*`;
const FORGET_PASSWORD_ROUTE = "/forgotPassword";
const VERIFY_FORGET_PASSWORD_ROUTE = `/api/v1/admin/verifyAdminForgotPassword/*`;

const NAME = "name";
const EMAIL = "email";
const PASSWORD = "password";
const LAST_NAME = "lastName";
const TEXT = "text";

const PASSWORD_IS_MANDATORY = "Password is mandatory";
const PASSWORD_MAX_CHAR_LENGTH = `Password must be at ${passwordLength} char long`;
const FIRST_NAME_IS_MANDATORY = "First name is mandatory";
const FIRST_NAME_MAX_CHAR_LENGTH = `First name must be at ${accFirstName} char long`;
const LAST_NAME_IS_MANDATORY = "Last name is mandatory";
const LAST_NAME_MAX_CHAR_LENGTH = `Last name must be at ${accLastName} char long`;
const ENTER_VALID_EMAIL = "Please enter a valid email format !";
const EMAIL_IS_MANDATORY = "Email is mandatory";

const PLAN_NAME_MAX_CHAR_LENGTH = `Name must be at ${PlansKeysMaxLengthsEnum.NAME} char long`;
const PLAN_DESCRIPTION_MAX_CHAR_LENGTH = `Description must be at ${PlansKeysMaxLengthsEnum.DESCRIPTIOIN} char long`;
const PLAN_BENEFIT_MAX_CHAR_LENGTH = `Benefit must be at ${PlansKeysMaxLengthsEnum.BENEFIT} char long`;
const PLAN_BENEFIT_IS_MANDATORY = "Please enter a benefit.";

export {
  passwordLength,
  accountType,
  ERROR_MESSAGE_FOR_INVALID_PRICE_FOR_ONE_MONTH,
  ERROR_MESSAGE_FOR_INVALID_PRICE_FOR_SIX_MONTHS,
  ERROR_MESSAGE_FOR_INVALID_PRICE_FOR_ONE_YEAR,
  ERROR_MESSAGE_FOR_INVALID_SUBS_NAME,
  ERROR_MESSAGE_FOR_INVALID_SUBS_DESCRIPTION,
  ERROR_MESSAGE_FOR_INVALID_SUBS_BENEFITS,
  DEFAULT_PRICE_FOR_SUBS_PLANS_INPUTS,
  flexCenter,
  accFirstName,
  accLastName,
  takeActionTextAreaText,
  noteToAdmin,
  selectActionType,
  verificationUpdateButton,
  giveWarningToUser,
  reportActionType,
  verifyActionType,
  adminManagersDashboard,
  reviewersDashboard,
  NoPlansCurrentAdded,
  success,
  warning,
  error,
  notice,
  variantError,
  variantNotice,
  variantSuccess,
  variantWarning,
  reviewReportedChat,
  reviewReportedUser,
  somethingIsWrong,
  SUCCESS_STATUS_CODE,
  FAILED_STATUS_CODE,
  ERROR_MESSAGE_FOR_INVALID_SUBS_OPTIONS,
  findAllUserReportApi,
  findReportedUserApi,
  findAllReportedChatApi,
  findReportedChatApi,
  reviewVerificationImgApi,
  findAllVerifyImgRequestApi,
  findVerifyImgRequestApi,
  UsersTypeEnum,
  AdminRole,
  REVIEWER_DASHBOARD_ROUTE,
  ADMIN_MANAGER_DASHBOARD_ROUTE,
  SIGN_IN_ROUTE,
  SIGN_UP_ROUTE,
  VERIFY_SIGN_UP_ROUTE,
  FORGET_PASSWORD_ROUTE,
  VERIFY_FORGET_PASSWORD_ROUTE,
  NAME,
  EMAIL,
  PASSWORD,
  LAST_NAME,
  TEXT,
  PASSWORD_IS_MANDATORY,
  PASSWORD_MAX_CHAR_LENGTH,
  FIRST_NAME_IS_MANDATORY,
  FIRST_NAME_MAX_CHAR_LENGTH,
  LAST_NAME_IS_MANDATORY,
  LAST_NAME_MAX_CHAR_LENGTH,
  ENTER_VALID_EMAIL,
  EMAIL_IS_MANDATORY,
  NotReviewedAnyUserYet,
  PLAN_NAME_MAX_CHAR_LENGTH,
  PLAN_DESCRIPTION_MAX_CHAR_LENGTH,
  PLAN_BENEFIT_MAX_CHAR_LENGTH,
  PlansKeysMaxLengthsEnum,
  PLAN_BENEFIT_IS_MANDATORY,
  getEnumKeyFromValue,
  LineGraphFilterType,
  SubscriptionType,
  UserType,
  logOutAlertText,
  DeactivateAlertText,
  DeleteAlertText,
  UpdateActionAlertText,
  Months,
  monthNames,
  IdTypeEnum,
  reviewerActionType,
  TakeActionAlertText,
  reportActionType1,
  ReviewerAccountStatus,
  subscriptionDurations,
  selectSubscriptionDuration,
  selectPlanName,
  userRate,
  PlanName,
  reviewMatchPartnerUsers,
  MatchingPartnerAccountStatus,
  userGender,
  UpdateUserGender,
  UpdatePreferenceGender,
  UpdateUserRating,
  MatchingCategory
};
