import { useState, useEffect, useContext } from "react";
import ViewReportedChatsUser from "../components/ViewReportedChatsUser";
import { getContentWithPagination } from "../adapters/get";
import { ToastContext } from "../components/Toast/ToastProvider";
import { error } from "../constants";
import { findAllReportedChatsEndPoint } from "../adapters/endpoints";
import Spinner from "../components/Spinner";
import { Users } from "react-feather";
import ProfilePicture from "../components/ProfileImages";
import DataTable from "react-data-table-component";

export default function ReportChats() {
  const [reportId, setReportId] = useState<any>();
  const [showUser, setShowUser] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const { handleCreateToast } = useContext(ToastContext);
  const [selectedRow, setSelectedRow] = useState(null);
  const [chatsreports, setChatsReports] = useState([]);
  const [chatsReportsCount, setChatsReportsCount] = useState<any>(0);

  function onSuccess(data: any) {
    setChatsReports(data.reportedChats);
    setChatsReportsCount(data.countReportedChats)
    setLoading(false);
  }

  function onFail(message: any) {
    handleCreateToast(message, error);
  }

  useEffect(() => {
    getContentWithPagination(findAllReportedChatsEndPoint, onSuccess, onFail);
    setLoading(true);
  }, []);

  function handleRowClick(row: any) {
    setSelectedRow(row._id)
    setReportId(row._id);
    setShowUser(true);
  }
  const users = chatsreports.map((item: any, idx: number) => {
    const user = {
      // no: idx + 1,
      _id: item?._id,
      reporter: item?.reporterId?.firstName,
      lastSeenAtRep:item?.reporterId?.lastSeenAt?.split("T")[0],
      reporterImageUrls:<ProfilePicture imageUrl = {item?.reporterImageUrls?.[0]?.imageUrls?.[0]} firstName = { item?.reporterId?.firstName} />,
      responsible: item?.responsibleId?.firstName,
      lastSeenAtRes:item?.reporterId?.lastSeenAt?.split("T")[0],
      responsibleImageUrls:<ProfilePicture imageUrl = {item?.responsibleImageUrls?.[0]?.imageUrls?.[0]} firstName = {item?.responsibleId?.firstName} />,
      reportType: item?.violationType,
      date: item?.createdAt?.split("T")[0],
    };
    return user;
  });
  
  const columns = [
    {
      name:'Profile',
      selector: (row: { reporterImageUrls: any }) => row.reporterImageUrls,
      width: '150px',
      cell: (row:any) => <span className="text-sm">{row.reporterImageUrls}</span>,
    },
   
    {
      name: "Reporter",
      selector: (row: { reporter: any }) => row.reporter,
      width: '150px',
      cell: (row:any) => <span className="text-sm">{row.reporter}</span>, 
      },
      {
        name: "last Seen",
        selector: (row: { lastSeenAtRep: any }) => row.lastSeenAtRep,
        width: '150px',
        cell: (row:any) => <span className="text-sm">{row.lastSeenAtRep}</span>, 
        },
      {
        name: "Profile",
        selector: (row: { responsibleImageUrls: any }) => row.responsibleImageUrls,
        width: '150px',
        cell: (row:any) => <span className="text-sm">{row.responsibleImageUrls}</span>, 
        },
      {
        name: "Responsible",
        selector: (row: { responsible: any }) => row.responsible,
        width: '200px',
        cell: (row:any) => <span className="text-sm">{row.responsible}</span>, 
        },
        {
          name: "last Seen",
          selector: (row: { lastSeenAtRes: any }) => row.lastSeenAtRes,
          width: '150px',
          cell: (row:any) => <span className="text-sm">{row.lastSeenAtRes}</span>, 
          },
    {
      name: "Report Type",
      selector: (row: { reportType: any }) => row.reportType,
      width: '150px',
      cell: (row:any) => <span className="text-sm">{row.reportType}</span>, 
    },
    {
      name: "Date",
      selector: (row: { date: any }) => row.date ,
      width: '150px', 
      cell: (row:any) => <span className="text-sm">{row.date}</span>, 
    },
  ];
  const conditionalRowStyles = [
    {
      when: (row: any) => row._id === selectedRow, 
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)', 
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
      },
    },
  ];
  return (
    <div className="  p-8 ">
      {loading ? (
        <Spinner />
      ) : (
        <div className=" w-[100%] caret-transparent px-8 py-4 h-screen   rounded-lg  bg-white shadow-sm">
          <div className="flex text-gray-600  shadow my-2 max-w-max px-4 py-2  rounded-md gap-2 items-center font-semibold ">
            <div className="flex gap-2  items-center">
              <Users />
              <p>Pending Users</p>
            </div>
            <span>&nbsp;•&nbsp;</span>
            <p className="text-slate-800 text-2xl">{chatsReportsCount}</p>
          </div>
          <div className="overflow-y-auto scrollbar max-h-[85%] p-4 m-4 rounded-lg bg-white shadow-sm">
            <DataTable
              columns={columns}
              data={users}
              pagination
              onRowClicked={handleRowClick}
              className="text-center"
              customStyles={{
                headCells: {
                  style: {
                    fontSize: '16px',
                    paddingTop: '4px',
                    paddingBottom: '4px',
                    backgroundColor:'#ADD8E6'

                  },
                },
                rows: {
                  style: {
                    paddingTop: '4px',
                    paddingBottom: '4px',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.05)',
                      cursor: 'pointer',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    },
                  },
                },
              }}
              conditionalRowStyles={conditionalRowStyles}
            />
          </div>
        </div>
      )}
      {showUser && (
        <ViewReportedChatsUser
          setShowUser={setShowUser}
          reportId={reportId}
          setReports={setChatsReports}
        />
      )}
    </div>
  );
}
